import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Select from 'react-select';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Loader from '../../../../Loader';
import {
  formatDateString,
  formatGermanDateForInput,
  generateKitaFoodOrdersPDF,
  getCurrentDate,
  hasGermanDateStringFormat,
} from '../../../../../../utilities/foodOrder';
import Button from '../../../../Buttons/Button';
import Input from '../../../../Inputs/Input';
import {
  cancelFoodByDateAndKid,
  getSettings,
  kitaGetOrders,
} from '../../../../../../api/foodOrder';
import ConfirmPopup from '../../../../Popups/ConfirmPopup';
import { getGroupsOfKita } from 'library/api/groups';
import styles from './foodOrdersKita.module.scss';

export default function FoodOrdersKita({ groupId, userId, activeKita, groupInfo }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState(formatGermanDateForInput(getCurrentDate()));
  const [initialData, setInitialData] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [config, setConfig] = useState(null);
  const [progress, setProgress] = useState(0);
  const [cancelKidFoods, setCancelKidFoods] = useState(null);
  const [groups, setGroups] = useState([]);

  const downloadPDF = () => {
    const uploadToFilesModuleData = {
      uploadToFilesModule: false,
      userId: userId,
      groupId: groupId,
      setProgress: setProgress,
    };

    if ((activeKita && activeKita.kitaId === 113) || activeKita.kitaId === 66) {
      if (groupId) {
        uploadToFilesModuleData.uploadToFilesModule = true;
        generateKitaFoodOrdersPDF(
          t,
          filteredData,
          date,
          activeKita.kitaName,
          groupInfo.groupName,
          uploadToFilesModuleData,
        ).then(data => {
          history.push(`/groups/${groupId}/dateien`);
        });
      }
    } else {
      generateKitaFoodOrdersPDF(
        t,
        filteredData,
        date,
        activeKita.kitaName,
        groupInfo.groupName,
        uploadToFilesModuleData,
      );
    }
  };

  const reloadData = () => {
    setIsLoading(true);

    let formatedDate = date;
    if (!hasGermanDateStringFormat(formatedDate)) {
      formatedDate = formatDateString(date);
    }

    kitaGetOrders(groupId, formatedDate).then(res => {
      const { data } = res;

      // Add -1 foods
      // for (let i = 0; i < data.orders.length; i++) {
      //   const kid = data.orders[i];
      //   if (!kid.food) {
      //     data.foods.push({
      //       name: t('FoodOrder.No name'),
      //       id: -1,
      //       description: t('FoodOrder.No description'),
      //       price: 0,
      //     });
      //     kid.food = {
      //       id: -1,
      //     };
      //   }
      // }
      setInitialData(data);
      setFilteredData(data);
    });

    // Remove timeout
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    reloadData();
  }, [date]);

  useEffect(() => {
    getSettings(groupId).then(res => {
      setConfig(res.data);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getGroupsOfKita({
      page: 1,
      sort: { name: 'groupName', direction: 'asc' },
      searchValue: '',
      kitaId: activeKita.kitaId,
      visibility: false,
      onlyPortfolioGroups: false,
    }).then(({ data }) => {
      let groupData;
      if (data && data !== 'undefined' && data.content.length > 0) {
        groupData = data.content.filter(group => !group.groupName.includes('(Portfolio)'));
      }
      groupData = groupData.map(item => ({
        id: item.id,
        label: item.groupName,
        value: item.groupName,
      }));
      setGroups(groupData);
    });
  }, []);

  const filterOrdersByGroup = newSelectedGroups => {
    if (newSelectedGroups.length > 0) {
      const newSelectedGroupsSet = new Set(newSelectedGroups.map(group => group.value));
      const filteredOrders = initialData.orders.filter(order =>
        newSelectedGroupsSet.has(order.kidGroupName),
      );

      setFilteredData(prevState => {
        return { ...prevState, orders: filteredOrders };
      });
    } else {
      setFilteredData(initialData);
    }
  };

  function sortOrdersBy(column) {
    if (filteredData && filteredData.orders) {
      let sortedData = filteredData.orders;
      if (column === 'childName') {
        if (
          !filteredData.hasOwnProperty('sortDirection') ||
          (filteredData.hasOwnProperty('sortDirection') &&
            filteredData.sortDirection !== 'childNameAsc')
        ) {
          sortedData = filteredData.orders.sort((a, b) => {
            if (a.childName === null) {
              return 1;
            }
            if (b.childName === null) {
              return -1;
            }
            if (a.childName < b.childName) {
              return -1;
            }
            if (a.childName > b.childName) {
              return 1;
            }
            return 0;
          });

          setFilteredData(prevState => {
            return { ...prevState, sortDirection: 'childNameAsc' };
          });
        } else {
          sortedData = filteredData.orders.sort((a, b) => {
            if (a.childName === null) {
              return 1;
            }
            if (b.childName === null) {
              return -1;
            }
            if (b.childName < a.childName) {
              return -1;
            }
            if (b.childName > a.childName) {
              return 1;
            }
            return 0;
          });
          setFilteredData(prevState => {
            return { ...prevState, sortDirection: 'childNameDesc' };
          });
        }
      }

      if (column === 'kidGroupName') {
        if (
          !filteredData.hasOwnProperty('sortDirection') ||
          (filteredData.hasOwnProperty('sortDirection') &&
            filteredData.sortDirection !== 'kidGroupNameAsc')
        ) {
          sortedData = filteredData.orders.sort((a, b) => {
            if (a.kidGroupName === null) {
              return 1;
            }
            if (b.kidGroupName === null) {
              return -1;
            }
            if (a.kidGroupName < b.kidGroupName) {
              return -1;
            }
            if (a.kidGroupName > b.kidGroupName) {
              return 1;
            }
            return 0;
          });

          setFilteredData(prevState => {
            return { ...prevState, sortDirection: 'kidGroupNameAsc' };
          });
        } else {
          sortedData = filteredData.orders.sort((a, b) => {
            if (a.kidGroupName === null) {
              return 1;
            }
            if (b.kidGroupName === null) {
              return -1;
            }
            if (b.kidGroupName < a.kidGroupName) {
              return -1;
            }
            if (b.kidGroupName > a.kidGroupName) {
              return 1;
            }
            return 0;
          });
          setFilteredData(prevState => {
            return { ...prevState, sortDirection: 'kidGroupNameDesc' };
          });
        }
      }
      setFilteredData(prevState => {
        return { ...prevState, orders: sortedData };
      });
    }
  }

  const handleCancellation = () => {
    cancelFoodByDateAndKid(groupId, date, cancelKidFoods.childId)
      .then(() => {
        store.dispatch(
          showBottomNotification(t('BottomNotification.FoodOrderCancelSuccess'), { isFail: false }),
        );
        setCancelKidFoods(null);
        reloadData();
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('BottomNotification.ErrorSavingTryAgain'), { isFail: true }),
        );
        setCancelKidFoods(null);
      });
  };

  return (
    <div>
      <ConfirmPopup
        isOpened={cancelKidFoods !== null}
        closePopup={() => setCancelKidFoods(null)}
        onConfirm={handleCancellation}
        text={
          cancelKidFoods
            ? t('FoodOrder.KitaCancelDayWarning').replace('{childName}', cancelKidFoods.childName)
            : ''
        }
      />

      <br />
      <h2>{t('FoodOrder.Orders')}</h2>
      <br />
      <label>{t('FoodOrder.Select day')}</label>
      <Input
        className={styles.timeSelect}
        type='date'
        onChange={e => {
          setDate(e.target.value);
        }}
        value={date}
      />
      <br />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              justifyContent: 'space-between',
              rowGap: '1em',
            }}
          >
            <Button onClick={downloadPDF} className={styles.exportButton}>
              <i className='fa fa-file-pdf-o' /> {t('FoodOrder.export pdf')}
            </Button>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline'  }}>
              <label style={{ marginRight: '15px' }}>{`${t('FoodOrder.Filter By Groups')}:`}</label>
              <div className={styles.groupFilterMultiSelectContainer}>
                <Select
                  placeholder={t('FoodOrder.FilterByGroupsPlaceholder')}
                  options={groups}
                  isMulti
                  onChange={values => filterOrdersByGroup(values)}
                />
              </div>
            </div>
          </div>
          <br />
          <br />
          {filteredData && filteredData.orders && filteredData.orders.length > 0 ? (
            <div className={styles.orderTableContainer}>
            <table className={styles.orderTable}>
              <thead>
                <tr>
                  <th
                    onClick={() => {
                      sortOrdersBy('childName');
                    }}
                  >
                    {t('FoodOrder.Kid')}
                    {filteredData.hasOwnProperty('sortDirection') &&
                      filteredData.sortDirection === 'childNameAsc' && (
                        <>
                          &nbsp;
                          <i className='fa fa-arrow-up' />
                        </>
                      )}
                    {filteredData.hasOwnProperty('sortDirection') &&
                      filteredData.sortDirection === 'childNameDesc' && (
                        <>
                          &nbsp;
                          <i className='fa fa-arrow-down' />
                        </>
                      )}
                  </th>
                  <th
                    onClick={() => {
                      sortOrdersBy('kidGroupName');
                    }}
                  >
                    {t('FoodOrder.Group name')}
                    {filteredData.hasOwnProperty('sortDirection') &&
                      filteredData.sortDirection === 'kidGroupNameAsc' && (
                        <>
                          &nbsp;
                          <i className='fa fa-arrow-up' />
                        </>
                      )}
                    {filteredData.hasOwnProperty('sortDirection') &&
                      filteredData.sortDirection === 'kidGroupNameDesc' && (
                        <>
                          &nbsp;
                          <i className='fa fa-arrow-down' />
                        </>
                      )}
                  </th>
                  {filteredData &&
                    filteredData.foods &&
                    filteredData.foods.map(food => <th className={styles.tr}>{food.name}</th>)}
                  {config && config.enableCancellation && <th />}
                </tr>
              </thead>
              <tbody>
                {filteredData &&
                  filteredData.orders &&
                  filteredData.orders.map(order => (
                    <tr>
                      <td>{order.childName}</td>
                      <td>
                        {order.kidGroupName && order.kidGroupName !== null && order.kidGroupName}
                      </td>
                      {filteredData.foods.map(food => (
                        <td className={styles.tr}>
                          {order.food && food.id === order.food.id && <i className='fa fa-times' />}
                        </td>
                      ))}
                      {config && config.enableCancellation && (
                        <td>
                          <Button
                            onClick={() =>
                              setCancelKidFoods({
                                childName: order.childName,
                                childId: order.childId,
                              })
                            }
                          >
                            {t('FoodOrder.KitaCancel')}
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
            </div>
          ) : (
            <>
              {' '}
              <strong>
                <i className={cn('fa fa-info-circle', styles.mr1)} />
                {t('FoodOrder.No meals available')}
              </strong>
              <br /> <br />
            </>
          )}
        </>
      )}
    </div>
  );
}
