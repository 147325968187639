import React from 'react';
import RadioButton from 'library/common/commonComponents/RadioButton';
import Input from 'library/common/commonComponents/Inputs/Input';
import { useTranslation } from 'react-i18next';

export default function MultiUserSignaturePopupHeader(props) {
  const { t } = useTranslation();
  const allUsersCount = props.noneSignatureUserCount + props.signatureUserCount;
  return (
    <div>
      <RadioButton
        checked={props.headerSelectboxState === 0}
        onClick={() => props.setHeaderSelectboxState(0)}
        label={`${t('Post.Show all')} (${allUsersCount})`}
      />

      <RadioButton
        checked={props.headerSelectboxState === 1}
        onClick={() => props.setHeaderSelectboxState(1)}
        label={`${t('Post.Show signed')} (${props.signatureUserCount})`}
      />

      <RadioButton
        checked={props.headerSelectboxState === 2}
        onClick={() => props.setHeaderSelectboxState(2)}
        label={`${t('Post.Show not signed')} (${props.noneSignatureUserCount})`}
      />
    </div>
  );
}
