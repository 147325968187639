import axios from 'library/api/axios';
import config, { getPort } from 'main/config';

const URL = `${config.API_BASE_URI}${getPort(8091)}/api`;
const usermanagementURL = `${config.API_BASE_URI}${getPort(8080)}/api`;

export const getAllKidsFromSelectedKita = () => {
  return axios.get(`${usermanagementURL}/allKidsFromSelectedKita`);
};

export const getKidContractEndDateListFromSelectedKita = () => {
  return axios.get(`${usermanagementURL}/kidContractEndDateListFromSelectedKita`);
};

export const getAllCheckedInKidsFromSelectedKita = () => {
  return axios.get(`${usermanagementURL}/checkedInKidsFromSelectedKita`);
};

export const getAllOwnKidsFromSelectedKita = () => {
  return axios.get(`${usermanagementURL}/allOwnKidsFromSelectedKita`);
};

export const getKidsForCurrentUserInSelectedKita = () => {
  return axios.get(`${usermanagementURL}/getkidsforcurrentuserinselectedkita`);
};

export const postGfzObservationSheet = async formData => {
  await axios.post(URL + '/savegfzobservationsheet', formData);
};

export const getGfzObservationSheet = kidId => {
  return axios.get(`${URL}/getgfzobservationsheet/${kidId}`);
};

export const deleteGfzObservationSheet = id => {
  return axios.delete(`${URL}/gfzobservationsheet/${id}`);
};

export const postGfzParentsTalk = async data => {
  await axios.post(URL + '/savegfzparentstalk', {
    ...data,
  });
};

export const getGfzParentsTalk = kidId => {
  return axios.get(`${URL}/getgfzparentstalk/${kidId}`);
};

export const deleteGfzParentsTalk = id => {
  return axios.delete(`${URL}/gfzparentstalk/${id}`);
};

export const postGfzAcclimatizationDocumentation = async data => {
  await axios.post(URL + '/savegfzacclimatizationdocumentation', {
    ...data,
  });
};

export const getGfzAcclimatizationDocumentation = kidId => {
  return axios.get(`${URL}/getgfzacclimatizationdocumentation/${kidId}`);
};

export const deleteGfzAcclimatizationDocumentation = id => {
  return axios.delete(`${URL}/gfzacclimatizationdocumentation/${id}`);
};

export const postGfzObservationAnalysis = async data => {
  await axios.post(URL + '/savegfzobservationanalysis', {
    ...data,
  });
};

export const getGfzObservationAnalysis = kidId => {
  return axios.get(`${URL}/getgfzobservationanalysis/${kidId}`);
};

export const deleteGfzObservationAnalysis = id => {
  return axios.delete(`${URL}/gfzobservationanalysis/${id}`);
};

export const postGfzColleagueExchange = async data => {
  await axios.post(URL + '/savegfzcolleagueexchange', {
    ...data,
  });
};

export const getGfzColleagueExchange = kidId => {
  return axios.get(`${URL}/getgfzcolleagueexchange/${kidId}`);
};

export const deleteGfzColleagueExchange = id => {
  return axios.delete(`${URL}/gfzcolleagueexchange/${id}`);
};

export const postGfzMedicalAuthorizationOld = async data => {
  return axios.post(URL + '/savegfzmedicalauthorization', {
    ...data,
  });
};

export const postGfzMedicalAuthorization = (data, pdfData) => {
  const file = new File(
                    [pdfData],
                    `document.pdf`);

  const formData = new FormData();
  formData.append("file", file);
  formData.append("data", JSON.stringify({...data}));

  return axios.post(`${URL}/savegfzmedicalauthorization`, formData);
};

export const getGfzMedicalAuthorization = (kidId, formId) => {
  return axios.get(`${URL}/getgfzmedicalauthorization/${kidId}/${formId}`);
};

export const postGfzKidLeaving = (data, pdfData) => {
  const file = new File(
                    [pdfData],
                    `document.pdf`);

  const formData = new FormData();
  formData.append("file", file);
  formData.append("data", JSON.stringify({...data}));

  return axios.post(`${URL}/savegfzkidleaving`, formData);
};

export const getGfzKidLeaving = (kidId, formId) => {
  return axios.get(`${URL}/getgfzkidleaving/${kidId}/${formId}`);
};

export const postGfzContractChanges = (data, pdfData) => {
  const file = new File(
                    [pdfData],
                    `document.pdf`);

  const formData = new FormData();
  formData.append("file", file);
  formData.append("data", JSON.stringify({...data}));

  return axios.post(`${URL}/savegfzcontractchanges`, formData);
};

export const getGfzContractChanges = (kidId, formId) => {
  return axios.get(`${URL}/getgfzcontractchanges/${kidId}/${formId}`);
};

export const postGfzAddDays = (data, pdfData) => {
  const file = new File(
                    [pdfData],
                    `document.pdf`);

  const formData = new FormData();
  formData.append("file", file);
  formData.append("data", JSON.stringify({...data}));

  return axios.post(`${URL}/savegfzadddays`, formData);
};

export const getGfzAddDays = (kidId, formId) => {
  return axios.get(`${URL}/getgfzadddays/${kidId}/${formId}`);
};

export const postGfzNannyProtocol = async data => {
  await axios.post(URL + '/savegfznannyprotocol', {
    ...data,
  });
};

export const getGfzNannyProtocol = kidId => {
  return axios.get(`${URL}/getgfznannyprotocol/${kidId}`);
};

export const getGfzNannyList = kitaId => {
  return axios.get(`${URL}/getgfznannylist/${kitaId}`);
};

export const postGfzNannyChanges = (deletedNannies, newNannies) => {
  return axios.post(`${URL}/savegfznannychanges`, {
    deletedNannies,
    newNannies,
  });
};

export const getGfzFormsOverview = () => {
  return axios.get(`${URL}/getgfzformsoverview`);
};

export const deleteGfzForms = (payload) => {
  return axios.post(`${URL}/deletegfzforms`, payload);
};

export const getGfzFormsOverviewForCurrentUser = () => {
  return axios.get(`${URL}/getgfzformsoverview/currentuser`);
};

export const declineGfzMedicalAuthorization = (kidId, formId) => {
  return axios.delete(`${URL}/declinegfzmedicalauthorization/${kidId}/${formId}`);
};

export const declineGfzKidLeaving = (kidId, formId) => {
  return axios.delete(`${URL}/declinegfzkidleaving/${kidId}/${formId}`);
};

export const declineGfzContractChanges = (kidId, formId) => {
  return axios.delete(`${URL}/declinegfzcontractchanges/${kidId}/${formId}`);
};

export const declineGfzAddDays = (kidId, formId) => {
  return axios.delete(`${URL}/declinegfzadddays/${kidId}/${formId}`);
};

export const onGfzFormSubmitByParent = (kidId, formId, formType, pdfData) => {
  const file = new File([pdfData], `document.pdf`);

  const formData = new FormData();
  formData.append("file", file);

  return axios.post(`${URL}/onGfzFormSubmitByParent/${kidId}/${formId}/${formType}`, formData);
};

export const onGfzFormSubmitByAdmin = (kidId, formId, formType, pdfData) => {
  const file = new File(
                    [pdfData],
                    `document.pdf`);

  const formData = new FormData();
  formData.append("file", file);

  return axios.post(`${URL}/onGfzFormSubmitByAdmin/${kidId}/${formId}/${formType}`, formData);
};
