import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Input from 'library/common/commonComponents/Inputs/Input';
import Loader from 'library/common/commonComponents/Loader';
import { formatTimeToLanguage } from 'library/utilities/date';
import styles from './absenceHistoryFrame.module.scss';
import {
  getKidAbsenceHistoryForUser,
  getKidAbsence,
  getMemberAbsenceHistoryForUser,
  deleteMemberAbsence,
} from '../../../../library/api/user';
import AbsenceFrame from 'modules/AccountSettings/accountSettingsFrames/AbsenceFrame/index.jsx';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import { deleteKidAbsence } from 'library/api/user';
import SortIndicator from 'library/common/commonComponents/SortIndicator';
import cn from 'classnames';
import Checkbox from 'library/common/commonComponents/Checkbox';
import Label from 'library/common/commonComponents/Label';
import DatePicker from 'library/common/commonComponents/DatePicker';

export default function AbsenceHistoryFrame({ user, showBottomNotification, memberSelectable }) {
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [history, setHistory] = useState([]);
  const [editPopup, setEditPopup] = useState(false);
  const [editItem, setEditItem] = useState(0);
  const [duration, setDuration] = useState(0);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteItem, setDeleteItem] = useState([]);
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);
  const [sorting, setSorting] = useState({ name: 'startDate', direction: 'desc' });

  const [showHoliday, setShowHoliday] = useState(true);
  const [showSickness, setShowSickness] = useState(true);

  const [formFrom, setFormFrom] = useState(new Date("2020-01-01"));
  const [formTo, setFormTo] = useState(new Date("2030-01-01"));

  const fetchMemberHistory = () => {
    if (window.location.hostname.toLowerCase().includes('ivs.safe2connect.org')) {
      getMemberAbsenceHistoryForUser().then(res => {
        if (res.status === 200) {
          const data = res.data || [];
          setHistory(value =>
            value.concat(
              data.map(item => ({
                ...item,
                kid: {
                  id: item.user.id,
                  firstName: item.user.firstName,
                  lastName: item.user.lastName,
                  deactivated: false,
                  isMember: true,
                },
              })),
            ),
          );
        }
      });
    }
  };

  const sortBy = nameOfSortObject => {
    if (sorting.name === nameOfSortObject) {
      if (sorting.direction === 'asc') {
        setSorting({ direction: 'desc', name: nameOfSortObject });
      } else {
        setSorting({ direction: 'asc', name: nameOfSortObject });
      }
    } else {
      setSorting({ direction: 'asc', name: nameOfSortObject });
    }
  };

  const sortList = (result, sorting) => {
    if (result.length > 0) {
      let sorted = [];
      if (sorting.direction === 'asc') {
        sorted = [...result].sort((a, b) =>
          a[sorting.name] > b[sorting.name]
            ? 1
            : a[sorting.name] === b[sorting.name]
              ? a.reportedDate > b.reportedDate
                ? 1
                : -1
              : -1,
        );
      } else {
        sorted = [...result].sort((a, b) =>
          a[sorting.name] > b[sorting.name]
            ? -1
            : a[sorting.name] === b[sorting.name]
              ? a.reportedDate > b.reportedDate
                ? -1
                : 1
              : 1,
        );
      }
      return sorted;
    }
    return [];
  };

  useEffect(() => {
    setIsLoading(true);
    setHistory([]);

    // get all absent kids when user is employee or admin
    if (user.employee || user.administrationAccess) {
      getKidAbsence()
        .then(res => {
          const data = (res.data || []);
          let kids = [];

          if (showHoliday) kids = kids.concat(data.filter(item => item.vacation === true));
          if (showSickness) kids = kids.concat(data.filter(item => item.vacation === false));

          // filter out all kids which startDate is between formFrom and formTo
          kids = kids.filter(item => {
            let startDate = new Date(item.startDate);
            if (isNaN(startDate)) {
              // for IOS Versions (< IOS 16) this is needed
              const s = item.startDate.split(" ")[0].split("-");
              startDate = new Date(s[0], parseInt(s[1]) - 1, s[2], 0, 0, 0);
            }
            return startDate >= formFrom && startDate <= formTo;
          });

          setHistory(kids);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      getKidAbsenceHistoryForUser()
        .then(res => {
          if (res.status === 200) {
            const kidsArray = res.data.filter(item => item.kid.deactivated == false);
            setHistory(prev => prev.concat(kidsArray || []));
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    fetchMemberHistory();
    // eslint-disable-next-line
  }, [refresh]);

  const prepared = history.map(entry => ({
    name: `${entry.kid.firstName} ${entry.kid.lastName}`,
    returnReceipt: entry.returnReceiptDate !== null,
    ...entry,
  }));
  const sorted = sortList(prepared, sorting);

  const filteredData =
    searchValue.length > 0
      ? sorted.filter(item =>
        (item.kid.firstName + ' ' + item.kid.lastName)
          .toLowerCase()
          .includes(searchValue.toLowerCase()),
      )
      : sorted;

  const setValueFromDropdown = item => {
    let currentDate = new Date();
    let startDate = new Date(item.startDate);
    let endDate = new Date(item.endDate);
    if (startDate.getDate() == currentDate && endDate.getDate() == currentDate) {
      return 0;
    } else if (startDate.getDate() === endDate.getDate()) {
      return 1;
    }
    return 2;
  };

  const editRecord = item => {
    setDuration(setValueFromDropdown(item));
    setEditPopup(true);
    setEditItem(item);
  };

  const deleteRecord = item => {
    setDeletePopup(true);
    setDeleteItem(item);
  };

  const sendFormForDelete = item => {
    const data = {
      id: item.id,
      startDate: item.startDate,
      endDate: item.endDate,
      reason: item.reason,
      sickness: item.sickness,
      vacation: item.vacation,
    };
    if (item.kid.isMember) {
      data.userId = item.kid.id;
    } else {
      data.kidId = item.kid.id;
    }
    setIsLoading(true);
    const deleteMethod = item.kid.isMember ? deleteMemberAbsence : deleteKidAbsence;
    deleteMethod(data).then(res => {
      setIsLoading(false);
      setRefresh(!refresh);
      setDeletePopup(false);
    });
  };

  return (
    <div>
      {editPopup && (
        <Popup
          size='normal'
          isOpened
          closePopup={() => setEditPopup(false)}
          header={<strong>{t('AbsencePopUp.Header')}</strong>}
        >
          <AbsenceFrame
            closeAbsencesPopUp={() => setEditPopup(false)}
            user={user}
            refreshHistory={() => setRefresh(!refresh)}
            editItem={editItem}
            durationNumber={duration}
            memberSelectable={memberSelectable}
          />
        </Popup>
      )}
      {deletePopup && (
        <Popup
          closePopup={() => setDeletePopup(false)}
          isOpened
          footer={
            !isLoading ? (
              <div className={styles.buttonsContainer}>
                <div className={styles.buttonSubmit}>
                  <Button onClick={() => sendFormForDelete(deleteItem)} type='primary'>
                    {t('AccountSettings.AbsenceSettings.Yes')}
                  </Button>
                </div>
                <div className={styles.buttonSubmit}>
                  <Button onClick={() => setDeletePopup(false)} type='primary'>
                    {t('AccountSettings.AbsenceSettings.No')}
                  </Button>
                </div>
              </div>
            ) : (
              <Loader />
            )
          }
        >
          <div className={styles.containerEnable}>
            <h2>Do you want to delete this record?</h2>
          </div>
        </Popup>
      )}
      <div className={styles.searchInputWrap}>
        <Input
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={t('Checkinout.Search by name')}
        />
      </div>

      {/* only show if user is employee or admin */}
      {user.employee || user.administrationAccess ? (
        <div>
          <div className={styles.checkbox}>
            <Checkbox
              isChecked={showHoliday}
              onChange={() => { setShowHoliday(!showHoliday); setRefresh(!refresh); }}
              name={t('AbsencePopup.History.showHoliday')}
            />
            <Checkbox
              isChecked={showSickness}
              onChange={() => { setShowSickness(!showSickness); setRefresh(!refresh); }}
              name={t('AbsencePopup.History.showSickness')}
            />
          </div>

          <div className={styles.dateWrapper}>
            <div className={styles.dateStart}>
              <Label className={styles.label} type='input'>
                {t('AccountSettings.AbsenceSettings.From')}
              </Label>
              <DatePicker
                selected={formFrom}
                onChange={data => { setFormFrom(data); setRefresh(!refresh); }}
                langCode={user.langCode}
              />
            </div>
            <div className={styles.dateEnd}>
              <Label className={styles.label} type='input'>
                {t('AccountSettings.AbsenceSettings.To')}
              </Label>
              <DatePicker
                selected={formTo}
                onChange={data => { setFormTo(data); setRefresh(!refresh); }}
                langCode={user.langCode}
              />
            </div>
          </div>
        </div>
      ) : null}

      <table className={cn(styles.table, styles.noWrap)}>
        <tbody>
          <tr>
            <th className={styles.headerText} onClick={() => sortBy('name')}>
              <div className={styles.headerTextContainer}>
                {t('AbsencePopup.History.Name')}
                <SortIndicator sort={sorting} name='name' />
              </div>
            </th>
            <th className={styles.headerText} onClick={() => sortBy('reason')}>
              <div className={styles.headerTextContainer}>
                {t('GroupBody.Absences.Reason')}
                <SortIndicator sort={sorting} name='reason' />
              </div>
            </th>
            <th className={styles.headerText} onClick={() => sortBy('reportedDate')}>
              <div className={styles.headerTextContainer}>
                {t('GroupBody.Absences.Reported Date')}
                <SortIndicator sort={sorting} name='reportedDate' />
              </div>
            </th>
            <th className={styles.headerText} onClick={() => sortBy('startDate')}>
              <div className={styles.headerTextContainer}>
                {t('GroupBody.Absences.Reported From')}
                <SortIndicator sort={sorting} name='startDate' />
              </div>
            </th>
            <th className={styles.headerText} onClick={() => sortBy('endDate')}>
              <div className={styles.headerTextContainer}>
                {t('GroupBody.Absences.Reported To')}
                <SortIndicator sort={sorting} name='endDate' />
              </div>
            </th>
            <th className={styles.headerText} onClick={() => sortBy('returnReceipt')}>
              <div className={styles.headerTextContainer}>
                {t('GroupBody.Absences.ReturnReceipt')}
                <SortIndicator sort={sorting} name='returnReceipt' />
              </div>
            </th>
            <th>{t('Actions')}</th>
          </tr>
          {isLoading ? (
            <tr>
              <td>
                <Loader />
              </td>
            </tr>
          ) : (
            filteredData.map(item => {
              return (
                <tr key={item.kid.isMember ? `member-${item.id}` : `kid-${item.id}`}>
                  <td className={styles.absenceName}>
                    {item.kid.firstName} {item.kid.lastName}
                  </td>
                  <td className={styles.absenceReason}>
                    {item.vacation && (
                      <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                        [{t('AccountSettings.AbsenceSettings.Vacation')}]
                      </span>
                    )}{' '}
                    {item.reason}
                  </td>
                  <td>{formatTimeToLanguage(item.reportedDate, 'de')}</td>
                  <td>{formatTimeToLanguage(item.startDate, 'de')}</td>
                  <td>{formatTimeToLanguage(item.endDate, 'de')}</td>
                  <td>
                    {item.returnReceiptDate === null ? (
                      <i className='fa fa-lg fa-times' />
                    ) : (
                      <i className='fa fa-lg fa-check' />
                    )}
                  </td>
                  <td>
                    {new Date(item.endDate) >= new Date() &&
                      !(
                        new Date(item.endDate) == new Date() && new Date(item.startDate) == new Date()
                      ) ? (
                      <div className={styles.absenceItemActions}>
                        <i className='fa fa-lg fa-cog' onClick={() => editRecord(item)} />
                        <i className='fa fa-lg fa-trash' onClick={() => deleteRecord(item)} />
                      </div>
                    ) : null}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
}
