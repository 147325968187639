import React from 'react';
// import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import styles from './breakButton.module.scss';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export default function BreakButton({ active, handleClick, breakTime }) {
  const { t } = useTranslation();

  return (
    <button className={styles.breakButtonContainer} onClick={handleClick}>
      <div className={cn(styles.breakButtonWrapper, active && styles.breakButtonWrapperStarted)}>
        <Input className={styles.timeInput} value={breakTime} disabled={true} />
        <div
          className={cn(styles.buttonLabel, active ? styles.buttonRunning : styles.buttonStopped)}
        >
          <label>{t('EmployeeCheckinout.Pause')}</label>
          {active ? (
            <i className='fa fa-stop' aria-hidden='true' />
          ) : (
            <i className='fa fa-play' aria-hidden='true' />
          )}
        </div>
      </div>
    </button>
  );
}
