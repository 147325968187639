import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as kitaApi from 'library/api/kita';
import { validateEmail } from 'library/utilities/validation';
import staticTenantDataProtectionData from 'resources/others/staticTenantDataProtectionData';
import { isAwoWW } from 'library/api/tenantConfig';
import { updateKitaUserFields } from 'library/api/userFields';
import awoLogo from 'resources/images/awoww/awoheart-higher-res.png';

const DefaultCrop = {
  unit: '%',
  x: 0,
  y: 0,
  aspect: 1,
};

const setStandardAwoWWUserFieldsSettings = async (kitaId) => {
  const standardFields = {
    "birthday": {
      "isShownOnUserProfile": false
    },
    "kitaHolidayCare": {
        "isShownOnUserProfile": false
    },
    "lastName": {
        "isShownOnUserProfile": true
    },
    "country": {
        "isShownOnUserProfile": false
    },
    "gender": {
        "isShownOnUserProfile": true
    },
    "city": {
        "isShownOnUserProfile": true
    },
    "kitaCreateKitaCode": {
        "isShownOnUserProfile": true
    },
    "about": {
        "isShownOnUserProfile": false
    },
    "kitaDescription": {
        "isShownOnUserProfile": false
    },
    "title": {
        "isShownOnUserProfile": true
    },
    "kitaName": {
        "isShownOnUserProfile": true
    },
    "mainLanguage": {
        "isShownOnUserProfile": false
    },
    "denomination": {
        "isShownOnUserProfile": false
    },
    "kitaBelongToCity": {
        "isShownOnUserProfile": true
    },
    "qrCode": {
        "isShownOnUserProfile": false
    },
    "kitaDataProtector": {
        "isShownOnUserProfile": false
    },
    "street": {
        "isShownOnUserProfile": true
    },
    "kitaImage": {
        "isShownOnUserProfile": true
    },
    "state": {
        "isShownOnUserProfile": false
    },
    "blockUsersFromMessaging": {
        "isShownOnUserProfile": true
    },
    "kitaEmail": {
        "isShownOnUserProfile": true
    },
    "zip": {
        "isShownOnUserProfile": true
    },
    "workingPlace": {
        "isShownOnUserProfile": false
    },
    "kitaPhoneNumber": {
        "isShownOnUserProfile": true
    },
    "kitaChiefName": {
        "isShownOnUserProfile": false
    },
    "kitaReligion": {
        "isShownOnUserProfile": false
    },
    "hideUser": {
        "isShownOnUserProfile": true
    },
    "kitaColor": {
        "isShownOnUserProfile": false
    },
    "userName": {
        "isShownOnUserProfile": true
    },
    "kitaWebsite": {
        "isShownOnUserProfile": false
    },
    "kitaVerifyImages": {
        "isShownOnUserProfile": false
    },
    "firstName": {
        "isShownOnUserProfile": true
    },
    "personSingleParent": {
        "isShownOnUserProfile": false
    },
    "importId": {
        "isShownOnUserProfile": false
    },
    "kitaAddress": {
        "isShownOnUserProfile": true
    },
    "kitaIsACity": {
      "isShownOnUserProfile": true
    },
    "kitaOrganization": {
        "isShownOnUserProfile": false
    },
    "personWithSoleCustody": {
        "isShownOnUserProfile": false
    },
    "motherLanguage": {
        "isShownOnUserProfile": false
    },
    "maritalStatus": {
        "isShownOnUserProfile": false
    }
  }
  await updateKitaUserFields(kitaId, { fields: standardFields, customFields: [] });
}

export const getCityOptions = t => [
  { label: t('Dortmund'), value: 'Dortmund' },
  { label: t('Ennepe-Ruhr-Kreis'), value: 'Ennepe-Ruhr-Kreis' },
  { label: t('Bottrop'), value: 'Bottrop' },
  { label: t('Gelsenkirchen'), value: 'Gelsenkirchen' },
  { label: t('Hagen'), value: 'Hagen' },
  { label: t('Märkischer Kreis'), value: 'Märkischer Kreis' },
  { label: t('Hochsauerlandkreis'), value: 'Hochsauerlandkreis' },
  { label: t('Kreis Soest'), value: 'Kreis Soest' },
  { label: t('Kreis Borken'), value: 'Kreis Borken' },
  { label: t('Kreis Coesfeld'), value: 'Kreis Coesfeld' },
  { label: t('Münster'), value: 'Münster' },
  { label: t('Kreis Recklinghausen'), value: 'Kreis Recklinghausen' },
  { label: t('Kreis Steinfurt'), value: 'Kreis Steinfurt' },
  { label: t('Bochum'), value: 'Bochum' },
  { label: t('Herne'), value: 'Herne' },
  { label: t('Kreis Siegen-Wittgenstein/Olpe'), value: 'Kreis Siegen-Wittgenstein/Olpe' },
  { label: t('Hamm'), value: 'Hamm' },
  { label: t('Kreis Unna'), value: 'Kreis Unna' },
  { label: t('Kreis Warendorf'), value: 'Kreis Warendorf' },
  { label: t('Kreis Märkischer Kreis'), value: 'Kreis Märkischer Kreis' },
];

export default function useCreateKita({
  isEditing,
  fields,
  showBottomNotification,
  providerCheck,
  formProvider,
}) {
  const { t } = useTranslation();
  const currentTenantUrl = window.location.hostname.toLowerCase();

  function returnStaticTenantDataProtectionData(field) {
    const obj = staticTenantDataProtectionData.find(o => currentTenantUrl.includes(o.url));
    if (
      obj !== null &&
      typeof obj !== 'undefined' &&
      Object.prototype.hasOwnProperty.call(obj, field)
    ) {
      return obj[field];
    }
  }
  const [verificationImages, setVerificationImages] = useState({
    image1: null,
    image2: null,
    image3: null,
    image4: null,
  });
  const [defaultImages, setDefaultImages] = useState({
    image1: null,
    image2: null,
    image3: null,
    image4: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(fields.kitaName || '');
  const [nameError, setNameError] = useState('');

  const [email, setEmail] = useState(fields.email || '');
  const [emailError, setEmailError] = useState('');

  const [houseNo, setHouseNo] = useState(fields.houseNo || '');
  const [houseNoError, setHouseNoError] = useState('');

  const [phoneNo, setPhoneNo] = useState(fields.phoneNo || '');
  const [phoneNoError, setPhoneNoError] = useState('');

  const [street, setStreet] = useState(fields.streetName || '');
  const [city, setCity] = useState(fields.city || '');
  const [zipCode, setZipCode] = useState(fields.zipCode || '');
  const [streetError, setStreetError] = useState('');
  const [cityError, setCityError] = useState('');
  const [zipCodeError, setZipCodeError] = useState('');

  const [belongedCity, setBelongedCity] = useState(
    fields && fields.belongedCity
      ? {
          value: fields.belongedCity,
          label: getCityOptions(t).find(cityOption => cityOption.value === fields.belongedCity)
            ?.label,
        }
      : { value: '' },
  );

  const [organizationName, setOrganizationName] = useState(fields.organizationName || '');
  const [organizationRepresentedBy, setOrganizationRepresentedBy] = useState(
    fields.organizationRepresentedBy || '',
  );
  const [organizationAddress, setOrganizationAddress] = useState(fields.organizationAddress || '');
  const [organizationNameError, setOrganizationNameError] = useState('');
  const [organizationRepresentedByError, setOrganizationRepresentedByError] = useState('');
  const [organizationAddressError, setOrganizationAddressError] = useState('');

  const [providerId] = useState(fields.providerId || '');
  const [providerName, setProviderName] = useState(fields.providerName || '');
  const [kitaProviderStatus] = useState(fields.kitaProviderStatus || '');

  const [dataProtectionOfficerName, setDataProtectionOfficerName] = useState(
    fields.dataProtectionOfficerName || returnStaticTenantDataProtectionData('name') || '',
  );
  const [dataProtectionOfficerNameError, setDataProtectionOfficerNameError] = useState('');
  const [dataProtectionOfficerCompany, setDataProtectionOfficerCompany] = useState(
    fields.dataProtectionOfficerCompany || returnStaticTenantDataProtectionData('company') || '',
  );
  const [dataProtectionOfficerCompanyError, setDataProtectionOfficerCompanyError] = useState('');
  const [dataProtectionOfficerCity, setDataProtectionOfficerCity] = useState(
    fields.dataProtectionOfficerCity || returnStaticTenantDataProtectionData('city') || '',
  );
  const [dataProtectionOfficerCityError, setDataProtectionOfficerCityError] = useState('');
  const [dataProtectionOfficerStreet, setDataProtectionOfficerStreet] = useState(
    fields.dataProtectionOfficerStreet || returnStaticTenantDataProtectionData('street') || '',
  );
  const [dataProtectionOfficerStreetError, setDataProtectionOfficerStreetError] = useState('');
  const [dataProtectionOfficerZip, setDataProtectionOfficerZip] = useState(
    fields.dataProtectionOfficerZip || returnStaticTenantDataProtectionData('zip') || '',
  );
  const [dataProtectionOfficerZipError, setDataProtectionOfficerZipError] = useState('');
  const [dataProtectionOfficerPhone, setDataProtectionOfficerPhone] = useState(
    fields.dataProtectionOfficerPhone || returnStaticTenantDataProtectionData('phone') || '',
  );
  const [dataProtectionOfficerPhoneError, setDataProtectionOfficerPhoneError] = useState('');
  const [dataProtectionOfficerEmail, setDataProtectionOfficerEmail] = useState(
    fields.dataProtectionOfficerEmail || returnStaticTenantDataProtectionData('email') || '',
  );
  const [dataProtectionOfficerEmailError, setDataProtectionOfficerEmailError] = useState('');

  const [dataProcessingName, setDataProcessingName] = useState(
    fields.dataProcessingName || returnStaticTenantDataProtectionData('name') || '',
  );
  const [dataProcessingNameError, setDataProcessingNameError] = useState('');
  const [dataProcessingCompany, setDataProcessingCompany] = useState(
    fields.dataProcessingCompany || returnStaticTenantDataProtectionData('company') || '',
  );
  const [dataProcessingCompanyError, setDataProcessingCompanyError] = useState('');
  const [dataProcessingCity, setDataProcessingCity] = useState(
    fields.dataProcessingCity || returnStaticTenantDataProtectionData('city') || '',
  );
  const [dataProcessingCityError, setDataProcessingCityError] = useState('');
  const [dataProcessingStreet, setDataProcessingStreet] = useState(
    fields.dataProcessingStreet || returnStaticTenantDataProtectionData('street') || '',
  );
  const [dataProcessingStreetError, setDataProcessingStreetError] = useState('');
  const [dataProcessingZip, setDataProcessingZip] = useState(
    fields.dataProcessingZip || returnStaticTenantDataProtectionData('zip') || '',
  );
  const [dataProcessingZipError, setDataProcessingZipError] = useState('');
  const [dataProcessingPhone, setDataProcessingPhone] = useState(
    fields.dataProcessingPhone || returnStaticTenantDataProtectionData('phone') || '',
  );
  const [dataProcessingPhoneError, setDataProcessingPhoneError] = useState('');
  const [dataProcessingEmail, setDataProcessingEmail] = useState(
    fields.dataProcessingEmail || returnStaticTenantDataProtectionData('email') || '',
  );
  const [dataProcessingEmailError, setDataProcessingEmailError] = useState('');

  const [description, setDescription] = useState(fields.description || '');

  const [kitaCode, setKitaCode] = useState(fields.kitaCode || '');
  const [kitaCodeError, setKitaCodeError] = useState('');

  const [religion, setReligion] = useState(
    fields && fields.religionId && fields.religionId !== null
      ? {
          id: fields.religionId,
          label:
            fields.religionId === 1
              ? t('Administration.Kita.Secular')
              : fields.religionId === 2
              ? t('Administration.Kita.Catholic')
              : t('Administration.Kita.Protestant'),
        }
      : { id: 0 },
  );

  const standardColor = isAwoWW() ? '#e2001A' : '#afcb05';
  const [colorCode, setColorCode] = useState(fields.colorCode || standardColor);

  const [kitaTypes, setKitaTypes] = useState([]);
  const [kitaType, setKitaType] = useState(
    (fields && fields.kitaType && fields.kitaType.id !== 'undefined' && fields.kitaType.id) || {
      id: 1,
      label: 'Kita',
    },
  );

  const [providerZip, setProviderZip] = useState(fields.providerZip || '');

  const [providerZipError, setProviderZipError] = useState('');
  const [providerCode, setProviderCode] = useState(fields.providerCode || '');
  const [providerCodeError, setProviderCodeError] = useState('');

  const [kitaChiefName, setKitaChiefName] = useState(fields.kitaChiefName || '');

  const [crop, setCrop] = useState({
    unit: '%',
    x: fields.kitaLogoXCoordinate || 0,
    y: fields.kitaLogoYCoordinate || 0,
    width: fields.kitaLogoWidth,
    height: fields.kitaLogoWidth,
    aspect: 1,
  });

  const [verificationImageCrops, setVerificationImageCrops] = useState({
    image1: DefaultCrop,
    image2: DefaultCrop,
    image3: DefaultCrop,
    image4: DefaultCrop,
  });

  const [file, setFile] = useState(null);
  const [verificationImageFiles, setVerificationImageFiles] = useState({
    image1: null,
    image2: null,
    image3: null,
    image4: null,
  });

  const [logoUrl, setLogoUrl] = useState(fields.logoUrl);
  const [isACity, setIsACity] = useState(fields.isACity || isAwoWW());
  const [verificationImageUrls, setVerificationImageUrls] = useState({
    image1: null,
    image2: null,
    image3: null,
    image4: null,
  });
  const [imagesFetched, setImagesFetched] = useState(false);

  // TODO: fetch holiday-care data from BE after BE is done & integrate holidaycare state with kitaApi.createKita
  const [holidayCares, setHolidayCares] = useState([]);
  const [additionalHolidayCareLabel, setAdditionalHolidayCareLabel] = useState('');
  const [additionalHolidayCareStartDate, setAdditionalHolidayCareStartDate] = useState('');
  const [additionalHolidayCareEndDate, setAdditionalHolidayCareEndDate] = useState('');
  const [additionalHolidayCareStartTime, setAdditionalHolidayCareStartTime] = useState('');
  const [additionalHolidayCareEndTime, setAdditionalHolidayCareEndTime] = useState('');

  useEffect(() => {
    (async () => {
      if (fields.id) {
        const res = await kitaApi.getKitaHolidayCares(fields.id);
        setHolidayCares(res.data);
      }
    })();
  }, []);

  useEffect(() => {
    kitaApi.getKitaTypes().then(({ data }) => {
      setKitaTypes(
        data.map(el => ({
          ...el.kitaTypeTranslation,
          label: el.kitaTypeTranslation?.kitaTypeName ?? 'Error: No name was given',
          kitaId: el.id,
        })),
      );
    });
    kitaApi.getKitaVerificationImages(fields.id).then(res => {
      if (res.status === 200 && res.data.length > 0) {
        const result = res.data;
        result.sort((a, b) => a.sequence - b.sequence);

        const images = {};
        const urls = {};
        const crops = {};
        result.forEach((item, index) => {
          images[`image${index + 1}`] = { isDefault: item.isDefault };
          urls[`image${index + 1}`] = item.url;
          crops[`image${index + 1}`] = {
            ...verificationImageCrops[`image${index + 1}`],
            x: item.xCoordinate || 0,
            y: item.yCoordinate || 0,
            width: item.width,
            height: item.width,
            aspect: item.aspect,
          };
        });
        setVerificationImages(images);
        setVerificationImageUrls(urls);
        setVerificationImageCrops(crops);
        setImagesFetched(true);
      }
    });
    kitaApi.getDefaultVerificationImages().then(res => {
      if (res.status === 200 && res.data.length > 0) {
        const result = res.data;
        result.sort((a, b) => a.sequence - b.sequence);
        const images = {};
        result.forEach((item, index) => {
          images[`image${index + 1}`] = item;
        });
        setDefaultImages(images);
      }
    });
    // eslint-disable-next-line
  }, []);

  const createKitaCode = async () => {
    const { data } = await kitaApi.createKitaCode();
    setKitaCode(data.kitaCode);
    setKitaCodeError('');
  };

  const createKita = async () => {
    if (isLoading) {
      return;
    }
    let isError = false;
    if (name.trim().length === 0) {
      isError = true;
      setNameError(`${t('Administration.Kita.Name')} ${t('Administration.Kita.cannot be blank')}`);
    } else {
      setNameError('');
    }

    if (email.trim().length === 0) {
      isError = true;
      setEmailError(
        `${t('Administration.Kita.Email')} ${t('Administration.Kita.cannot be blank')}`,
      );
    } else if (!validateEmail(email)) {
      isError = true;
      setEmailError(t('Administration.Kita.Email is incorrect'));
    } else {
      setEmailError('');
    }

    if (phoneNo.trim().length === 0) {
      isError = true;
      setPhoneNoError(
        `${t('Administration.Kita.Phone number')} ${t('Administration.Kita.cannot be blank')}`,
      );
    } else {
      setPhoneNoError('');
    }

    if (street.trim().length === 0) {
      isError = true;
      setStreetError(
        `${t('Administration.Kita.Street address')} ${t('Administration.Kita.cannot be blank')}`,
      );
    } else {
      setStreetError('');
    }

    if (city.trim().length === 0) {
      isError = true;
      setCityError(
        `${t('Administration.Kita.City Town')} ${t('Administration.Kita.cannot be blank')}`,
      );
    } else {
      setCityError('');
    }

    if (zipCode.trim().length === 0) {
      isError = true;
      setZipCodeError(
        `${t('Administration.Kita.Zip Code')} ${t('Administration.Kita.cannot be blank')}`,
      );
    } else {
      setZipCodeError('');
    }

    if (organizationName.trim().length === 0) {
      isError = true;
      setOrganizationNameError(
        `${t('Administration.Kita.Organization Name')} ${t('Administration.Kita.cannot be blank')}`,
      );
    } else {
      setOrganizationNameError('');
    }

    if (organizationRepresentedBy.trim().length === 0) {
      isError = true;
      setOrganizationRepresentedByError(
        `${t('Administration.Kita.Organization Represented By')} ${t(
          'Administration.Kita.cannot be blank',
        )}`,
      );
    } else {
      setOrganizationRepresentedByError('');
    }

    if (organizationAddress.trim().length === 0) {
      isError = true;
      setOrganizationAddressError(
        `${t('Administration.Kita.Organization Address')} ${t(
          'Administration.Kita.cannot be blank',
        )}`,
      );
    } else {
      setOrganizationAddressError('');
    }

    if (houseNo.trim().length === 0) {
      isError = true;
      setHouseNoError(
        `${t('Administration.Kita.House number')} ${t('Administration.Kita.cannot be blank')}`,
      );
    } else {
      setHouseNoError('');
    }

    if (dataProtectionOfficerName.trim().length === 0) {
      isError = true;
      setDataProtectionOfficerNameError(
        `${t('Administration.Kita.Data Protection Officer')} ${t(
          'Administration.Kita.cannot be blank',
        )}`,
      );
    } else {
      setDataProtectionOfficerNameError('');
    }

    if (dataProtectionOfficerCompany.trim().length === 0) {
      isError = true;
      setDataProtectionOfficerCompanyError(
        `${t('Administration.Kita.Data Protection Company')} ${t(
          'Administration.Kita.cannot be blank',
        )}`,
      );
    } else {
      setDataProtectionOfficerCompanyError('');
    }

    if (dataProtectionOfficerCity.trim().length === 0) {
      isError = true;
      setDataProtectionOfficerCityError(
        `${t('Administration.Kita.Data Protection City')} ${t(
          'Administration.Kita.cannot be blank',
        )}`,
      );
    } else {
      setDataProtectionOfficerCityError('');
    }

    if (dataProtectionOfficerStreet.trim().length === 0) {
      isError = true;
      setDataProtectionOfficerStreetError(
        `${t('Administration.Kita.Data Protection Street')} ${t(
          'Administration.Kita.cannot be blank',
        )}`,
      );
    } else {
      setDataProtectionOfficerStreetError('');
    }

    if (dataProtectionOfficerZip.trim().length === 0) {
      isError = true;
      setDataProtectionOfficerZipError(
        `${t('Administration.Kita.Data Protection Street')} ${t(
          'Administration.Kita.cannot be blank',
        )}`,
      );
    } else {
      setDataProtectionOfficerZipError('');
    }

    if (dataProcessingName.trim().length === 0) {
      isError = true;
      setDataProcessingNameError(
        `${t('Administration.Kita.Data Protection Officer')} ${t(
          'Administration.Kita.cannot be blank',
        )}`,
      );
    } else {
      setDataProcessingNameError('');
    }

    if (dataProcessingCompany.trim().length === 0) {
      isError = true;
      setDataProcessingCompanyError(
        `${t('Administration.Kita.Data Protection Company')} ${t(
          'Administration.Kita.cannot be blank',
        )}`,
      );
    } else {
      setDataProcessingCompanyError('');
    }

    if (dataProcessingCity.trim().length === 0) {
      isError = true;
      setDataProcessingCityError(
        `${t('Administration.Kita.Data Protection City')} ${t(
          'Administration.Kita.cannot be blank',
        )}`,
      );
    } else {
      setDataProcessingCityError('');
    }

    if (dataProcessingStreet.trim().length === 0) {
      isError = true;
      setDataProcessingStreetError(
        `${t('Administration.Kita.Data Protection Street')} ${t(
          'Administration.Kita.cannot be blank',
        )}`,
      );
    } else {
      setDataProcessingStreetError('');
    }

    if (dataProcessingZip.trim().length === 0) {
      isError = true;
      setDataProcessingZipError(
        `${t('Administration.Kita.Data Protection Street')} ${t(
          'Administration.Kita.cannot be blank',
        )}`,
      );
    } else {
      setDataProcessingZipError('');
    }

    if (kitaCode.trim().length === 0) {
      isError = true;
      setKitaCodeError(
        `${t('Administration.Kita.Kita Code')} ${t('Administration.Kita.cannot be blank')}`,
      );
    } else {
      setKitaCodeError('');
    }

    if (providerCheck) {
      if (providerZip !== formProvider.zip) {
        isError = true;
        setProviderZipError(
          `${t('Administration.Kita.Provider Postcode')} ${t(
            'Administration.Kita.is not correct',
          )}`,
        );
      } else {
        setProviderZipError('');
      }

      if (providerCode !== formProvider.kitaCode) {
        isError = true;
        setProviderCodeError(
          `${t('Administration.Kita.Provider Code')} ${t('Administration.Kita.is not correct')}`,
        );
      } else {
        setProviderCodeError('');
      }
      formProvider.pending = 'PENDING';
    } else {
      if (!providerId) {
        formProvider.value = 0;
      } else {
        formProvider.value = providerId;
      }
      if (!providerName) {
        formProvider.label = '';
      } else {
        formProvider.label = providerName;
      }
      if (!kitaProviderStatus) {
        formProvider.pending = '';
      } else {
        formProvider.pending = kitaProviderStatus;
      }
    }

    if (isError) return;
    setIsLoading(true);

    const getImage = async () => {
      if(!isEditing && isAwoWW() && !file) {
        const response = await fetch(awoLogo);
        const blob = await response.blob();
        return new File([blob], 'logo.png', { type: "image/png" });
      } else {
        return file;
      }
    }

    try {
      const { data } = await (isEditing ? kitaApi.updateKita : kitaApi.createKita)({
        city,
        colorCode,
        description,
        email,
        houseNo,
        kitaCode,
        kitaName: name,
        phoneNo,
        streetName: street,
        kitaChiefName,
        dataProtectionOfficerName,
        dataProtectionOfficerCompany,
        dataProtectionOfficerCity,
        dataProtectionOfficerStreet,
        dataProtectionOfficerZip,
        dataProtectionOfficerPhone,
        dataProtectionOfficerEmail,
        dataProcessingName,
        dataProcessingCompany,
        dataProcessingCity,
        dataProcessingStreet,
        dataProcessingZip,
        dataProcessingPhone,
        dataProcessingEmail,
        zipCode,
        kitaTypeId:
          kitaType && kitaType.kitaId && kitaType.kitaId !== 'undefined' ? kitaType.kitaId : 1,
        kitaProviderStatus: formProvider.pending,
        providerId: formProvider.value,
        providerName: formProvider.label,
        kitaId: fields.id,
        xCoordinate: crop.x,
        yCoordinate: crop.y,
        imageAspect: crop.aspect,
        image: await getImage(),
        imageWidth: crop.width || 0,
        removeKitaLogoStatus: !logoUrl,
        ...(!verificationImages.image1?.isDefault && {
          verifyImage1XCoordinate: verificationImageCrops.image1.x,
          verifyImage1YCoordinate: verificationImageCrops.image1.y,
          verifyImage1Aspect: verificationImageCrops.image1.aspect,
          verifyImage1: verificationImageFiles.image1,
          verifyImage1Width: verificationImageCrops.image1.width || 0,
        }),
        ...(!verificationImages.image2?.isDefault && {
          verifyImage2XCoordinate: verificationImageCrops.image2.x,
          verifyImage2YCoordinate: verificationImageCrops.image2.y,
          verifyImage2Aspect: verificationImageCrops.image2.aspect,
          verifyImage2: verificationImageFiles.image2,
          verifyImage2Width: verificationImageCrops.image2.width || 0,
        }),
        ...(!verificationImages.image3?.isDefault && {
          verifyImage3XCoordinate: verificationImageCrops.image3.x,
          verifyImage3YCoordinate: verificationImageCrops.image3.y,
          verifyImage3Aspect: verificationImageCrops.image3.aspect,
          verifyImage3: verificationImageFiles.image3,
          verifyImage3Width: verificationImageCrops.image3.width || 0,
        }),
        ...(!verificationImages.image4?.isDefault && {
          verifyImage4XCoordinate: verificationImageCrops.image4.x,
          verifyImage4YCoordinate: verificationImageCrops.image4.y,
          verifyImage4Aspect: verificationImageCrops.image4.aspect,
          verifyImage4: verificationImageFiles.image4,
          verifyImage4Width: verificationImageCrops.image4.width || 0,
        }),
        removeKitaVerifyImage1Status: verificationImages.image1?.isDefault || false,
        removeKitaVerifyImage2Status: verificationImages.image2?.isDefault || false,
        removeKitaVerifyImage3Status: verificationImages.image3?.isDefault || false,
        removeKitaVerifyImage4Status: verificationImages.image4?.isDefault || false,
        religionId: religion.id,
        belongedCity: belongedCity.value,
        organizationName,
        organizationRepresentedBy,
        organizationAddress,
        isACity,
      });

      if (data?.error === 'Already Reported') {
        setKitaCodeError(data.message || t('Administration.Kita.Kita name is already exists'));
        setIsLoading(false);
      } else {
        await (isEditing ? kitaApi.updateKitaHolidayCares : kitaApi.updateKitaHolidayCares)({
          kitaId: data.id,
          kitaHolidayCares: holidayCares,
        });

        if(isAwoWW() && !isEditing) {
          await setStandardAwoWWUserFieldsSettings(data.id);
        }

        showBottomNotification(t('BottomNotifications.Saved'));
        setIsLoading(false);
        return data;
      }
    } catch (error) {
      if (error?.response?.data && error?.response?.data?.message) {
        if (error?.response?.status === 403) {
          showBottomNotification(t('BottomNotifications.Permission is not allowed'), {
            isFail: true,
          });
        } else {
          showBottomNotification(t('BottomNotifications.API error'), {
            isFail: true,
          });
        }
      } else {
        showBottomNotification(t('BottomNotifications.Unexpected error'), {
          isFail: true,
        });
      }
      setIsLoading(false);
    }
  };

  const uploadFile = files => {
    setFile(files[0]);
    setCrop({
      unit: '%',
      x: 0,
      y: 0,
      width: 100,
      height: 100,
      aspect: 1,
    });
    setLogoUrl(URL.createObjectURL(files[0]));
  };
  const deleteFile = () => {
    setFile(null);
    setLogoUrl(null);
  };

  const uploadVerificationImageFile = (files, key) => {
    setVerificationImages(prevState => ({
      ...prevState,
      [key]: {
        isDefault: false,
      },
    }));
    setVerificationImageFiles(prevState => ({
      ...prevState,
      [key]: files[0],
    }));
    setVerificationImageCrops(prevState => ({
      ...prevState,
      [key]: {
        unit: '%',
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        aspect: 1,
      },
    }));
    setVerificationImageUrls(prevState => ({
      ...prevState,
      [key]: URL.createObjectURL(files[0]),
    }));
  };

  const updateVerificationImageCrop = (key, newCrop) => {
    setVerificationImageCrops(prevState => ({
      ...prevState,
      [key]: newCrop,
    }));
  };

  const deleteVerificationImageFile = key => {
    setVerificationImages(prevState => ({
      ...prevState,
      [key]: {
        isDefault: true,
      },
    }));
    setVerificationImageFiles(prevState => ({
      ...prevState,
      [key]: null,
    }));
    setVerificationImageUrls(prevState => ({
      ...prevState,
      [key]: defaultImages[key].url,
    }));
  };

  return {
    name,
    setName,
    nameError,
    setNameError,
    email,
    setEmail,
    emailError,
    setEmailError,
    kitaChiefName,
    setKitaChiefName,
    phoneNo,
    setPhoneNo,
    phoneNoError,
    setPhoneNoError,
    street,
    setStreet,
    streetError,
    setStreetError,
    city,
    setCity,
    cityError,
    setCityError,
    zipCode,
    setZipCode,
    zipCodeError,
    setZipCodeError,
    houseNo,
    setHouseNo,
    houseNoError,
    setHouseNoError,
    belongedCity,
    setBelongedCity,
    providerZip,
    setProviderZip,
    providerName,
    setProviderName,
    providerZipError,
    setProviderZipError,
    providerCode,
    setProviderCode,
    providerCodeError,
    setProviderCodeError,
    dataProtectionOfficerName,
    setDataProtectionOfficerName,
    dataProtectionOfficerNameError,
    setDataProtectionOfficerNameError,
    dataProtectionOfficerCompany,
    setDataProtectionOfficerCompany,
    dataProtectionOfficerCompanyError,
    setDataProtectionOfficerCompanyError,
    dataProtectionOfficerCity,
    setDataProtectionOfficerCity,
    dataProtectionOfficerCityError,
    setDataProtectionOfficerCityError,
    dataProtectionOfficerStreet,
    setDataProtectionOfficerStreet,
    dataProtectionOfficerStreetError,
    setDataProtectionOfficerStreetError,
    dataProtectionOfficerZip,
    setDataProtectionOfficerZip,
    dataProtectionOfficerZipError,
    setDataProtectionOfficerZipError,
    dataProtectionOfficerPhone,
    setDataProtectionOfficerPhone,
    dataProtectionOfficerPhoneError,
    setDataProtectionOfficerPhoneError,
    dataProtectionOfficerEmail,
    setDataProtectionOfficerEmail,
    dataProtectionOfficerEmailError,
    setDataProtectionOfficerEmailError,
    dataProcessingName,
    setDataProcessingName,
    dataProcessingNameError,
    setDataProcessingNameError,
    dataProcessingCompany,
    setDataProcessingCompany,
    dataProcessingCompanyError,
    setDataProcessingCompanyError,
    dataProcessingCity,
    setDataProcessingCity,
    dataProcessingCityError,
    setDataProcessingCityError,
    dataProcessingStreet,
    setDataProcessingStreet,
    dataProcessingStreetError,
    setDataProcessingStreetError,
    dataProcessingZip,
    setDataProcessingZip,
    dataProcessingZipError,
    setDataProcessingZipError,
    dataProcessingPhone,
    setDataProcessingPhone,
    dataProcessingPhoneError,
    setDataProcessingPhoneError,
    dataProcessingEmail,
    setDataProcessingEmail,
    dataProcessingEmailError,
    setDataProcessingEmailError,
    religion,
    setReligion,
    kitaType,
    setKitaType,
    kitaTypes,
    description,
    setDescription,
    kitaCode,
    kitaCodeError,
    createKita,
    createKitaCode,
    colorCode,
    setColorCode,
    isLoading,
    crop,
    setCrop,
    logoUrl,
    uploadFile,
    deleteFile,
    verificationImages,
    defaultImages,
    verificationImageCrops,
    verificationImageUrls,
    uploadVerificationImageFile,
    updateVerificationImageCrop,
    deleteVerificationImageFile,
    imagesFetched,
    organizationName,
    setOrganizationNameError,
    setOrganizationName,
    organizationNameError,
    organizationRepresentedBy,
    setOrganizationRepresentedByError,
    setOrganizationRepresentedBy,
    organizationRepresentedByError,
    organizationAddress,
    setOrganizationAddressError,
    setOrganizationAddress,
    organizationAddressError,
    holidayCares,
    setHolidayCares,
    additionalHolidayCareLabel,
    setAdditionalHolidayCareLabel,
    additionalHolidayCareStartDate,
    setAdditionalHolidayCareStartDate,
    additionalHolidayCareEndDate,
    setAdditionalHolidayCareEndDate,
    additionalHolidayCareStartTime,
    setAdditionalHolidayCareStartTime,
    additionalHolidayCareEndTime,
    setAdditionalHolidayCareEndTime,
    isACity,
    setIsACity,
  };
}
