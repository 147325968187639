import React, { useState } from 'react';
import styles from './calendarPrint.module.scss';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useTranslation } from 'react-i18next';

const CalendarPrint = ({ events, year, kitaName, groupInfo }) => {
  const { t } = useTranslation();

  const printCalendar = async () => {
    const doc = new jsPDF();

    doc.setDocumentProperties({
      title: year.toString(),
      subject: year.toString(),
    });

    // filter events by year
    const yearEvents = events.filter(event => new Date(event.start).getFullYear() === year);

    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    // position in the pdf document
    let y = 12;

    let title = kitaName;
    if (groupInfo && groupInfo.groupName && !groupInfo.defaultGroup) {
      title += ` - ${groupInfo.groupName}`;
    }
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(20);
    doc.text(title, 10, y);
    y += 12;
    // minimum height for a new month section, so there is no page break between the month name and the table
    const minHeightNewMonthSection = 50;
    const pageHeight = doc.internal.pageSize.getHeight();
    months.forEach(month => {
      if (y + minHeightNewMonthSection > pageHeight) {
        doc.addPage();
        y = 10;
      }
      const monthName = new Date(2021, month - 1, 1).toLocaleString('default', { month: 'long' });

      doc.setFont('helvetica', 'bold');
      doc.setFontSize(16);
      doc.text(monthName, 10, y);
      y += 7;

      // get events for the month
      const monthEvents = yearEvents.filter(
        event => new Date(event.start).getMonth() === month - 1,
      );

      // order month events by date
      monthEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

      const tableData = monthEvents.map(event => {
        const date = new Date(event.start).toLocaleString('de-DE', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
        const dateDayName = new Date(event.start).toLocaleString('de-DE', { weekday: 'long' });
        const time = new Date(event.start).toLocaleString('de-DE', {
          hour: '2-digit',
          minute: '2-digit',
        });
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = event.description;
        return [
          // Date with format dd.mm.yyyy and time hh:mm
          event.allDay ? `${date} (${dateDayName})` : `${date} (${dateDayName}) ${time}`,
          event.title,
          tempDiv.textContent,
          event.location,
        ];
      });

      autoTable(doc, {
        startY: y,
        head: [
          [
            t('Calendar.Date'),
            t('Calendar.Subject'),
            t('Calendar.Description'),
            t('Calendar.Location'),
          ],
        ],
        body: tableData,
        styles: { fontSize: 10, cellPadding: 3 },
        margin: { left: 10, right: 10 },
      });

      y = doc.lastAutoTable.finalY + 10;
    });

    doc.autoPrint();
    doc.output('dataurlnewwindow');
  };
  return (
    <div className={styles.actionsContainer}>
      <div className={styles.action} onClick={() => printCalendar()} title={t('Calendar.print')}>
        <i className='fa fa-print' />
      </div>
    </div>
  );
};

export default CalendarPrint;
