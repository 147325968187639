export function mapAddDayTypeToPrice(type) {
  if (type === 'wholeDay') {
    return 175;
  }
  if (type === 'morningWithFood') {
    return 123;
  }
  if (type === 'afternoonWithoutFood') {
    return 88;
  }
  if (type === 'morningWithoutFood') {
    return 88;
  }
  if (type === 'afternoonWithFood') {
    return 123;
  }
  if (type === 'wholeDayAlt') {
    return 155;
  }
  if (type === 'morningWithFoodAlt') {
    return 109;
  }
  if (type === 'afternoonWithoutFoodAlt') {
    return 78;
  }
  if (type === 'morningWithoutFoodAlt') {
    return 78;
  }
  if (type === 'afternoonWithFoodAlt') {
    return 109;
  }
  return 0;
}
