import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Popup from "../../../Popups/Popup";
import Button from "../../../Buttons/Button";

import moment from 'moment/min/moment-with-locales';
import { markKidAbsenceRead } from "../../../../../api/user";

import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import ButtonWithLoader from "../../../Buttons/ButtonWithLoader";

export default function CalendarInspectAbsencePopup({ absence, setAbsence, reloadEvents, isAdmin }) {
  const { t } = useTranslation();

  const [isConfirming, setIsConfirming] = useState(false);

  const confirmAbsence = (item) => {
    if (item.returnReceiptDate === null) {
      setIsConfirming(true);
      markKidAbsenceRead(item.id)
        .then(() => {
          setAbsence(null);
          if (reloadEvents)
            reloadEvents();
          store.dispatch(
            showBottomNotification(t('CalendarAbsencePopup.ConfirmSuccess'), {
              isFail: false,
            }),
          );
        })
        .catch(() => {
          store.dispatch(
            showBottomNotification(t('CalendarAbsencePopup.ConfirmError'), {
              isFail: true,
            }),
          );
        })
        .finally(() => {
          setIsConfirming(false);
        });
    }
  };

  return (
    <div>
      {absence != null && (
        <Popup
          isOpened
          closePopup={() => setAbsence(null)}
          size='normal'
          header={t('CalendarAbsencePopup.Title')}
          body={
            <div>
              <p><span style={{ fontWeight: 'bold' }}>{t('CalendarAbsencePopup.ChildName')}</span> {absence.kid.firstName} {absence.kid.lastName}</p>
              <p><span style={{ fontWeight: 'bold' }}>{t('CalendarAbsencePopup.Reason')}</span> {absence.reason}</p>
              <p>
                {absence?.vacation
                  ? <span style={{ fontWeight: 'bold' }}>{t("AccountSettings.AbsenceSettings.Vacation")}</span>
                  : <span style={{ fontWeight: 'bold' }}>{t("CalendarAbsencePopup.Sickness")}</span>
                }
                {absence.sickness}
              </p>
              <p><span style={{ fontWeight: 'bold' }}>{t('CalendarAbsencePopup.ReportDate')}</span> {moment(absence.reportedDate).format("DD.MM.YYYY HH:mm")}</p>
              <p><span style={{ fontWeight: 'bold' }}>{t('CalendarAbsencePopup.ReportedBy')}</span> {absence.reportedBy.firstName} {absence.reportedBy.lastName}</p>
              <p><span style={{ fontWeight: 'bold' }}>{t('CalendarAbsencePopup.Start')}</span> {moment(absence.startDate).format("DD.MM.YYYY HH:mm")}</p>
              <p><span style={{ fontWeight: 'bold' }}>{t('CalendarAbsencePopup.End')}</span> {moment(absence.endDate).format("DD.MM.YYYY HH:mm")}</p>
              <p><span style={{ fontWeight: 'bold' }}>{t('CalendarAbsencePopup.Confirmed')}</span> <span style={{ color: (absence.returnReceiptDate ? 'green' : 'red') }}>{absence.returnReceiptDate == null ?
                t('Absences.ReturnReceipt.NotConfirmed')
                : t('Absences.ReturnReceipt.Confirmed')
                  .replace('{date}', moment(absence.returnReceiptDate).format("DD.MM.YYYY HH:mm"))
                  .replace('{user}', absence.returnReceiptName)}</span>
              </p>
            </div>
          }
          footer={
            <div>
              {!absence.returnReceiptDate && isAdmin && (
                <ButtonWithLoader
                  onClick={() => confirmAbsence(absence)}
                  isLoading={isConfirming}
                >
                  {t('CalendarAbsencePopup.ConfirmNow')}
                </ButtonWithLoader>
              )}
              <Button onClick={() => setAbsence(null)}>{t('Popup.Close')}</Button>
            </div>
          }
        />
      )}
    </div>
  );
}
