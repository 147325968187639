import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../library/common/commonComponents/Buttons/Button';
import Input from '../../../../../../library/common/commonComponents/Inputs/Input';
import Label from '../../../../../../library/common/commonComponents/Label';

import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import ButtonWithLoader from '../../../../../../library/common/commonComponents/Buttons/ButtonWithLoader';
import style from './TablesEditor.module.scss';
import { saveTable } from 'library/api/tableEditor';
import { ActiveTable } from 'active-table-react';

export default function TablesEditor({ editTable, cancelEdit, reloadTables }) {
  const { t } = useTranslation();

  const [table, setTable] = useState(editTable);
  const [errors, setErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingAndClosing, setIsSavingAndClosing] = useState(false);

  const save = close => {
    const curErrors = [];
    if (table.name.length === 0) {
      curErrors.push(t('FormsEditor.EmptyNameError'));
    }
    setErrors(curErrors);

    if (curErrors.length > 0) {
      return;
    }

    if (close) {
      setIsSavingAndClosing(true);
    } else {
      setIsSaving(true);
    }

    const data = {
      ...table,
      templateData: JSON.stringify(tableRef.current.getData()),
      columnsDetails: JSON.stringify(tableRef.current.getColumnsDetails()),
      template: true
    };

    saveTable(data)
      .then(res => {
        let templateData = JSON.parse(res.data.templateData);
        if (typeof templateData === 'string') {
          templateData = JSON.parse(templateData);
        }
        setTable({
          ...editTable,
          ...res.data,
          templateData,
        });
        store.dispatch(showBottomNotification(t('FormsEditor.SaveSuccess'), { isFail: false }));
        reloadTables();
        if (close) {
          cancelEdit();
        }
      })
      .catch(err => {
        store.dispatch(showBottomNotification(t('FormsEditor.SaveError'), { isFail: true }));
      })
      .finally(() => {
        setIsSavingAndClosing(false);
        setIsSaving(false);
      });
  };

  const tableRef = useRef(null);

  const cols =
    table.templateData[0]?.map((_, idx) => {
      const col = (table.columnsDetails && table.columnsDetails[idx]) ?? {};
      return {
        defaultColumnTypeName: col.typeName ?? '',
        cellStyle: col?.width ? { width: col.width } : null,
        headerName: table.templateData[0][idx] ?? '',
      };
    }) ?? [];

  return (
    <div className={style.container}>
      <h1>{t('Administration.TableEditorTitle')}</h1>
      <div className={style.buttonsTopRight}>
        <Button onClick={cancelEdit}>
          <i className='fa fa-arrow-left' /> {t('Administration.TableEditorBackButton')}
        </Button>
        <ButtonWithLoader onClick={() => save(false)} isLoading={isSaving}>
          <i className='fa fa-floppy-o' /> {t('Administration.TableEditorSaveButton')}
        </ButtonWithLoader>
        <ButtonWithLoader onClick={() => save(true)} isLoading={isSavingAndClosing}>
          <i className='fa fa-floppy-o' /> {t('Administration.TableEditorSaveAndCloseButton')}
        </ButtonWithLoader>
      </div>
      <div className={style.formDataContainer}>
        <Label type='input'>{t('Administration.TableEditorNameInputLabel')}</Label>
        <Input
          value={table.name}
          onChange={e =>
            setTable({
              ...table,
              name: e.target.value,
            })
          }
          className={style.formNameInput}
          placeholder={t('Administration.TableEditorNameInputPlaceholder')}
        />
        {errors.length > 0 && (
          <ul>
            {errors.map(e => {
              return <li className={style.errorMsg}>{e}</li>;
            })}
          </ul>
        )}
      </div>

      <div className={style.wrapper}>
        <ActiveTable
          data={editTable.templateData}
          customColumnsSettings={cols}
          headerStyles={{ default: { backgroundColor: '#d6d6d630' } }}
          ref={tableRef}
        />
      </div>

      <div className={style.bottomButtonsWrapper}>
        <ButtonWithLoader
          onClick={() => save(false)}
          isLoading={isSaving}
          className={style.bottomButton}
        >
          <i className='fa fa-floppy-o' /> {t('Administration.TableEditorSaveButton')}
        </ButtonWithLoader>
        <ButtonWithLoader
          onClick={() => save(true)}
          isLoading={isSavingAndClosing}
          className={style.bottomButton}
        >
          <i className='fa fa-floppy-o' /> {t('Administration.TableEditorSaveAndCloseButton')}
        </ButtonWithLoader>
      </div>
    </div>
  );
}
