import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function useComments(allComments, postAuthorId, group) {
  // filter comments whether they are private or not
  const user = useSelector(state => state.userReducer);

  const isPostAuthor = user.id === postAuthorId;
  const isAdmin =
    (group ? group.groupAdminStatus : false) || user.administrationAccess || user.employee;

  const comments = allComments.filter(comment => {
    const isCommentAuthor = comment.userId === user.id;

    if (comment.isPrivate) {
      return isPostAuthor || isAdmin || isCommentAuthor;
    }

    return true;
  });

  const [showAllComments, setShowAllComments] = useState(comments.length < 3);
  if (comments.length < 3 && !showAllComments) {
    setShowAllComments(true);
  }

  const shouldRenderShowAllButton = !showAllComments && comments.length > 2;

  const commentsToDisplay = showAllComments ? comments : getNotAllComments(comments);

  return { setShowAllComments, shouldRenderShowAllButton, commentsToDisplay };
}

export function getNotAllComments(comments) {
  const newComments = comments.filter(comment => comment.isNew);
  const oldCommentsLength = comments.length - newComments.length;

  const oldCommentsToShow =
    oldCommentsLength > 2 ? comments.slice(oldCommentsLength - 2, oldCommentsLength) : [];

  return oldCommentsToShow.concat(newComments);
}
