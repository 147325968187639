import { createUserSignatureListPDF } from './files';

export function removeWhiteBackgroundSignature(oldCanvas) {
  var canvas = oldCanvas;
  var ctx = canvas.getContext('2d');
  var imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  var data = imgData.data;
  for (var i = 0; i < data.length; i += 4) {
    if (data[i + 3] < 255) {
      data[i] = 255;
      data[i + 1] = 255;
      data[i + 2] = 255;
      data[i + 3] = 255;
    }
  }
  ctx.putImageData(imgData, 0, 0);
  return canvas;
}

export async function exportSignatureListPDF(group, kitaName, users, kids, fileId, t) {
  let kidsSignatures = mapKidsToUsers(users, kids);
  const lastIndexOfSignedKidRow = kidsSignatures.findLastIndex(row => row.hasSignature);
  kidsSignatures.splice(lastIndexOfSignedKidRow + 1, 0,
    emptySignatureData,
    emptySignatureData
  );

  await createUserSignatureListPDF(kidsSignatures, kitaName, group, fileId, t);
}

const emptySignatureData = {
  id: '',
  firstName: '',
  lastName: '',
  hasSignature: true,
  signatureDate: '',
  userName: '',
  isEmptyRow: true
}

function mapKidsToUsers(users, kids) {
  const result = [];
  users.forEach((user) => {
    let userKids = kids.filter(kid => {
      return (
        (kid.parent1 && kid.parent1.userKita && kid.parent1.userKita.user.id === user.id) ||
        (kid.parent2 && kid.parent2.userKita && kid.parent2.userKita.user.id === user.id)
      );
    });
    userKids = userKids.sort((a, b) => a.lastName.localeCompare(b.lastName));
    userKids.forEach((kid) => {
      result.push({
        id: kid.id,
        firstName: kid.firstName,
        lastName: kid.lastName,
        hasSignature: user.hasSignature,
        signatureDate: user.signatureDate,
        userName: `${user.firstName} ${user.lastName}`,
      })
    });
  });
  result.sort((a, b) => {
    if (a.hasSignature === b.hasSignature) {
      return a.firstName.localeCompare(b.firstName);
    }
    return a.hasSignature ? -1 : 1;
  });
  return result;
}

function groupSignaturesByFileId(signatures, users, kids) {
  const result = [];

  const kidMap = new Map();
  kids.forEach(kid => {
    if (kid.parent1 && kid.parent1.userKita && kid.parent1.userKita.user) {
      const parentId = kid.parent1.userKita.user.id;
      if (!kidMap.has(parentId)) {
        kidMap.set(parentId, []);
      }
      kidMap.get(parentId).push(kid);
    }
    if (kid.parent2 && kid.parent2.userKita && kid.parent2.userKita.user) {
      const parentId = kid.parent2.userKita.user.id;
      if (!kidMap.has(parentId)) {
        kidMap.set(parentId, []);
      }
      kidMap.get(parentId).push(kid);
    }
  });

  // Group signatures by fileId
  const signatureMap = new Map();
  signatures.forEach(signature => {
    if (!signatureMap.has(signature.fileId)) {
      signatureMap.set(signature.fileId, []);
    }
    signatureMap.get(signature.fileId).push(signature);
  });

  signatureMap.forEach((signatureList, fileId) => {
    const mainSignature = signatureList[0];
    const entry = {
      fileId,
      fileName: mainSignature.fileName,
      fileOriginalPath: mainSignature.fileOriginalPath,
      users: []
    };

    users.forEach(user => {
      // Check if user has signed in this fileId
      const signature = signatureList.find(sig => sig.userId === user.user.id);
      const userKids = kidMap.get(user.user.id) || [];

      // Add user info with signature and kids
      entry.users.push({
        user: user.user,
        signStatus: signature ? 'signed' : 'not-signed',
        kids: userKids,
        signature
      });
    });

    result.push(entry);
  });

  return result;
}
