import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TablesEditor from './TablesFrameFrames/TablesEditor';

import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { getTables } from 'library/api/tableEditor';
import TablesOverview from './TablesFrameFrames/TablesOverview';

function TablesFrame() {
  const { t } = useTranslation();

  const [tables, setTables] = useState([]);

  const defaultTableData = [['Spalte 1'], ['Wert 1']];
  const emptyTable = {
    id: -1,
    name: '',
    creator: -1,
    templateData: defaultTableData,
  };

  const [editTable, setEditTable] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const reloadTables = () => {
    setIsLoading(true);
    getTables()
      .then(res => {
        const data = (res.data ?? []).filter((table) => table.template).map(table => {
          let parsedData = defaultTableData;
          let parsedColumns = [];
          try {
            parsedData = JSON.parse(table.templateData) ?? [];
            if (typeof parsedData === 'string') {
              parsedData = JSON.parse(parsedData);
            }
            parsedColumns = JSON.parse(table.columnsDetails) ?? [];
            if (typeof parsedColumns === 'string') {
              parsedColumns = JSON.parse(parsedColumns);
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('Error parsing table data', e);
          }
          const res = {
            ...table,
            columnsDetails: parsedColumns,
            templateData: parsedData,
          };
          return res;
        });
        setTables(data);
      })
      .catch(err => {
        store.dispatch(showBottomNotification(t('FormsEditor.LoadFormsError'), { isFail: true }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createNewTable = () => {
    setEditTable(emptyTable);
  };

  const startEditTable = f => {
    setEditTable(f);
  };

  useEffect(() => {
    reloadTables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {editTable ? (
        <TablesEditor
          editTable={editTable}
          cancelEdit={() => setEditTable(null)}
          reloadTables={reloadTables}
        />
      ) : (
        <TablesOverview
          createNewTable={createNewTable}
          tables={tables}
          isLoading={isLoading}
          startEditTable={startEditTable}
          reloadTables={reloadTables}
        />
      )}
    </div>
  );
}

export default TablesFrame;
