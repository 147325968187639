import Storage from 'library/utilities/storage';
import axiosInstance from 'library/api/axios';
import { useTranslation } from 'react-i18next';

import config, { getPort } from 'main/config';
import * as at from '../commonConstants/groupConstants';

const URL = `${config.API_BASE_URI}${getPort(8080)}/api`;

const { t } = useTranslation();

export const setCreateGroupMode = flag => dispatch => {
  dispatch({
    type: at.SET_CREATE_GROUP_MODE,
    payload: flag,
  });
};

export const createGroupAPI = data => {
  return axiosInstance.post(at.URLS.groups, data).then(res => {
    switch (res.status) {
      case 200:
        return {
          status: res.status,
          result: true,
          reason: t('CreateGroup.ok'),
          data: res.data,
        };
      case 208:
        return {
          status: res.status,
          result: false,
          reason: 'CreateGroup.Space With Same Name is already present in the Kita',
        };
      default:
        return false;
    }
  });
};

export const inviteUsersAPI = (data, kitaId) => {
  const inviteUrl = kitaId ? `${at.URLS.invite}?newKitaId=${kitaId}` : at.URLS.invite;
  return axiosInstance.post(inviteUrl, data).then(res => {
    switch (res.status) {
      case 200:
        return {
          status: res.status,
          result: true,
          reason: t('CreateGroup.ok'),
          data: res.data,
        };
      case 208:
        return {
          status: res.status,
          result: false,
          reason: 'CreateGroup.Space With Same Name is already present in the Kita',
        };
      default:
        return false;
    }
  });
};

export const inviteUsersEmailAPI = data => {
  return axiosInstance.post(at.URLS.inviteEmail, data).then(res => {
    switch (res.status) {
      case 200:
        return {
          status: res.status,
          result: true,
          reason: t('CreateGroup.ok'),
          data: res.data,
        };
      case 208:
        return {
          status: res.status,
          result: false,
          reason: 'CreateGroup.Space With Same Name is already present in the Kita',
        };
      default:
        return false;
    }
  });
};

export const joinPublicGroupAPI = groupId => {
  axiosInstance.post(at.URLS.joinPublicGroup + '/' + groupId).then(res => {
    if (res.status === 200) {
      window.location.reload();
    }
  });
};

export const requestGroupMembershipAPI = (groupId) =>
  axiosInstance.post(`/membership/request/group/${groupId}`);

export const getAllMembershipRequestOfGroup = (groupId) =>
  axiosInstance.get(`/membership/all-request/group/${groupId}`);

export const getGroupMembershipRequestForUser = (groupId, userId) =>
  axiosInstance.get(`/membership/group/${groupId}/user/${userId}`);

export const approveGroupMembershipRequestForUser = (groupId, userId) =>
  axiosInstance.post(`/membership/approve/group/${groupId}/user/${userId}`);

export const rejectGroupMembershipRequestForUser = (groupId, userId) =>
  axiosInstance.post(`/membership/reject/group/${groupId}/user/${userId}`);

export const approveAllGroupMembershipRequestOfGroup = (groupId, userIds) =>
  axiosInstance.post(`/membership/approve-users/group/${groupId}?${userIds}`);

export const rejectAllGroupMembershipRequestOfGroup = (groupId, userIds) =>
  axiosInstance.post(`/membership/reject-users/group/${groupId}?${userIds}`);

export const getJoinPolicies = data => {
  return axiosInstance.post(at.URLS.joinPolicies, { data }).then(res => {
    switch (res.status) {
      case 200:
        return res.data;
      default:
        return false;
    }
  });
};

export const getGroupUsersByName = ({ groupId, name }) => {
  return axiosInstance
    .get(`${URL}/user/search/${name}`, { params: { groupId, groupInvite: true } })
    .then(res => {
      switch (res.status) {
        case 200:
          return res.data;
        default:
          return false;
      }
    });
};

export const getModules = () => {
  return axiosInstance.get(at.URLS.modules).then(res => {
    switch (res.status) {
      case 200:
        return res.data;
      default:
        return false;
    }
  });
};

export const setGroupData = data => dispatch => {
  dispatch({
    type: at.SET_GROUP_DATA,
    payload: data,
  });
};

export const clearGroupData = () => dispatch => {
  dispatch({
    type: at.CLEAR_GROUP_DATA,
  });
};

export const getGroupMembers = (id, filters, sortBy, currentPage, size) => {
  const filter = filters.length
    ? filters.reduce(
        (str, filterItem) => {
          return (str = filterItem.filterValue
            ? filterItem.filterName === 'search'
              ? str.concat(['and', [`searchKeyword:cic:${filterItem.filterValue}`]])
              : str.concat(['and', `role.id:eq:${filterItem.filterValue}`])
            : str);
        },
        [`group.id:eq:${id}`, 'and', 'kitaAdminStatus:eq:false', 'and', 'deleted:eq:false'],
      )
    : [`group.id:eq:${id}`, 'and', 'kitaAdminStatus:eq:false', 'and', 'deleted:eq:false'];

  const sort = sortBy
    ? sortBy.order
      ? [`${sortBy.sortBy}`, 'asc']
      : [`${sortBy.sortBy}`, 'desc']
    : [];

  const data = {
    filter,
    sort,
    page: currentPage || 1,
    size: size || 50,
    graphql:
      // eslint-disable-next-line
      'user[id,superAdmin,userName,firstName,lastName,logoUrl,profileLogoXCoordinate,profileLogoYCoordinate,profileLogoWidth,profileLogoAspect],createdAt,groupRole[role[adminRole]],lastVisit',
  };
  const locale = Storage.getItem('locale') || 'de';
  return axiosInstance.post(`group/${id}/users`, data, {
    headers: { 'Accept-Language': `${locale}` },
  });
};

export const getGroupMembersWithoutFilterSorting = id => {

  const data = {
    filter: [],
    sort: [],
    page: 1,
    size: 99999,
    graphql:
      // eslint-disable-next-line
      'user[id,superAdmin,userName,firstName,lastName,logoUrl,profileLogoXCoordinate,profileLogoYCoordinate,profileLogoWidth,profileLogoAspect,blockUsersFromMessaging],createdAt,groupRole[role[adminRole]],lastVisit',
  };
  const locale = Storage.getItem('locale') || 'de';
  return axiosInstance.post(`group/${id}/users`, data, {
    headers: { 'Accept-Language': `${locale}` },
  });
};

export const deleteGroupMember = (groupId, userIds) => {
  return axiosInstance.post(`/userTransfer/remove/${groupId}?${userIds}`).catch(err => err);
};

export const updateGroupMemberRole = (groupId, userId, roleId) => {
  return axiosInstance.put(`group/${groupId}/user/${userId}/role/${roleId}`).catch(err => err);
};

export const getGroupsForAdmin = () => {
  return axiosInstance.get(at.URLS.transferGroups).catch(err => err);
};

export const transferGroupMembers = (userIds, sourceGroupId, destinationGroupId, config) => {
  return axiosInstance
    .post(
      `${at.URLS.transferGroupMembers}${sourceGroupId}/${destinationGroupId}?${userIds}`,
      config,
    )
    .catch(err => err);
};

export const copyGroupMembers = (userIds, sourceGroupId, destinationGroupId, config) => {
  return axiosInstance
    .post(`${at.URLS.copyGroupMembers}${sourceGroupId}/${destinationGroupId}?${userIds}`, config)
    .catch(err => err);
};

export default setCreateGroupMode;
