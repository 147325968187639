import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { isAwoWW } from 'library/api/tenantConfig';
import { CheckedIcon } from 'library/common/commonComponents/Icons';
import Loader from 'library/common/commonComponents/Loader';
import checkedDisabled from 'resources/images/checkbox/checked-gray.svg';
import empty from 'resources/images/checkbox/empty-square.svg';

import styles from './checkbox.module.scss';

export default function Checkbox({
  className = '',
  name = '',
  isChecked = false,
  isDisabled = false,
  onChange,
  isLoading,
  checkboxTextClass,
}) {
  const activeKita = useSelector(state => state.kitaReducer.activeKita);

  const renderImage = () => {
    if (isLoading) {
      return <Loader dotColor='#777' dotSize='3px' className={styles.submitting} />;
    } else if (isDisabled && isChecked) {
      return <img src={checkedDisabled} className={cn(styles.checkbox)} alt='checked-disabled' />;
    } else if (isDisabled) {
      return (
        <img
          src={empty}
          className={cn(styles.checkbox, isDisabled && styles.disabled)}
          alt='unchecked-disabled'
        />
      );
    } else if (isChecked) {
      return (
        <i
          className={cn(
            styles.checkbox,
            isAwoWW() && !activeKita.kitaColorCode && styles.checkboxAWOColor,
            !isAwoWW() && styles.checkboxColor,
          )}
          {...isAwoWW() &&
            activeKita.kitaColorCode && {
              style: { color: activeKita.kitaColorCode },
            }}
        >
          <CheckedIcon />
        </i>
      );
    }
    return (
      <img
        src={empty}
        className={cn(styles.checkbox, isDisabled && styles.disabled)}
        alt='unchecked'
      />
    );
  };

  return (
    <div className={cn(styles.container, className)}>
      <span
        onClick={!isDisabled ? onChange : null}
        className={cn(isLoading && styles.loadingContainer)}
        style={{ display: 'flex' }}
      >
        {renderImage()}
        {name && <span className={cn(styles.text, checkboxTextClass)}>{name}</span>}
      </span>
    </div>
  );
}

Checkbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
