import axios from 'library/api/axios';

import Storage from 'library/utilities/storage';
import config, { getPort } from 'main/config';

const zuulURL = `${config.API_BASE_URI}/zuul${getPort(8091)}/api`;

export const getUserKitas = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/user/current/kitas`, {
    graphql:
      // eslint-disable-next-line
      'id,kitaName,description,deleted,streetName,kitaType[id,kitaTypeTranslation[id,kitaTypeName]],createdAt,colorCode,streetNo,street,city,zipCode,kitaChiefName,kitaCode,email,phoneNo,street,country,houseNo,dataProtectionOfficerName,dataProtectionOfficerCompany,dataProtectionOfficerCity,dataProtectionOfficerStreet,dataProtectionOfficerZip,dataProtectionOfficerPhone,dataProtectionOfficerEmail,dataProcessingName,dataProcessingCompany,dataProcessingCity,dataProcessingStreet,dataProcessingZip,dataProcessingPhone,dataProcessingEmail,kitaTypeId,kitaLogoXCoordinate,kitaLogoYCoordinate,kitaLogoWidth,logoUrl,trager,religionId',
  });

export const setUserKita = kitaId =>
  axios.put(`${config.API_BASE_URI}${getPort(8080)}/api/kita/${kitaId}/user`);

export const getKitasWhereUserIsAdmin = kitaId =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kita/getKitasWhereUserIsAdmin`);

export const editUserKitaColor = ({ kitaId, colorCode }) =>
  axios.put(`${config.API_BASE_URI}${getPort(8080)}/api/kita/${kitaId}/user/current`, {
    colorCode,
  });

export const toggleKitaFavorite = ({ kitaId, isFavorite }) =>
  axios.put(`${config.API_BASE_URI}${getPort(8080)}/api/kita/${kitaId}/user/current`, {
    isFavorite,
  });
export const uploadUserKitaImage = ({ kitaId, image, setProgress }) => {
  const formData = new FormData();
  formData.append('image', image);
  formData.append('imageAspect', 1);
  formData.append('imageWidth', 30);
  formData.append('xCoordinate', 25);
  formData.append('yCoordinate', 25);
  return axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/kita/${kitaId}/user/current`,
    formData,
    {
      onUploadProgress: progressEvent => {
        setProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total) || 0.01);
      },
    },
  );
};

export const editUserKitaImage = ({ kitaId, ...payload }) => {
  return axios.put(
    `${config.API_BASE_URI}${getPort(8080)}/api/kita/${kitaId}/user/current`,
    payload,
  );
};

export const deleteUserKitaImage = kitaId =>
  axios.put(`${config.API_BASE_URI}${getPort(8080)}/api/kita/${kitaId}/user/current`, {
    logoDeletionStatus: true,
  });

export const addNewKita = kitaCode =>
  axios.put(`${config.API_BASE_URI}${getPort(8080)}/api/kita/user/current?kitaCode=${kitaCode}`);

export const createKitaCode = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kita/kitaCode`);

export const getKitaReligion = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kita/checkifkitahassetreligion`);

export const setKitaReligion = religionId =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kita/setkitareligion/${religionId}`);

export const fixBrokenKitaRolesPermissions = () =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kita/fixbrokenkitarolespermissions`);

export const fixBrokenTraegerDatabaseEntries = () =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kita/fixbrokentraegerdatabaseentries`);

export const fixBrokenSafe2ConnectDatabaseEntries = () =>
  axios.post('/kita/fixbrokensafetoselectdatabaseentries');

export const createKita = payload => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    formData.append(key, payload[key]);
  });
  return axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kita`, formData);
};

export const updateKita = payload => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    formData.append(key, payload[key]);
  });
  return axios.put(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/${payload.kitaId}`, formData);
};

export const getKitaTypes = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kitaType`, {
    headers: {
      'Accept-Language': Storage.getItem('locale'),
    },
  });

export const getAwoVirtualKitas = () => axios.get('/awo-kitas');

export const getAWOConnectionByKita = kitaId => axios.get(`/awo-connection/by-kita/${kitaId}`);

export const getAwoKitaConnection = awoKitaId => axios.get(`/awo-connection/${awoKitaId}`);

export const setAwoKitaConnection = (kitaId, awoKitaId, connectionActivated) =>
  axios.put(`/awo-connection/${kitaId}`, {
    awoKitaId,
    connectionActivated,
  });

export const getAllKitas = ({ page, sort, searchValue, deactivated }) =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/kitas?deactivated=${deactivated}${searchValue &&
      '&searchKeyword=' + searchValue}`,
    {
      graphql:
        // eslint-disable-next-line
        'id,kitaName,description,deleted,streetName,kitaTypeId,kitaType[id],providerId,kitaProviderStatus,providerName,createdAt,colorCode,streetNo,street,city,zipCode,kitaChiefName,kitaCode,email,phoneNo,street,country,houseNo,dataProtectionOfficerName,dataProtectionOfficerCompany,dataProtectionOfficerCity,dataProtectionOfficerStreet,dataProtectionOfficerZip,dataProtectionOfficerPhone,dataProtectionOfficerEmail,dataProcessingName,dataProcessingCompany,dataProcessingCity,dataProcessingStreet,dataProcessingZip,dataProcessingPhone,dataProcessingEmail,kitaTypeId,kitaLogoXCoordinate,kitaLogoYCoordinate,kitaLogoWidth,logoUrl,trager,religionId',
      page,
      size: 50,
      sort: [sort.name, sort.direction],
    },
  );

export const getAllKitasWithoutPagination = () =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kitas?deactivated=false`, {
    graphql:
      // eslint-disable-next-line
      'id,kitaName,streetName,streetNo,street,city,zipCode,country,houseNo',
    page: 1,
    size: 1000,
    sort: ['createdAt', 'asc'],
  });

export const getAllKitasWithAllData = () =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kitas?deactivated=false`, {
    graphql:
      // eslint-disable-next-line
      'id,kitaName,description,deleted,streetName,kitaTypeId,kitaType[id],providerId,kitaProviderStatus,providerName,createdAt,colorCode,streetNo,street,city,zipCode,kitaChiefName,kitaCode,email,phoneNo,street,country,houseNo,dataProtectionOfficerName,dataProtectionOfficerCompany,dataProtectionOfficerCity,dataProtectionOfficerStreet,dataProtectionOfficerZip,dataProtectionOfficerPhone,dataProtectionOfficerEmail,dataProcessingName,dataProcessingCompany,dataProcessingCity,dataProcessingStreet,dataProcessingZip,dataProcessingPhone,dataProcessingEmail,kitaTypeId,kitaLogoXCoordinate,kitaLogoYCoordinate,kitaLogoWidth,logoUrl,trager,religionId',
    page: 1,
    size: 1000,
    sort: ['createdAt', 'asc'],
  });

export const deactivateKita = kitaId =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/deactivate`);

export const downloadExcel = (fileType, deactivated) =>
  axios.post(
    `${config.API_BASE_URI}${getPort(
      8080,
    )}/api/kitas/export?fileType=${fileType}&deactivated=${deactivated}`,
    {
      filter: [],
      graphql:
        'id,kitaName,description,deleted,streetName,kitaType[id,kitaTypeTranslation[id,kitaTypeName]]',
      page: 1,
      size: 50,
      sort: ['createdAt', 'asc'],
    },
    {
      headers: {
        'Accept-Language': Storage.getItem('locale'),
      },
      responseType: 'blob',
    },
  );

export const downloadSearchPattern = body =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/download-search-pattern`, body, {
    headers: {
      'Accept-Language': Storage.getItem('locale'),
    },
    responseType: 'blob',
  });

export const uploadSearchPattern = file =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/upload-search-pattern`, file);

export const getSearchPattern = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/search-pattern`);

export const createDPP = payload => {
  return axios.post(zuulURL + '/dataprotectionpolicy?graphql=text,setting', payload);
};

export const getDPP = () => axios.get(zuulURL + '/dataprotectionpolicy?graphql=text,setting');

export const createImprint = payload => {
  return axios.post(zuulURL + '/imprint?graphql=text,setting', payload);
};
export const bulkEditAdminRoleForKitaUsers = (kitaId, userIds) =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/kita/${kitaId}/user-admin-role/bulk-update`,
    userIds,
  );

export const bulkEditEmployeeRoleForKitaUsers = (kitaId, userIds) =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/kita/${kitaId}/user-employee-role/bulk-update`,
    userIds,
  );

export const bulkEditUserRoleForKitaUsers = (kitaId, userIds) =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/kita/${kitaId}/user-normal-role/bulk-update`,
    userIds,
  );

export const deleteKitaKids = (kitaId, kidIds) =>
  axios.post(`/kita/${kitaId}/kids/bulk-delete`, kidIds);

export const transferKitaKidsToGroup = (kitaId, groupId, kidIds) =>
  axios.post(`/kita/${kitaId}/kids/bulk-transfer/group/${groupId}`, kidIds);

export const getImprint = () => axios.get(zuulURL + '/imprint?graphql=text,setting');

export const approveKita = (providerId, kitaId) =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kita/approve/${providerId}/${kitaId}`);

export const rejectKita = (providerId, kitaId) =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kita/reject/${providerId}/${kitaId}`);

export const getEmployeeIds = kitaId =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/getEmployeeIds`);

export const getKitaHolidays = kitaId =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/holidays`);

export const setKitaHolidays = (kitaId, holidays) =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/holidays`, holidays);

export const getDefaultVerificationImages = () => axios.get(`/kita/verificationImages/default`);

export const getKitaVerificationImages = kitaId => axios.get(`/kita/${kitaId}/verificationImages`);

export const getKitaHolidayCares = kitaId => axios.get(`/kitas/${kitaId}/holidaycares`);

export const getAdminLogsForCurrentKita = () => axios.get(`/kitas/current/adminlogs`);

export const updateKitaHolidayCares = ({ kitaId, kitaHolidayCares }) =>
  axios.post(`/kitas/${kitaId}/holidaycares`, kitaHolidayCares);

export const getKitaOpeningHours = kitaId => axios.get(`/kitas/get-opening-hours/${kitaId}`);

export const saveKitaOpeningHours = payload => axios.post(`/kitas/save-opening-hours`, payload);

export const getConfig = () => axios.get(`/kitas/get-config/`);

export const editConfig = config => axios.post(`/kitas/edit-config`, config);

export const editFormConfig = config => axios.post(`/kitas/changeFormConfiguration/${config}`);

export const getFormConfig = () => axios.get(`/kitas/getFormConfiguration/`);

export const getScimGlobalConfig = () => axios.get(`/kitas/global/scim-config`);

export const isScimEnabled = () => axios.get(`/kitas/global/is-scim-enabled`);

export const editScimlobalConfig = payload => axios.put(`/kitas/global/scim-config`, payload);

export const updateMsClientId = (clientId, userIds) =>
  axios.post(`/kitas/global/update-ms-client_id`, {
    clientId,
    userIds,
  });

export const getScimConfigPublic = () => axios.get(`/kitas/public/scim-config`);

export const getEnableKitaConfig = () => axios.get(`/kitas/enable-create-kita-config`);

export const updateEnableCreateKitaConfig = active =>
  axios.put(`/kitas/enable-create-kita-config`, { active });

export const getArticleConfig = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/getArticleConfig`);

export const saveArticle = (article, kitaId) =>
  axios.post(`${config.API_BASE_URI}${getPort(8091)}/api/saveArticle/${kitaId}`, article);

/**
 * Returns Excel file with all employees over all kitas and their last login
 */
export const downloadEmployeeLastLoginExcel = () =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/kitas/employees/export`,
    {
      filter: [
        'registrationStatus:eq:registered',
        'and',
        'deleted:eq:false',
        'and',
        'deactivated:eq:false',
      ],
    },
    {
      headers: {
        'Accept-Language': Storage.getItem('locale'),
      },
      responseType: 'blob',
    },
  );
