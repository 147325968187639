import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import axios from 'library/api/axios';
import config, { getPort } from 'main/config';
import { store } from 'main/store/configureStore';

// import Storage from 'library/utilities/storage';
import { writeTranslationLine } from 'library/common/commonActions/translationsActions';

import styles from './withWatsonTranslate.module.scss';

export default function WithWatsonTranslate({ Component, data, doNotTranslate = false, display }) {
  const currentWatsonLang = useSelector(state => state.userReducer.currentWatsonLang);
  // const doNotTranslate = false; // currentWatsonLang === 'de' && Storage.getItem('locale') === 'de';

  const { text, entityId, entityType, forced } = data;
  const [translated, setTranslated] = useState(text);
  const [loading, setLoading] = useState(true);

  const getText = (txt, textIndex, lang) => {
    const textString =
      entityId.toString() +
      entityType +
      lang +
      txt +
      `${txt.substring(0, 5)}_${txt.length}_${textIndex}`;
    const payload = {
      entityId,
      entityType,
      target: lang,
      text: txt,
      textIndex: `${txt.substring(0, 5)}_${txt.length}_${textIndex}`,
    };
    if (forced) payload.forced = true;

    const state = store.getState();

    return new Promise(resolve => {
      if (state.translationsReducer[textString]) {
        resolve({ result: state.translationsReducer[textString] });
      } else {
        axios
          .post(`${config.API_BASE_URI}${getPort(8091)}/api/dynamic/translation`, payload)
          .then(res => {
            resolve({
              result: `${res.data} `,
              textString,
              request: true,
            });
          });
      }
    });
  };

  useEffect(() => {
    if (currentWatsonLang && entityId && !doNotTranslate) {
      const parent = document.createElement('div');

      parent.innerHTML = text;

      const translationEngine = () =>
        new Promise(solve => {
          let textsCount = 0;
          let ready = 0;

          const countPlus = () => {
            ready++;

            if (textsCount === ready) {
              setTranslated(parent);
              solve();
            }
          };

          const textGetter = (nodeText, node) => {
            getText(nodeText, textsCount.toString(), currentWatsonLang).then(transText => {
              if (transText.request) {
                store.dispatch(writeTranslationLine(transText.textString, transText.result));
              }
              node.textContent = transText.result;
              countPlus();
            });
          };

          const recursiveTextNodeFinder = node => {
            const nodeText = node.textContent.trim();

            if (node.nodeType === 3) {
              if (nodeText.length > 0) {
                textsCount++;
                textGetter(node.textContent, node);
              }
            }

            if (node.childNodes.length > 0) {
              for (const nodeChildren of node.childNodes) {
                if (nodeChildren.nodeType === 3) {
                  const nodeChildrenText = nodeChildren.textContent.trim();
                  if (nodeChildrenText.length > 0) {
                    textsCount++;
                    textGetter(nodeChildren.textContent, nodeChildren);
                  }
                }
                if (nodeChildren.childNodes.length > 0) {
                  recursiveTextNodeFinder(nodeChildren);
                }
              }
            }
          };

          recursiveTextNodeFinder(parent);

          if (textsCount === 0) {
            setLoading(false);
          }
        });

      translationEngine().then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [text, currentWatsonLang]);

  return (
    <div
      className={styles.wrapper}
      style={{ flexGrow: loading ? 1 : 0, display: display ? display : 'inline' }}
    >
      {loading ? (
        <div className={styles.textPreload} />
      ) : (
        <Component html={!doNotTranslate && currentWatsonLang ? translated : text} />
      )}
    </div>
  );
}
