import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { sanitize } from 'dompurify';
import { useTranslation } from 'react-i18next';

import { getCanBePublicStatus } from 'library/utilities/posts';
import UsersListPopup from 'library/common/commonComponents/Popups/UsersListPopup';
import CalendarKidParticipantsPopup from 'library/common/commonComponents/Popups/CalendarKidParticipantsPopup';
import Input from 'library/common/commonComponents/Inputs/Input';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import FilePrewiev from 'library/common/commonComponents/FilePrewiev';
import CalendarCreateEditEvent from 'library/common/commonComponents/Calendar/CalendarFrames/CalendarCreateEditEvent';
import KidParticipationView from './KidParticipationView';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
import { isAwoWW } from 'library/api/tenantConfig';
import {
  getAcceptanceToEvent,
  setAcceptanceToEvent,
  updateCalendarEvent,
  getCalendarSettings,
  getGeoCoordinates,
} from 'library/api/calendar';
import { getDateRange } from 'library/utilities/date';

import styles from './feedItemEvent.module.scss';
import 'leaflet/dist/leaflet.css';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { Icon } from 'leaflet';

export default function FeedItemEvent({
  start,
  end,
  title,
  description,
  color,
  eventId,
  userParticipations,
  setUserParticipations,
  acceptanceCount,
  declineCount,
  maybeCount,
  acceptance,
  maxNumberOfParticipants,
  calendarKidParticipations,
  participationMode,
  participationStateMaybeAllowed,
  participationStateDeclineAllowed,
  canceled,
  files,
  inCalendarModule,
  isEditing,
  setIsEditing,
  participantInfo,
  location,
  streetMap,
  isPublic,
  eventType,
  allDay,
  group,
  onEventEdit,
  updateFeedById,
  isAuthoredByCurrentUser,
  user,
  isTranslationAllowed,
  watsonLanguage,
}) {
  const { t } = useTranslation();
  const shouldShowMap = streetMap === true || streetMap === null;
  const canBePublic = getCanBePublicStatus(group);
  const [isUsersPopupOpened, setIsUsersPopupOpened] = useState(false);
  const [lastAcceptance, setLastAcceptance] = useState(acceptance);
  const [manualAttendCounts, setManualAttendCounts] = useState(1);
  const [usersPopupData, setUsersPopupData] = useState([]);
  const [kidParticipationPopupOpen, setKidParticipationPopupOpen] = useState(false);
  const [usersPopupTitle, setUsersPopupTitle] = useState('');
  const [isOpened, setIsOpened] = useState(false);
  const [calendarSettings, setCalendarSettings] = useState({});
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [geoCoordinates, setGeoCoordinates] = useState([]);
  const [isMapExpanded, setIsMapExpanded] = useState(false);

  const handleSetAcceptanceToEvent = async (acceptanceType) => {
    if (isSubmiting) {
      return;
    }
    setIsSubmiting(acceptanceType);
    try {
      if (acceptanceType !== lastAcceptance) {
        const res = await setAcceptanceToEvent(eventId, acceptanceType, manualAttendCounts);
        if (res.status === 200) {
          const currentUserParticipation = userParticipations.find(up => up.userId === user.id);
          if (!!currentUserParticipation) {
            const updatedUserParticipation = {
              ...currentUserParticipation,
              acceptance: acceptanceType,
              manualAttendCount: null,
            };
            if (acceptanceType === 'ACCEPT' && manualAttendCounts > 1) {
              updatedUserParticipation.manualAttendCount = manualAttendCounts;
            } else if (acceptanceType === 'DECLINE' && currentUserParticipation?.manualAttendCount > 1) {
              if (currentUserParticipation?.manualAttendCount > manualAttendCounts) {
                updatedUserParticipation.manualAttendCount = currentUserParticipation?.manualAttendCount - manualAttendCounts;
              }
            }
            setUserParticipations(prevState => prevState.map(up => up.userId === user.id
              ? updatedUserParticipation
              : up
            ));
          } else {
            let newDataArray = userParticipations.slice();
            newDataArray.push({
              acceptance: acceptanceType,
              userId: user.id,
              manualAttendCount: acceptanceType === 'ACCEPT' && manualAttendCounts > 1 ? manualAttendCounts : null,
            });
            setUserParticipations(newDataArray);
          }
          setLastAcceptance(acceptanceType);
        }
      } else {
        const res = await setAcceptanceToEvent(eventId, '', manualAttendCounts);
        if (res.status === 200) {
          setUserParticipations(prevState => prevState.filter(up => up.userId !== user.id));
          setLastAcceptance(null);
        }
      }
      setIsSubmiting(false);
    } catch (err) {
      console.log(err);
      setIsSubmiting(false);
    }
  };

  const handleAttendCountsChange = async (acceptanceType, newCount) => {
    setManualAttendCounts(parseInt(newCount, 10));
  };

  const handleEditing = async () => {
    const result = await getCalendarSettings(
      group && group.groupId,
      group ? 'groupCalendar' : 'userProfileCalendar',
    );
    setCalendarSettings(result.data);
    setIsOpened(true);
  };

  useEffect(() => {
    if (isEditing) {
      if (inCalendarModule) {
        onEventEdit();
      } else {
        handleEditing();
      }
    }
    // eslint-disable-next-line
  }, [isEditing]);

  useEffect(() => {
    if (location && location !== null && location !== '') {
      fetch(`https://nominatim.openstreetmap.org/search?q=${location}&format=geojson&limit=1`)
        .then(response => response.json())
        .then(data => {
          if (data && data.features && data.features.length === 1) {
            setGeoCoordinates(data.features[0].geometry.coordinates.reverse());
          }
        });
    }
  }, [location]);

  const toggleMapResize = () => {
    setIsMapExpanded(!isMapExpanded);
    if (location && location !== '') {
      fetch(`https://nominatim.openstreetmap.org/search?q=${location}&format=geojson&limit=1`)
        .then(response => response.json())
        .then(data => {
          if (data && data.features && data.features.length === 1) {
            setGeoCoordinates(data.features[0].geometry.coordinates.reverse());
          }
        });
    }
  }

  const openPopup = type =>
    openPopupHandler({
      setUsersPopupData,
      setIsUsersPopupOpened,
      setUsersPopupTitle,
      type,
      t,
      eventId,
    });

  const handleSubmit = async updatedEvent => {
    try {
      await updateCalendarEvent(eventId, updatedEvent);
      updateFeedById(
        eventId,
        {
          ...updatedEvent,
          text: updatedEvent.title,
          eventType: calendarSettings.eventTypes.find(item => item.id === updatedEvent.eventType),
          participationMode: updatedEvent.participationInfo.participationEnum,
          maxNumberOfParticipants: updatedEvent.participationInfo.maxNumberOfParticipants,
          participationStateDeclineAllowed: updatedEvent.participationInfo.isAllowDecline,
          participationStateMaybeAllowed: updatedEvent.participationInfo.isAllowMaybe,
          participantInfo: updatedEvent.participationInfo.info,
        },
        { withoutRequest: true },
      );
      setIsEditing(false);
      setIsOpened(false);
    } catch (err) {
      console.log(err);
    }
  };

  const ResizeMap = ({ geoCoordinates, isMapExpanded }) => {
    const map = useMap();

    useEffect(() => {
      if (map) {
        map.invalidateSize();
        map.setView(geoCoordinates, map.getZoom());
      }
    }, [isMapExpanded, geoCoordinates, map]);

    return null;
  };

  return (
    <div>
      {kidParticipationPopupOpen && (
        <CalendarKidParticipantsPopup
          isOpened={kidParticipationPopupOpen}
          closePopup={() => setKidParticipationPopupOpen(false)}
          eventId={eventId}
        />
      )}
      <UsersListPopup
        isOpened={isUsersPopupOpened}
        title={usersPopupTitle}
        closePopup={() => setIsUsersPopupOpened(false)}
        users={usersPopupData}
      />
      <div className={styles.eventInfo} style={{ borderColor: `${color}` }}>
        <div className={styles.eventInfoContainer}>
          <i className={cn('fa fa-calendar', styles.eventInfoIcon)} />
          <div>
            <div className={styles.eventInfoTitle}>
              {isTranslationAllowed && watsonLanguage !== user.currentWatsonLang ? (
                <WithWatsonTranslate
                  data={{ text: title, entityId: eventId, entityType: 'event' }}
                  Component={({ html }) => (
                    <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
                  )}
                />
              ) : (
                title
              )}
            </div>
            <div className={styles.eventInfoDate}>
              {getDateRange(start, end, allDay, user && user.langCode)}
            </div>
          </div>
        </div>
        {description &&
          (isTranslationAllowed && watsonLanguage !== user.currentWatsonLang ? (
            <WithWatsonTranslate
              data={{ text: description, entityId: eventId, entityType: 'event' }}
              Component={({ html }) => (
                <div
                  className={styles.eventInfoDescription}
                  dangerouslySetInnerHTML={{ __html: sanitize(html) }}
                />
              )}
            />
          ) : (
            <div
              className={styles.eventInfoDescription}
              dangerouslySetInnerHTML={{ __html: sanitize(description) }}
            />
          ))}
        {location && (
          <div className={styles.eventInfoLocation}>
            <i className='fa fa-map-marker' />
            <span>{location}</span>
          </div>
        )}
        {geoCoordinates.length > 0 && shouldShowMap && isAwoWW() && (
          <div
            className={cn(styles.mapContainer, isMapExpanded? styles.extendedMapContainer : styles.smMapContainer)}
          >
            <div
              className={styles.mapResizeButton}
              onClick={toggleMapResize}
            >
              <i className="fa fa-expand" />
            </div>
            <MapContainer
              center={geoCoordinates}
              zoom={13}
              scrollWheelZoom={false}
              style={{ height: '100%', zIndex: 5 }}
            >
              <ResizeMap geoCoordinates={geoCoordinates} isMapExpanded={isMapExpanded} />
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              />
              <Marker
                position={geoCoordinates}
                icon={
                  new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })
                }
              />
            </MapContainer>
          </div>
        )}
      </div>
      {participationMode === 'EVERYBODY_CAN_PARTICIPATE' && !canceled && (
        <div className={styles.participants}>
          <div className={styles.participantsTitle}>
            <i className={cn('fa fa-users', styles.participantsTitleIcon)} />{' '}
            {t('FeedItemEvent.Participants')}
          </div>

          <div className={styles.participantsCount}>
            <span
              className={cn({
                [styles.participantsCountClickable]: acceptanceCount > 0 && isAuthoredByCurrentUser,
              })}
              onClick={() => {
                if (acceptanceCount > 0 && isAuthoredByCurrentUser) {
                  openPopup('ACCEPT');
                }
              }}
            >
              {acceptanceCount} {t('FeedItemEvent.attending')}
            </span>
            {participationStateMaybeAllowed && (
              <>
                {' · '}
                <span
                  className={cn({
                    [styles.participantsCountClickable]: maybeCount > 0 && isAuthoredByCurrentUser,
                  })}
                  onClick={() => {
                    if (maybeCount > 0 && isAuthoredByCurrentUser) {
                      openPopup('MAYBE');
                    }
                  }}
                >
                  {maybeCount} {t('FeedItemEvent.maybe')}
                </span>
              </>
            )}
            {participationStateDeclineAllowed && (
              <>
                {' · '}
                <span
                  className={cn({
                    [styles.participantsCountClickable]:
                      declineCount > 0 && isAuthoredByCurrentUser,
                  })}
                  onClick={() => {
                    if (declineCount > 0 && isAuthoredByCurrentUser) {
                      openPopup('DECLINE');
                    }
                  }}
                >
                  {declineCount} {t('FeedItemEvent.declined')}
                </span>
              </>
            )}
          </div>

          {(!maxNumberOfParticipants ||
            acceptanceCount < maxNumberOfParticipants ||
            lastAcceptance === 'ACCEPT') && (
            <div className={styles.participantsButtons}>
              <ButtonWithLoader
                size='sm'
                type='default'
                className={styles.participantsButton}
                onClick={() => handleSetAcceptanceToEvent('ACCEPT')}
                isLoading={isSubmiting === 'ACCEPT'}
              >
                {lastAcceptance === 'ACCEPT' && (
                  <i className={cn('fa fa-check', styles.participantsButtonCheckIcon)} />
                )}
                <span>{t('FeedItemEvent.Attend')}</span>
              </ButtonWithLoader>
              {participationStateMaybeAllowed && (
                <ButtonWithLoader
                  size='sm'
                  type='default'
                  className={styles.participantsButton}
                  onClick={() => handleSetAcceptanceToEvent('MAYBE')}
                  isLoading={isSubmiting === 'MAYBE'}
                >
                  {lastAcceptance === 'MAYBE' && (
                    <i className={cn('fa fa-check', styles.participantsButtonCheckIcon)} />
                  )}
                  <span>{t('FeedItemEvent.Maybe')}</span>
                </ButtonWithLoader>
              )}
              {participationStateDeclineAllowed && (
                <ButtonWithLoader
                  size='sm'
                  type='default'
                  className={styles.participantsButton}
                  onClick={() => handleSetAcceptanceToEvent('DECLINE')}
                  isLoading={isSubmiting === 'DECLINE'}
                >
                  {lastAcceptance === 'DECLINE' && (
                    <i className={cn('fa fa-check', styles.participantsButtonCheckIcon)} />
                  )}
                  <span>{t('FeedItemEvent.Decline')}</span>
                </ButtonWithLoader>
              )}
              <Input
                className={styles.attendCountInput}
                type='number'
                min='1'
                step='1'
                value={manualAttendCounts ?? 1}
                onChange={e => handleAttendCountsChange('ACCEPT', e.target.value)}
              />
              <span className={styles.attendCountLabel}>({t('FeedItemEvent.AttendCount')})</span>
            </div>
          )}
        </div>
      )}

      {participationMode === 'KIDS_CAN_PARTICIPATE' && !canceled && (
        <KidParticipationView
          user={user}
          eventId={eventId}
          calendarKidParticipations={calendarKidParticipations || []}
          t={t}
        />
      )}
      <FilePrewiev newFiles={files || []} onDelete={false} download />
      {!inCalendarModule && isEditing && (
        <CalendarCreateEditEvent
          isOpened={isOpened}
          onClose={() => {
            setIsEditing(false);
            setIsOpened(false);
          }}
          eventData={{
            title,
            color,
            eventType,
            description,
            location,
            isPublic,
            allDay,
            start,
            end,
            files,
            participationInfo: {
              participationEnum: participationMode,
              maxNumberOfParticipants,
              isAllowDecline: participationStateDeclineAllowed,
              isAllowMaybe: participationStateMaybeAllowed,
              info: participantInfo,
            },
          }}
          calendarSettings={calendarSettings}
          onSubmit={updatedEvent => handleSubmit(updatedEvent)}
          canBePublic={canBePublic}
          isEditing
        />
      )}
    </div>
  );
}

export async function openPopupHandler({
  setUsersPopupData,
  setIsUsersPopupOpened,
  setUsersPopupTitle,
  type,
  t,
  eventId,
}) {
  try {
    const users = await getAcceptanceToEvent(eventId, type);
    switch (type) {
      case 'ACCEPT': {
        setUsersPopupTitle(t('FeedItemEvent.Attending users'));
        break;
      }
      case 'MAYBE': {
        setUsersPopupTitle(t('FeedItemEvent.Maybe attending users'));
        break;
      }
      default: {
        setUsersPopupTitle(t('FeedItemEvent.Declining users'));
      }
    }
    setUsersPopupData(
      users.data.map(item => ({ ...item, name: `${item.firstName} ${item.lastName}` })),
    );
    setIsUsersPopupOpened(true);
  } catch (err) {
    console.log(err);
  }
}
