export const germanHolidays = [
  {
    id: '20220417_hp218hh15bjikmha2a2e6q1b2k',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description:
      'Feiertag in Baden-Württemberg, Bayern, Berlin, Brandenburg, Bremen, Hamburg, Hessen, Mecklenburg-Vorpommern, Niedersachsen, Nordrhein-Westfalen, Rheinland-Pfalz, Saarland, Sachsen, Sachsen-Anhalt, Schleswig-Holstein, Thüringen',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Ostern (regionaler Feiertag)',
    end: '2022-04-18',
    start: '2022-04-17',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20220526_jgrejg15bkvh7o77rvtht2o99k',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Christi Himmelfahrt',
    end: '2022-05-27',
    start: '2022-05-26',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20220616_gj0en6peukldc4b466t8ru00go',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description:
      'Feiertag in Baden-Württemberg, Bayern, Hessen, Nordrhein-Westfalen, Rheinland-Pfalz, Saarland, Sachsen, Thüringen',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Fronleichnam (regionaler Feiertag)',
    end: '2022-06-17',
    start: '2022-06-16',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20220106_c55q37enptungumpq3lcasqrqc',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Feiertag in Baden-Württemberg, Bayern, Sachsen-Anhalt',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Heilige Drei Könige (regionaler Feiertag)',
    end: '2022-01-07',
    start: '2022-01-06',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20220501_eu48tsssfu5tehi40h6lc85iac',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Tag der Arbeit',
    end: '2022-05-02',
    start: '2022-05-01',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20220606_5vko40qah6gn7tvarguvpub3b4',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Pfingstmontag',
    end: '2022-06-07',
    start: '2022-06-06',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20220808_n3p0d0sind35e2dj9bl94ccirg',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Feiertag in Bayern',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Hohes Friedensfest Augsburg (Bayern)',
    end: '2022-08-09',
    start: '2022-08-08',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20220101_3clq7pqonjkib0ceduk1k42mig',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Neujahrstag',
    end: '2022-01-02',
    start: '2022-01-01',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20220415_045b5kikdi35u1qk1ohc85miv8',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Karfreitag',
    end: '2022-04-16',
    start: '2022-04-15',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20220418_g9enfpb523fcvahs196tof61b0',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Ostermontag',
    end: '2022-04-19',
    start: '2022-04-18',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20220920_hutpm9407m7go9qe4detn58la0',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Feiertag in Thüringen',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Weltkindertag (Thüringen)',
    end: '2022-09-21',
    start: '2022-09-20',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20221003_ucmbr6bofu9rkspip7lvn9nj9o',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Tag der Deutschen Einheit',
    end: '2022-10-04',
    start: '2022-10-03',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20221031_t6f50prqj0u7s0csas3j6sp8oc',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description:
      'Feiertag in Brandenburg, Mecklenburg-Vorpommern, Sachsen, Sachsen-Anhalt, Thüringen, Schleswig-Holstein, Hamburg, Niedersachsen, Bremen',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Reformationstag (regionaler Feiertag)',
    end: '2022-11-01',
    start: '2022-10-31',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20221101_du4tuppj03t8n0r9ifu0on739g',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description:
      'Feiertag in Baden-Württemberg, Bayern, Nordrhein-Westfalen, Rheinland-Pfalz, Saarland',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Allerheiligen (regionaler Feiertag)',
    end: '2022-11-02',
    start: '2022-11-01',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20221116_u0qm9gc7feii59i2mm6nmgpv2k',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Feiertag in Sachsen',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Buß- und Bettag (Sachsen)',
    end: '2022-11-17',
    start: '2022-11-16',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20221225_62c0vj63fb0jgmt2tsqvcu7hfo',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Erster Weihnachtstag',
    end: '2022-12-26',
    start: '2022-12-25',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20221226_alo0uvg029p0tfd2a8jlrr5il8',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Zweiter Weihnachtstag',
    end: '2022-12-27',
    start: '2022-12-26',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20230101_67c1tm251m1bq4gv4nnq5rhm0s',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Neujahrstag',
    end: '2023-01-02',
    start: '2023-01-01',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20230106_87ha9glr6qt9tqngeccmb2uipk',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Feiertag in Baden-Württemberg, Bayern, Sachsen-Anhalt',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Heilige Drei Könige (regionaler Feiertag)',
    end: '2023-01-07',
    start: '2023-01-06',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20230407_lv6ggpqtsio5pui3ogvv0ib3j8',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Karfreitag',
    end: '2023-04-08',
    start: '2023-04-07',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20230410_j8h7du2tgb74sgbffnnbfrp7cs',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Ostermontag',
    end: '2023-04-11',
    start: '2023-04-10',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20230501_b1h0uksh91mdsq24tfoolk9nfo',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Tag der Arbeit',
    end: '2023-05-02',
    start: '2023-05-01',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20230518_87qn10knesa5nt2f6d3q2scoc4',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Christi Himmelfahrt',
    end: '2023-05-19',
    start: '2023-05-18',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20230529_0ljjg9h8dj4ft4ha2am74mul9k',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Pfingstmontag',
    end: '2023-05-30',
    start: '2023-05-29',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20230608_rkd0obgh7a3fkcm84msk4tobng',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description:
      'Feiertag in Baden-Württemberg, Bayern, Hessen, Nordrhein-Westfalen, Rheinland-Pfalz, Saarland, Sachsen, Thüringen',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Fronleichnam (regionaler Feiertag)',
    end: '2023-06-09',
    start: '2023-06-08',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20230808_hcntnpnfmkgconpdrfba3o8mso',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Feiertag in Bayern',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Hohes Friedensfest Augsburg (Bayern)',
    end: '2023-08-09',
    start: '2023-08-08',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20230920_re6548b4e6c28mo1dttrgrlu20',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Feiertag in Thüringen',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Weltkindertag (Thüringen)',
    end: '2023-09-21',
    start: '2023-09-20',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20231003_up7fltq2c4nt3b13i5lngaoq2o',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Tag der Deutschen Einheit',
    end: '2023-10-04',
    start: '2023-10-03',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20231031_pf9erih9nicot7kbvesas511f8',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description:
      'Feiertag in Brandenburg, Mecklenburg-Vorpommern, Sachsen, Sachsen-Anhalt, Thüringen, Schleswig-Holstein, Hamburg, Niedersachsen, Bremen',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Reformationstag (regionaler Feiertag)',
    end: '2023-11-01',
    start: '2023-10-31',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20231101_j2bpej98u49opl37vpc9tqa3ck',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description:
      'Feiertag in Baden-Württemberg, Bayern, Nordrhein-Westfalen, Rheinland-Pfalz, Saarland',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Allerheiligen (regionaler Feiertag)',
    end: '2023-11-02',
    start: '2023-11-01',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20231122_m0hnmifb9hdodqeqkcgod1vqoc',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Feiertag in Sachsen',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Buß- und Bettag (Sachsen)',
    end: '2023-11-23',
    start: '2023-11-22',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20231225_t0rl00u26tbi35tr3moi83iht0',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Erster Weihnachtstag',
    end: '2023-12-26',
    start: '2023-12-25',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20231226_ppk5alopsqau4h6dk8b243g5bk',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Zweiter Weihnachtstag',
    end: '2023-12-27',
    start: '2023-12-26',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20240101_di2uuh3656jt6ahogto7cj4te4',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Neujahrstag',
    end: '2024-01-02',
    start: '2024-01-01',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20240106_l21ren9l3ritt1fqa2vkfag368',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Feiertag in Baden-Württemberg, Bayern, Sachsen-Anhalt',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Heilige Drei Könige (regionaler Feiertag)',
    end: '2024-01-07',
    start: '2024-01-06',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20240329_8rj77tkmv80qdfe3it2es5op3c',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Karfreitag',
    end: '2024-03-30',
    start: '2024-03-29',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20240331_5sd4k52u2ekaufoq30dp0pb92k',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description:
      'Feiertag in Baden-Württemberg, Bayern, Berlin, Brandenburg, Bremen, Hamburg, Hessen, Mecklenburg-Vorpommern, Niedersachsen, Nordrhein-Westfalen, Rheinland-Pfalz, Saarland, Sachsen, Sachsen-Anhalt, Schleswig-Holstein, Thüringen',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Ostern (regionaler Feiertag)',
    end: '2024-04-01',
    start: '2024-03-31',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20240401_t2b84pc5ke8it7rrsc8k6f080c',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Ostermontag',
    end: '2024-04-02',
    start: '2024-04-01',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20240501_cfpn15c0819flr22pdp42eskso',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Tag der Arbeit',
    end: '2024-05-02',
    start: '2024-05-01',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20240509_a1a9k4sacm6lmmljn510aofbes',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Christi Himmelfahrt',
    end: '2024-05-10',
    start: '2024-05-09',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20240519_ga3ovrrgmnrhncltbe48a1ok50',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description:
      'Feiertag in Baden-Württemberg, Bayern, Berlin, Brandenburg, Bremen, Hamburg, Hessen, Mecklenburg-Vorpommern, Niedersachsen, Nordrhein-Westfalen, Rheinland-Pfalz, Saarland, Sachsen, Sachsen-Anhalt, Schleswig-Holstein, Thüringen',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Pfingsten (regionaler Feiertag)',
    end: '2024-05-20',
    start: '2024-05-19',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20240520_pj757s9jaapbu7sejuvo6ca1a0',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Pfingstmontag',
    end: '2024-05-21',
    start: '2024-05-20',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20240530_qgc2sebgj4b42fig5v31dm88u0',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description:
      'Feiertag in Baden-Württemberg, Bayern, Hessen, Nordrhein-Westfalen, Rheinland-Pfalz, Saarland, Sachsen, Thüringen',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Fronleichnam (regionaler Feiertag)',
    end: '2024-05-31',
    start: '2024-05-30',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20240808_i796gviakrdpp8ppdtnuo2pvp0',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Feiertag in Bayern',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Hohes Friedensfest Augsburg (Bayern)',
    end: '2024-08-09',
    start: '2024-08-08',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20240920_caqla46kvj0enh9tnls2q902qk',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Feiertag in Thüringen',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Weltkindertag (Thüringen)',
    end: '2024-09-21',
    start: '2024-09-20',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20241003_dfvapomfcg36flije73aknlf88',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Tag der Deutschen Einheit',
    end: '2024-10-04',
    start: '2024-10-03',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20241031_r8j3772pghojg80abkgj1a0nis',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description:
      'Feiertag in Brandenburg, Mecklenburg-Vorpommern, Sachsen, Sachsen-Anhalt, Thüringen, Schleswig-Holstein, Hamburg, Niedersachsen, Bremen',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Reformationstag (regionaler Feiertag)',
    end: '2024-11-01',
    start: '2024-10-31',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20241101_gt4bmg13f1aq5e4cf6heovvusk',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description:
      'Feiertag in Baden-Württemberg, Bayern, Nordrhein-Westfalen, Rheinland-Pfalz, Saarland',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Allerheiligen (regionaler Feiertag)',
    end: '2024-11-02',
    start: '2024-11-01',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20241120_mlq18o6euidbesusjjr0hp1e3k',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Feiertag in Sachsen',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Buß- und Bettag (Sachsen)',
    end: '2024-11-21',
    start: '2024-11-20',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20241225_h4a3g9a7ir64jss37mt6a00r98',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Erster Weihnachtstag',
    end: '2024-12-26',
    start: '2024-12-25',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
  {
    id: '20241226_kd7sgllu0ssnnb36rs1kkabpm4',
    groupId: '',
    moduleType: 'calendar',
    likes: [],
    comments: [],
    files: [],
    group: null,
    firstName: null,
    lastName: null,
    isTranslationAllowed: false,
    watsonLanguage: 'null',
    description: 'Gesetzlicher Feiertag',
    eventType: null,
    canceled: false,
    startTime: '',
    endTime: '',
    eventCreatedDate: 0,
    eventUpdatedDate: null,
    timeZone: '(UTC+02:00) Europe/Berlin',
    location: '',
    repeatType: 'none',
    repeatDays: 0,
    repeatEndDate: 0,
    repeatWeeklyInterval: 1,
    reminders: [],
    userParticipations: [],
    editable: false,
    color: '#3788d8',
    title: 'Zweiter Weihnachtstag',
    end: '2024-12-27',
    start: '2024-12-26',
    allDay: true,
    user: null,
    acceptance: null,
    isPublic: true,
    participationInfo: {
      participationEnum: 'NO_PARTICIPANTS',
      maxNumberOfParticipants: null,
      isAllowDecline: false,
      isAllowMaybe: false,
      info: '',
    },
  },
];
