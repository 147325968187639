import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  addCalendarEvent,
  deleteCalendarEvent,
  getCalendarEvents,
  getCalendarSettings,
  updateCalendarEvent
} from "library/api/calendar";
import { getKitaHolidayCares, getKitaHolidays } from "library/api/kita";
import { changeModuleStatus, getUserModules } from "library/api/user";
import { inviteUsersAPI } from 'library/common/commonActions/groupActions';
import KitaPanelWrapper from "library/common/commonComponents/KitaPanelWrapper";
import Wrapper from "library/common/commonComponents/Wrapper";
import Calendar from "library/common/commonComponents/Calendar";
import useCalendarEvents from "library/common/commonHooks/useCalendarEvents";
import useSiteTitle from "library/common/commonHooks/useSiteTitle";
import { formatHolidayCareEvents, formatHolidayEvents, formatBirthdayEvents } from "library/utilities/formatEvents";
import { getBirthdayEventByGroupKita } from 'library/api/user';

import styles from "./dashboardCalendar.module.scss";

export default function DashboardCalendar({ showBottomNotification, match, user }) {
  useSiteTitle('DashboardCalendar');
  const { t } = useTranslation();

  const [locationHost] = useState(window.location.hostname);
  const [holidayEvents, setHolidayEvents] = useState([]);
  const [holidayCareEvents, setHolidayCareEvents] = useState([]);
  const [birthdayEvents, setBirthdayEvents] = useState([]);
  const { kitaId } = useSelector(state => state.kitaReducer.activeKita);

  const defaultFilters = [
    { id: 1, name: 'attending', checked: false },
    { id: 2, name: 'myEvents', checked: false },
    { id: 3, name: 'currentViewDate', value: new Date() },
    { id: 4, name: 'myProfile', checked: false },
    { id: 5, name: 'myGroups', checked: false },
    { id: 6, name: 'myFriends', checked: false },
    { id: 7, name: 'showAbsences', checked: locationHost.toLowerCase().includes('gfz-app.ch') },
    { id: 8, name: 'publicHolidays', checked: false },
    { id: 9, name: 'holidaySettings', checked: false },
    { id: 10, name: 'holidayCare', checked: false },
    { id: 11, name: 'kidBirthday', checked: false },
  ];

  const getEditableValue = (ownerId, task, groupInfo) => {
    groupInfo = groupInfo || { editDeleteCalenderEntry: true };
    if (!task) {
      return groupInfo.editDeleteCalenderEntry && (groupInfo.groupAdminStatus || user.id === ownerId);
    }
    const isResponsible = task.responsibleUsersList.find(item => item.id === user.id);
    return user.id === ownerId ||
      (!task.assignedUsersList.length && groupInfo.processUnassignedTask) ||
      groupInfo.groupAdminStatus ||
      isResponsible;
  };

  const {
    events,
    calendarSettings,
    deleteEvent,
    addEvent,
    updateEvent,
    getEventInfo,
    filters,
    setFilters,
    reloadEvents,
  } = useCalendarEvents(
    getCalendarEvents,
    getCalendarSettings,
    deleteCalendarEvent,
    addCalendarEvent,
    updateCalendarEvent,
    defaultFilters,
    user.id,
    getEditableValue,
    'dashboardCalendar',
    user.currentWatsonLang,
    false,
  );

  const [isUserProfileCalendarEnabled, setIsUserProfileCalendarEnabled] = useState(false);
  const [userProfileCalendarSettings, setUserProfileCalendarSettings] = useState(null);
  const [userModules, setUserModules] = useState(null);
  const [isBirthdayEventLoading, setIsBirthdayEventLoading] = useState(false);
  const showBirthdayButton = user.administrationAccess || user.employee;

  const holidaySettingOptionChange = () => {
    setFilters(
      filters.map(item => {
        return item.id === 9 ? { ...item, checked: !item.checked } : item;
      }),
    );
    const isHolidaySettingChecked = filters.find(item => item.id === 9)?.checked;
    if (!isHolidaySettingChecked) {
      getKitaHolidays(kitaId).then(res => {
        if (res.status === 200 && res.data.length > 0) {
          const formattedEvents = formatHolidayEvents(res.data);
          setHolidayEvents(formattedEvents);
        }
      });
    } else {
      setHolidayEvents([]);
    }
  };

  const holidayCareOptionChange = () => {
    setFilters(
      filters.map(item => {
        return item.id === 10 ? { ...item, checked: !item.checked } : item;
      }),
    );
    const isHolidayCareChecked = filters.find(item => item.id === 10)?.checked;
    if (!isHolidayCareChecked) {
      getKitaHolidayCares(kitaId).then(res => {
        if (res.status === 200 && res.data.length > 0) {
          const formattedEvents = formatHolidayCareEvents(res.data);
          setHolidayCareEvents(formattedEvents);
        }
      });
    } else {
      setHolidayCareEvents([]);
    }
  };

  useEffect(() => {
    if (user?.id) {
      getUserModules(user.id)
        .then(res => {
          setUserModules(res.data);
          if (res.status === 200) {
            const calendarModule = res.data.modules.find(item => item.moduleKey === 'calendar');
            setIsUserProfileCalendarEnabled(calendarModule.activeStatus);
          }
        })
        .catch(err => {
          console.log(err);
        })
    }
  }, [user.id]);

  const getBirthdayEvent = async () => {
    const currentDate = filters ? filters.find(item => item.name === 'currentViewDate').value : new Date();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const nextMonthDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
    const endDate = new Date(nextMonthDate - 1);
    const birthdayEvents = (await getBirthdayEventByGroupKita(startDate.getTime(), endDate.getTime())).data;
    const formattedEvents = formatBirthdayEvents(birthdayEvents, null, startDate.getFullYear());
    setBirthdayEvents(formattedEvents);
  }

  useEffect(() => {
    const fetchKidBirthdayEventData = async () => {
      const birthdayEvent = filters.find(item => item.id === 11);
      if (birthdayEvent) {
        setIsBirthdayEventLoading(true);
        const isBirthdayEventChecked = birthdayEvent.checked;
        if (isBirthdayEventChecked) {
          await getBirthdayEvent();
        }
        else {
          setBirthdayEvents([]);
        }
        setIsBirthdayEventLoading(false);
      }
    }
    fetchKidBirthdayEventData();
  }, [filters])

  const filterList = [
    {
      title: t('Calendar.Select calendars'),
      filters: [
        {
          id: 1,
          name: t('Calendar.My profile'),
          checked: filters.reduce((result, item) => {
            return item.id === 4 ? item.checked : result;
          }, ''),
          onChange: () =>
            setFilters(
              filters.map(item => {
                return item.id === 4 ? { ...item, checked: !item.checked } : item;
              }),
            ),
        },
        {
          id: 2,
          name: t('Calendar.My Groups'),
          checked: filters.reduce((result, item) => {
            return item.id === 5 ? item.checked : result;
          }, ''),
          onChange: () =>
            setFilters(
              filters.map(item => {
                return item.id === 5 ? { ...item, checked: !item.checked } : item;
              }),
            ),
        },
        {
          id: 3,
          name: t('Calendar.Kita Holiday Care'),
          checked: filters.reduce((result, item) => {
            return item.id === 10 ? item.checked : result;
          }, ''),
          onChange: holidayCareOptionChange,
        },
      ],
    },
    {
      title: '',
      filters: [
        {
          id: 1,
          name: t('Calendar.My Friends'),
          checked: filters.reduce((result, item) => {
            return item.id === 6 ? item.checked : result;
          }, ''),
          onChange: () =>
            setFilters(
              filters.map(item => {
                return item.id === 6 ? { ...item, checked: !item.checked } : item;
              }),
            ),
        },
        {
          id: 2,
          name: t('Calendar.Public Holidays'),
          checked: filters.reduce((result, item) => {
            return item.id === 8 ? item.checked : result;
          }, ''),
          onChange: () =>
            setFilters(
              filters.map(item => {
                return item.id === 8 ? { ...item, checked: !item.checked } : item;
              }),
            ),
        },
        {
          id: 3,
          name: t('Calendar.Holidays'),
          checked: filters.reduce((result, item) => {
            return item.id === 9 ? item.checked : result;
          }, ''),
          onChange: holidaySettingOptionChange,
        },
      ],
    },
    {
      title: t('Calendar.Filter events'),
      className: styles.eventsFilters,
      filters: [
        {
          id: 1,
          name: t("Calendar.I'm attending"),
          checked: filters.reduce((result, item) => {
            return item.id === 1 ? item.checked : result;
          }, ''),
          onChange: () =>
            setFilters(
              filters.map(item => {
                return item.id === 1 ? { ...item, checked: !item.checked } : item;
              }),
            ),
        },
        {
          id: 2,
          name: t('Calendar.My events'),
          checked: filters.reduce((result, item) => {
            return item.id === 2 ? item.checked : result;
          }, ''),
          onChange: () =>
            setFilters(
              filters.map(item => {
                return item.id === 2 ? { ...item, checked: !item.checked } : item;
              }),
            ),
        },
        {
          id: 3,
          name: t('Calendar.Show absences'),
          checked: filters.reduce((result, item) => {
            return item.id === 7 ? item.checked : result;
          }, ''),
          onChange: () =>
            setFilters(
              filters.map(item => {
                return item.id === 7 ? { ...item, checked: !item.checked } : item;
              }),
            ),
        },
      ],
    },
  ];

  if (showBirthdayButton) {
    filterList[2].filters.push({
      id: 4,
      name: t('Calendar.KidBirthday'),
      checked: filters.reduce((result, item) => {
        return item.id === 11 ? item.checked : result;
      }, ''),
      isLoading: isBirthdayEventLoading,
      onChange: () =>
        setFilters(
          filters.map(item => {
            return item.id === 11 ? { ...item, checked: !item.checked } : item;
          }),
        ),
    });
  }

  const onAddEvent = event => {
    event.multiGroups.map(async group => {
      if (group.groupId) {
        await inviteUsersAPI({
          groupId: group.groupId,
          userGroup: event.parentParticipants,
        });
      }
    });
    return addEvent(
      {
        ...event,
        groupId: event.group ? event.group.groupId : null,
        multiGroups: event.multiGroups,
        calendarId: event.group ? event.group.groupId : event.multiGroups.map(g => g.groupId),
        userId: user.id,
      },
      !event.group || event.group.groupId ? 'groupCalendar' : 'userProfileCalendar',
    );
  };

  const handleEnableUserProfileCalendar = () => {
    const mod = userModules.modules.find(modul => modul.moduleKey === 'calendar');
    if (mod) {
      const { moduleId } = mod;
      const moduleStatus = 'ENABLE';
      return changeModuleStatus({ moduleId, moduleStatus })
        .then(res => {
          if (res.status === 200) {
            return { success: true };
          }
          return { success: false };
        })
        .then(res => {
          if (res.success) {
            return getCalendarSettings(user.id, 'userProfileCalendar').then(resSettings => {
              if (resSettings.status === 200) {
                setUserProfileCalendarSettings(resSettings.data || {});
                setIsUserProfileCalendarEnabled(true);
                return { success: true };
              }
              return { success: false };
            });
          }
          return { success: false };
        })
        .catch(err => {
          console.log(err);
          return { success: false };
        });
    }
  };

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <Calendar
          showBottomNotification={showBottomNotification}
          events={[...events, ...holidayEvents, ...holidayCareEvents, ...birthdayEvents]}
          calendarSettings={userProfileCalendarSettings || calendarSettings}
          canCreateEvent
          getEventInfo={getEventInfo}
          addEvent={onAddEvent}
          updateEvent={updateEvent}
          deleteEvent={deleteEvent}
          match={match}
          filterList={filterList}
          user={user}
          filters={filters}
          setFilters={setFilters}
          reloadEvents={reloadEvents}
          calendarModulePlace='dashboardCalendar'
          isUserProfileCalendarEnabled={isUserProfileCalendarEnabled}
          onEnableUserProfileCalendar={handleEnableUserProfileCalendar}
          getEditableValue={getEditableValue}
        />
      </Wrapper>
    </KitaPanelWrapper>
  );
}
