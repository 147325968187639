import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';

import { getLetterInLogo } from 'library/utilities/groups';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import Loader from 'library/common/commonComponents/Loader';
import GroupPanel from 'library/common/commonComponents/Group/GroupPanel';
import GroupBody from 'library/common/commonComponents/Group/GroupBody';
import { setGroupData, clearGroupData } from 'library/common/commonActions/groupActions';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import GroupAcceptPopup from 'library/common/commonComponents/Popups/GroupAcceptPopup';
import useGroupLoading from './hooks/useGroupLoading';
import useGroupInfo from './hooks/useGroupInfo';

import GroupPrivate from './groupFrames/GroupPrivate';
import styles from './group.module.scss';

export function Group({ history, match, setGroupDataInStore, clearGroupDataInStore, activeKita }) {
  const location = useLocation();

  const {
    isLoading,
    groupData,
    reloadGroupInfo,
    isShowPopup,
    closePopup,
    handleConfirm,
    isSubmitting
  } = useGroupLoading(match.params.id, setGroupDataInStore, history, activeKita);

  if (groupData.error === 203) {
    history.push('/dashboard');
  }

  useEffect(() => {
    return () => {
      clearGroupDataInStore();
    };
    // eslint-disable-next-line
  }, [match.url.split('/')[1]]);

  const { groupInfo, patchGroup, menuItems } = useGroupInfo(groupData, match, activeKita);

  const { t } = useTranslation();

  const { setTemplateTitle } = useSiteTitle();

  useEffect(() => {
    if (!groupData) return;
    setTemplateTitle('GroupProfile', groupData.groupName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData]);

  const isNotInKidsMode = !(
    queryString.parse(location.search).mode && queryString.parse(location.search).mode === 'kids'
  );

  return (
    <KitaPanelWrapper>
      <Wrapper>
        {isLoading ? (
          <Loader />
        ) : groupData.error === 406 ? (
          <GroupPrivate history={history} />
        ) : groupData.error === 404 ? (
          <div className={styles.notFound}>
            <div className={styles.notFoundDescription}>{t('Filters.Oops description')}</div>
            <div className={styles.notFoundText}>{t('Filters.Oops not found')}</div>
          </div>
        ) : (
          <GroupContext.Provider
            value={{ groupInfo, patchGroup, reloadGroupInfo, setGroupDataInStore }}
          >
            {isNotInKidsMode && (
              <GroupPanel
                letter={groupInfo.groupName && getLetterInLogo(groupInfo.groupName)}
                title={groupInfo.groupName}
                description={groupInfo.groupDescription}
                backgroundColor={groupInfo.colorCode}
                postCount={groupInfo.postCount}
                members={groupInfo.userList}
                history={history}
                groupJoinPolicyId={groupInfo.groupJoinPolicy.id}
                type='group'
                match={match}
                activeKita={activeKita}
              />
            )}
            <GroupBody
              menuItems={menuItems}
              groupInfo={groupInfo}
              isNotInKidsMode={isNotInKidsMode}
            />
            <GroupAcceptPopup
              isShowPopup={isShowPopup}
              closePopup={closePopup}
              onConfirm={handleConfirm}
              isSubmitting={isSubmitting}
            />
          </GroupContext.Provider>
        )}
      </Wrapper>
    </KitaPanelWrapper>
  );
}
export default connect(
  state => ({
    user: state.userReducer,
    activeKita: state.kitaReducer.activeKita,
  }),
  {
    setGroupDataInStore: setGroupData,
    clearGroupDataInStore: clearGroupData,
  },
)(Group);

const GroupContext = React.createContext();
export { GroupContext };
