import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PT from 'prop-types';
import cn from 'classnames';

import { createKidDevelopmentDiaryEntry } from 'library/api/kidDevelopmentDiary';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import { formatDateObjectToTimeString } from 'library/utilities/dateMoment';
import store from 'main/store/configureStore';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';

import CheckInOutTimePopup from '../../TimePopup/CheckInOutTimePopup';
import styles from './childRow.module.scss';

const ChildRow = ({ childObj, prepareDelete, prepareEdit }) => {
  const { t } = useTranslation();
  const [foodCounter, setFoodCounter] = useState(childObj.eatTodayCount);
  const [timePopupOpened, setTimePopupOpened] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [todayComments, setTodayComments] = useState(childObj.eatTodayComments);
  const [time, setTime] = useState('');
  const [isValidTime, setIsValidTime] = useState(true);
  const [activeSmileyStatus, setActiveSmileyStatus] = useState(null);

  useEffect(() => {
    const date = new Date();
    const hours = date
      .getHours()
      .toString()
      .padStart(2, '0');
    const minutes = date
      .getMinutes()
      .toString()
      .padStart(2, '0');
    const currentTime = `${hours}:${minutes}`;
    setTime(currentTime);
  }, []);
  const handleTimeChange = e => {
    const selectedTime = e.target.value;
    if (selectedTime < '06:00') {
      setIsValidTime(false);
      setTime('06:00');
    } else if (selectedTime > '18:00') {
      setIsValidTime(false);
      setTime('18:00');
    } else {
      setIsValidTime(true);
      setTime(selectedTime);
    }
  };

  useEffect(() => {
    const lastSmileyStatus = childObj.todaySmileyStatus
      ? childObj.todaySmileyStatus[childObj.todaySmileyStatus.length - 1]
      : null;
    setActiveSmileyStatus(lastSmileyStatus ? lastSmileyStatus.smileyStatus : null);
  }, [childObj.todaySmileyStatus]);

  const toUnixTimestamp = timeString => {
    const date = new Date();

    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(':');

    // Set the hours and minutes on the date object
    date.setHours(hours);
    date.setMinutes(minutes);

    // Return the UNIX timestamp (in seconds)
    return Math.floor(date.getTime());
  };

  const handleSubmitFromTimePopup = (time, text) => {
    setIsSaving(true);
    createKidDevelopmentDiaryEntry(childObj.kidId, 'eat', text, time)
      .then(res => {
        const sortedTodayComments = [...todayComments, res.data];
        sortedTodayComments.sort(function(a, b) {
          return new Date(a.date) - new Date(b.date);
        });
        setTodayComments(sortedTodayComments);
        store.dispatch(
          showBottomNotification(t('BottomNotification.SuccessfullySaved'), {
            isFail: false,
          }),
        );
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('BottomNotification.ErrorSavingTryAgain'), {
            isFail: true,
          }),
        );
      })
      .finally(() => {
        setIsSaving(false);
        setTimePopupOpened(false);
      });
  };

  const handleSubmitSmileyStatus = (smileyStatus) => {
    if( smileyStatus === activeSmileyStatus) {
      return;
    }

    createKidDevelopmentDiaryEntry(
      childObj.kidId,
      'eat',
      null,
      toUnixTimestamp(time),
      smileyStatus,
    ).then(res => {
      setActiveSmileyStatus(smileyStatus);
      store.dispatch(
        showBottomNotification(t('BottomNotification.SuccessfullySaved'), {
          isFail: false,
        }),
      );
    })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('BottomNotification.ErrorSavingTryAgain'), {
            isFail: true,
          }),
        );
      })
      .finally(() => {
      });
  }

  const eat = () => {
    return (
      <>
        <CheckInOutTimePopup
          submit={handleSubmitFromTimePopup}
          isSaving={isSaving}
          handleCloseTimePopup={() => setTimePopupOpened(false)}
          timePopupOpened={timePopupOpened}
        />

        <span className={styles.statusIn}>{t('Checkinout.Portions eaten')}</span>
        <div
          className={styles.changeStatusCheckOut}
          onClick={() => {
            if (foodCounter >= 1) {
              setFoodCounter(prevState => {
                return prevState - 1;
              });
              createKidDevelopmentDiaryEntry(
                childObj.kidId,
                'eat-undo',
                null,
                toUnixTimestamp(time),
              );
            }
          }}
        >
          <i className='fa fa-minus' />
        </div>
        <div>{foodCounter}</div>
        <div
          className={styles.changeStatusCheckIn}
          onClick={() => {
            setFoodCounter(prevState => {
              return prevState + 1;
            });
            createKidDevelopmentDiaryEntry(childObj.kidId, 'eat', null, toUnixTimestamp(time));
          }}
        >
          <i className='fa fa-plus' />
        </div>
        <Input type={'time'} value={time} onChange={e => handleTimeChange(e)} />
        <Button onClick={() => setTimePopupOpened(true)}>{t('Checkinout.AddNote')}</Button>

        {todayComments && todayComments.length > 0 && (
          <div>
            <h5>{t('Checkinout.TodaysComments')}</h5>
            <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
              {todayComments.map(c => {
                return (
                  <li>
                    {formatDateObjectToTimeString(c.date)} "{c.message}"{' '}
                    <a
                      onClick={() =>
                        prepareDelete(c.id, () => {
                          setTodayComments(todayComments.filter(c2 => c2.id !== c.id));
                        })
                      }
                    >
                      <i className='fa fa-trash' />
                    </a>
                    <a
                      onClick={() =>
                        prepareEdit(c, (newText, newDate) => {
                          const sortedTodayComments = todayComments.map(c2 => {
                            if (c2.id === c.id) {
                              return {
                                ...c2,
                                message: newText,
                                date: newDate,
                              };
                            } else return c2;
                          });
                          sortedTodayComments.sort(function(a, b) {
                            return new Date(a.date) - new Date(b.date);
                          });
                          setTodayComments(sortedTodayComments);
                        })
                      }
                      style={{ marginLeft: '5px' }}
                    >
                      <i className='fa fa-pencil' />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.photo}>
        <img src={childObj.groupLogoUrl || defaultAvatar} alt='' />
      </div>
      <div className={styles.name}>
        {childObj.name}
        {childObj.parent1Name !== null && (
          <span className={styles.parent}>{childObj.parent1Name}</span>
        )}
        {childObj.parent2Name !== null && (
          <span className={styles.parent}>{childObj.parent2Name}</span>
        )}
      </div>
      <div className={styles.portionWrapper}>
        <div className={styles.statusWrap}>{eat()}</div>
        <div className={styles.smileyButtonsWrapper}>
          <div
            className={cn(styles.smileyButton, activeSmileyStatus === 'like' && styles.active)}
            onClick={() => handleSubmitSmileyStatus('like')}
          >
            <i className='fa fa-smile-o' />
          </div>
          <div
            className={cn(styles.smileyButton, activeSmileyStatus === 'normal' && styles.active)}
            onClick={() => handleSubmitSmileyStatus('normal')}
          >
            <i className='fa fa-meh-o' />
          </div>
          <div
            className={cn(styles.smileyButton, activeSmileyStatus === 'dislike' && styles.active)}
            onClick={() => handleSubmitSmileyStatus('dislike')}
          >
            <i className='fa fa-frown-o' />
          </div>
        </div>
      </div>
    </div>
  );
};

ChildRow.propTypes = {
  childObj: PT.shape({
    kidId: PT.number.isRequired,
    name: PT.string.isRequired,
    photo: PT.string,
    parent1Name: PT.string,
    parent2Name: PT.string,
    checkInOutStatus: PT.string.isRequired,
  }).isRequired,
};

export default ChildRow;
