import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getKidHistory, getKidsList } from 'library/api/kidDevelopmentDiary';
import Select from 'library/common/commonComponents/Inputs/Select';
import Label from 'library/common/commonComponents/Label';
import TextCaret from 'library/common/commonComponents/TextCaret';
import { getKidWithDetailsAndParentInformationById } from 'library/api/formEditor';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import Storage from 'library/utilities/storage';
import { downloadKidPdf, downloadTotalPdf } from 'library/utilities/checkinOverviewform';
import './PresenceDayOverview.css';
import Button from 'library/common/commonComponents/Buttons/Button';
import {
  getKidAbsenceByTimespanAndGroup,
  getKidAbsenceAndHistoryDataForAbsenceCalendarByTimespanAndGroup,
  downloadKidAbsenceOverviewForGroup,
  downloadKidAbsenceOverviewForChild,
  downloadKidAbsenceOverviewForGroupPdf,
  downloadKidAbsenceOverviewForChildPdf,
} from 'library/api/user';

import styles from './PresenceDayOverview.module.scss';
import { getConfig } from 'library/api/kita';

const PresenceDayOverview = ({ groupInfo, kidIds, kita }) => {
  const { t } = useTranslation();
  const locale = Storage.getItem('locale');
  const [events, setEvents] = useState([]);
  const [selectedChild, setSelectedChild] = useState(null);
  const [kidsList, setKidsList] = useState([]);
  const currentInitialDate = new Date(); // 2009-11-10
  const currentInitialMonth = currentInitialDate.toLocaleString('default', { month: 'long' });
  const [currentCalendarMonth, setCurrentCalendarMonth] = useState(currentInitialMonth);
  const [currentCalendarYear, setCurrentCalendarYear] = useState(currentInitialDate.getFullYear());
  const [selectedKidTableData, setSelectedKidTableData] = useState({ rows: [], headers: [] });
  const [totalKidsTableData, setTotalKidsTableData] = useState({ rows: [], headers: [] });

  const calendarRef = useRef(null);

  useEffect(() => {
    getKidsList(groupInfo.groupId).then(res => {
      let tempDropdownOptions = [];
      res.data
        .filter(k => kidIds.includes(k.kidId))
        .forEach(item =>
          tempDropdownOptions.push({
            id: item.kidId,
            title: item.name,
          }),
        );
      setKidsList(tempDropdownOptions);
    });
  }, []);

  const getGroupedObjectByTime = array => {
    const grouped = array.reduce((grpdHistory, entry) => {
      const formattedDate = entry.checkDate.slice(0, 10);

      if (grpdHistory[formattedDate] == null) {
        grpdHistory[formattedDate] = [];
      }
      grpdHistory[formattedDate].push(entry);
      return grpdHistory;
    }, {});

    return grouped;
  };

  const checkOneHourDifference = (start, end) => {
    const date1 = new Date(start);
    const date2 = new Date(end);

    if (date2.getTime() - date1.getTime() >= 3600000) {
      return true;
    } else {
      return false;
    }
  };

  const [configs, setConfigs] = useState({
    lastLogin: false,
    signature: false,
    communication: false,
    receivePrivateMessage: false,
    groupMandatoryField: false,
    allowParentsAddingChild: false,
    kidContactAvailableForEmployee: false,
    specificPrivacyVisible: false,
    memberOptionVisible: false,
    ageOfChild: false,
    ageOfChildValue: 1,
  });

  useEffect(() => {
    const fetchConfigData = async () => {
      try {
        const res = await getConfig();
        const kitaConfig = res.data;

        let fetchedAgeValue = kitaConfig.ageOfChildValue || 1;
        if (fetchedAgeValue < 1) {
          fetchedAgeValue = 1;
        }

        setConfigs(prev => ({
          ...prev,
          lastLogin: kitaConfig.lastLoginVisible,
          signature: kitaConfig.signatureVisible,
          communication: kitaConfig.communicationVisible,
          receivePrivateMessage: kitaConfig.receivePrivateMessage,
          groupMandatoryField: kitaConfig.groupMandatoryField,
          allowParentsAddingChild: kitaConfig.allowParentsAddingChild,
          kidContactAvailableForEmployee: kitaConfig.kidContactAvailableForEmployee,
          specificPrivacyVisible: kitaConfig.specificPrivacyVisible,
          memberOptionVisible: kitaConfig.memberOptionVisible,
          ageOfChild: kitaConfig.ageOfChild,
          ageOfChildValue: fetchedAgeValue,
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchConfigData();
  }, []);

  const isDateBetween = (dateObj, startDateObj, endDateObj) => {
    const dateYear = dateObj.getFullYear();
    const dateMonth = dateObj.getMonth();
    const dateDay = dateObj.getDate();

    const startYear = startDateObj.getFullYear();
    const startMonth = startDateObj.getMonth();
    const startDay = startDateObj.getDate();

    const endYear = endDateObj.getFullYear();
    const endMonth = endDateObj.getMonth();
    const endDay = endDateObj.getDate();

    const startDate = new Date(startYear, startMonth, startDay);
    const endDate = new Date(endYear, endMonth, endDay);
    const toCompareDate = new Date(dateYear, dateMonth, dateDay);

    return toCompareDate >= startDate && toCompareDate <= endDate;
  };

  const isToday = date => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const isTheSameMonth = (date1, date2) => {
    return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
  };

  const isTheSameDate = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const isFuture = date => {
    const currentDate = new Date();
    return date.getTime() > currentDate.getTime();
  };

  const includesDate = (events, date) => {
    let result = false;
    events.forEach(event => {
      const tempDate = new Date(event);
      if (isTheSameDate(tempDate, date)) {
        result = true;
        return;
      }
    });
    return result;
  };

  const updateHeaderDataOfGivenEvents = (
    lastDayOfCurrentMonth,
    currentDayOfMonth,
    headers,
    updatedTableData,
  ) => {
    const tmpDate = new Date(currentDayOfMonth.getTime());
    while (tmpDate <= lastDayOfCurrentMonth) {
      const tempDay = tmpDate
        .getDate()
        .toString()
        .padStart(2, '0');

      const newHeaderCell = {};
      newHeaderCell.title = tempDay;
      newHeaderCell.key = tempDay;
      headers.push(newHeaderCell);
      tmpDate.setDate(tmpDate.getDate() + 1);
    }
    updatedTableData.headers = headers;
  };
  const updateRowDataOfGivenEvents = (
    tempEvents,
    lastDayOfCurrentMonth,
    currentDayOfMonth,
    newRowCell,
  ) => {
    tempEvents.forEach(item => {
      const tempDate = new Date(item.date);
      const tempDay = tempDate
        .getDate()
        .toString()
        .padStart(2, '0');
      newRowCell[tempDay] = item.color;
    });

    // add future days to pdf
    const today = new Date();
    if (isTheSameMonth(currentDayOfMonth, today)) {
      currentDayOfMonth = today;
      currentDayOfMonth.setDate(currentDayOfMonth.getDate() + 1);
      if (currentDayOfMonth <= lastDayOfCurrentMonth)
        while (currentDayOfMonth <= lastDayOfCurrentMonth) {
          const tempDay = currentDayOfMonth
            .getDate()
            .toString()
            .padStart(2, '0');
          newRowCell[tempDay] = 'white';
          currentDayOfMonth.setDate(currentDayOfMonth.getDate() + 1);
        }
    }
  };
  async function fetchAbsenceSelectedChildEventsData(date) {
    let firstDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let currentDayOfMonth = firstDayOfCurrentMonth;
    let pdfTableHeaders = [];
    const kidAbsenceEventsDataResult = await fetchKidAbsenceEventsDataBulk(
      [
        {
          kidId: selectedChild.kidId,
          title: `${selectedChild.firstName} ${selectedChild.lastName}`,
        },
      ],
      date,
      lastDayOfCurrentMonth,
      currentDayOfMonth,
    );

    const { tempEvents, returningKidName } = kidAbsenceEventsDataResult[selectedChild.kidId];

    let updatedTableData = {};
    let newRowCell = {};

    firstDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    currentDayOfMonth = firstDayOfCurrentMonth;
    updateHeaderDataOfGivenEvents(
      lastDayOfCurrentMonth,
      currentDayOfMonth,
      pdfTableHeaders,
      updatedTableData,
    );

    firstDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    currentDayOfMonth = firstDayOfCurrentMonth;
    updateRowDataOfGivenEvents(tempEvents, lastDayOfCurrentMonth, currentDayOfMonth, newRowCell);

    updatedTableData.rows = [];
    updatedTableData.rows.push(newRowCell);
    setSelectedKidTableData(updatedTableData);
    setEvents(tempEvents);
  }

  async function fetchAbsenceAllKidsEventsData(date) {
    let updatedTableData = {};
    let pdfTableHeaders = [];

    const firstDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const currentDayOfMonth = firstDayOfCurrentMonth;
    const lastDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    pdfTableHeaders.push({ key: 'name', title: 'Name' });
    updateHeaderDataOfGivenEvents(
      lastDayOfCurrentMonth,
      currentDayOfMonth,
      pdfTableHeaders,
      updatedTableData,
    );

    const kidAbsenceEventsDataResult = await fetchKidAbsenceEventsDataBulk(
      kidsList.map(k => {
        return {
          kidId: k.id,
          title: k.title,
        };
      }),
      date,
      lastDayOfCurrentMonth,
      currentDayOfMonth,
    );

    const rowCellsPromises = kidsList.map(async ({ id, title }) => {
      if (!kidAbsenceEventsDataResult[id]) {
        return {
          name: '',
        };
      }
      const { tempEvents, returningKidName } = kidAbsenceEventsDataResult[id];

      let newRowCell = {};
      newRowCell['name'] = returningKidName;

      updateRowDataOfGivenEvents(tempEvents, lastDayOfCurrentMonth, currentDayOfMonth, newRowCell);
      return newRowCell;
    });

    const rowCells = await Promise.all(rowCellsPromises);
    updatedTableData.rows = [];
    rowCells.forEach(rowCellItem => {
      updatedTableData.rows.push(rowCellItem);
    });
    setTotalKidsTableData(updatedTableData);

    return updatedTableData;
  }

  async function fetchKidAbsenceEventsDataBulk(
    kids,
    date,
    lastDayOfCurrentMonth,
    currentDayOfMonth,
  ) {
    const monthYearString =
      (date.getMonth() + 1).toString().padStart(2, '0') +
      '.' +
      date
        .getFullYear()
        .toString()
        .padStart(4, '0');

    const absenceAndHistoryData = await getKidAbsenceAndHistoryDataForAbsenceCalendarByTimespanAndGroup(
      groupInfo.groupId,
      currentDayOfMonth.getTime(),
      lastDayOfCurrentMonth.getTime(),
    );

    const res = {};

    for (let i = 0; i < kids.length; i++) {
      const kid = kids[i];

      // const kidHistoryResponse = await getKidHistory(kid.kidId, monthYearString);
      const kidHistoryData = absenceAndHistoryData.data.historyPerKid[kid.kidId];
      if (!kidHistoryData) {
        continue;
      }

      // const groupedKidCheckInOuts = getGroupedObjectByTime(kidHistoryResponse.data.kidCheckInOuts);
      const groupedKidCheckInOuts = getGroupedObjectByTime(kidHistoryData.kidCheckInOuts);
      // const curChildAbsenceData = absenceData.data.filter(ad => ad.kid.id === kid.kidId);
      const curChildAbsenceData = absenceAndHistoryData.data.absences.filter(
        ad => ad.kid.id === kid.kidId,
      );

      const lookupResult = lookupKidAbsenceEventsData(
        kid.title,
        date,
        new Date(lastDayOfCurrentMonth.getTime()),
        new Date(currentDayOfMonth.getTime()),
        groupedKidCheckInOuts,
        curChildAbsenceData,
      );

      res[kid.kidId] = lookupResult;
    }

    return res;
  }

  function lookupKidAbsenceEventsData(
    title,
    date,
    lastDayOfCurrentMonth,
    currentDayOfMonth,
    groupedKidCheckInOuts,
    selectedChildAbsenceData,
  ) {
    const abscenceDays = [];
    const presenceDays = [];

    Object.keys(groupedKidCheckInOuts).forEach(keyDate => {
      const sortedGroupedKidCheckInOuts = groupedKidCheckInOuts[keyDate].sort(function(a, b) {
        const dateA = new Date(a.checkDate.replace(' ', 'T'));
        const dateB = new Date(b.checkDate.replace(' ', 'T'));

        const dateNoTimeA = new Date(dateA.toDateString());
        const dateNoTimeB = new Date(dateB.toDateString());

        if (dateNoTimeA.getTime() !== dateNoTimeB.getTime()) {
          return -(dateNoTimeA.getTime() - dateNoTimeB.getTime());
        }

        return dateA - dateB;
      });
      const checkIns = [];

      sortedGroupedKidCheckInOuts.forEach(({ checkDate, checkInOutType }, i) => {
        if (checkInOutType === 'CHECKIN') {
          let noCheckouts = true;
          let checkedOut = false;
          const checkInCheckDate = new Date(checkDate);
          const now = new Date();
          const todayIs = isToday(checkInCheckDate);
          if (todayIs && checkOneHourDifference(checkInCheckDate, now)) {
            checkedOut = true;
          } else if (!todayIs) {
            for (let j = i + 1; j < sortedGroupedKidCheckInOuts.length; j++) {
              const secondCheck = new Date(sortedGroupedKidCheckInOuts[j].checkDate);
              if (sortedGroupedKidCheckInOuts[j].checkInOutType === 'CHECKOUT') {
                noCheckouts = false;
                if (
                  checkOneHourDifference(checkInCheckDate, secondCheck) &&
                  isTheSameDate(checkInCheckDate, secondCheck)
                ) {
                  checkedOut = true;
                }
              }
            }
          }
          if (checkedOut || noCheckouts) {
            checkIns.push({ id: i, presence: true });
          } else {
            checkIns.push({ id: i, presence: false });
          }
        }
      });
      let presence = false;
      checkIns.forEach(checkIn => {
        if (checkIn.presence) {
          presence = true;
          return;
        }
      });

      const thisDate = new Date(date);
      const eventDate = new Date(keyDate);
      if (isTheSameMonth(thisDate, eventDate)) {
        if (presence) {
          presenceDays.push(
            eventDate
              .getFullYear()
              .toString()
              .padStart(4, '0') +
              '-' +
              (eventDate.getMonth() + 1).toString().padStart(2, '0') +
              '-' +
              eventDate
                .getDate()
                .toString()
                .padStart(2, '0'),
          );
        } else {
          abscenceDays.push(
            eventDate
              .getFullYear()
              .toString()
              .padStart(4, '0') +
              '-' +
              (eventDate.getMonth() + 1).toString().padStart(2, '0') +
              '-' +
              eventDate
                .getDate()
                .toString()
                .padStart(2, '0'),
          );
        }
      }
    });

    while (currentDayOfMonth <= lastDayOfCurrentMonth && !isFuture(currentDayOfMonth)) {
      if (!includesDate(presenceDays.concat(abscenceDays), currentDayOfMonth)) {
        abscenceDays.push(
          currentDayOfMonth
            .getFullYear()
            .toString()
            .padStart(4, '0') +
            '-' +
            (currentDayOfMonth.getMonth() + 1).toString().padStart(2, '0') +
            '-' +
            currentDayOfMonth
              .getDate()
              .toString()
              .padStart(2, '0'),
        );
      }
      currentDayOfMonth.setDate(currentDayOfMonth.getDate() + 1);
    }

    const totalEvents = [];
    abscenceDays.forEach(abscenceDay => {
      let newEvent = {};
      if (selectedChildAbsenceData.length > 0) {
        let includedInAbsenceData = false;
        selectedChildAbsenceData.forEach(({ startDate, endDate, sickness, vacation }) => {
          const startDateObj = new Date(startDate.replace(' ', 'T'));
          const endDateObj = new Date(endDate.replace(' ', 'T'));
          const dateObj = new Date(abscenceDay);
          if (isDateBetween(dateObj, startDateObj, endDateObj)) {
            includedInAbsenceData = true;
            let color = '';
            let title = '';

            if (vacation) {
              color = 'purple';
              title = t('Checkinout.vacation');
            } else {
              color = 'red';
              title = t('Checkinout.sickness');
            }

            newEvent = {
              title: title,
              date: abscenceDay,
              color: color,
            };
          }
        });
        if (!includedInAbsenceData) {
          newEvent = {
            title: t('Checkinout.absent'),
            date: abscenceDay,
            color: 'yellow',
          };
        }
      } else {
        newEvent = {
          title: t('Checkinout.absent'),
          date: abscenceDay,
          color: 'yellow',
        };
      }

      totalEvents.push(newEvent);
    });

    presenceDays.forEach(presenceDay => {
      const newEvent = {
        title: t('Checkinout.present'),
        date: presenceDay,
        color: 'green',
      };
      totalEvents.push(newEvent);
    });
    if (selectedChildAbsenceData.length > 0) {
      selectedChildAbsenceData.forEach(({ startDate, endDate, sickness, vacation }) => {
        const startDateObj = new Date(startDate.replace(' ', 'T'));
        const endDateObj = new Date(endDate.replace(' ', 'T'));
        if (
          (isFuture(startDateObj) && !isToday(startDateObj)) ||
          (isFuture(endDateObj) && !isToday(endDateObj))
        ) {
          let color = '';
          let title = '';

          if (vacation) {
            color = 'purple';
            title = t('Checkinout.vacation');
          } else {
            color = 'red';
            title = t('Checkinout.sickness');
          }

          let currentDay = startDateObj;
          while (!isFuture(currentDay) || isToday(currentDay)) {
            currentDay.setDate(currentDay.getDate() + 1);
          }
          while (currentDay <= endDateObj) {
            const newEvent = {
              title: title,
              date:
                currentDay
                  .getFullYear()
                  .toString()
                  .padStart(4, '0') +
                '-' +
                (currentDay.getMonth() + 1).toString().padStart(2, '0') +
                '-' +
                currentDay
                  .getDate()
                  .toString()
                  .padStart(2, '0'),
              color: color,
            };
            totalEvents.push(newEvent);

            currentDay.setDate(currentDay.getDate() + 1);
          }
        }
      });
    }

    return { tempEvents: totalEvents, returningKidName: title };
  }

  const handleMonthChange = () => {
    if (calendarRef.current) {
      const currentDate = calendarRef.current.getApi().getDate();
      setCurrentCalendarMonth(currentDate.toLocaleString('default', { month: 'long' }));
      setCurrentCalendarYear(currentDate.getFullYear());
      if (selectedChild) {
        fetchAbsenceSelectedChildEventsData(currentDate);
      }
      // fetchAbsenceAllKidsEventsData(currentDate);
    }
  };

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();

      const currentDate = calendarApi.getDate();

      if (selectedChild) fetchAbsenceSelectedChildEventsData(currentDate);
    }
  }, [selectedChild]);

  /* useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();

      const currentDate = calendarApi.getDate();

      if (selectedChild) fetchAbsenceSelectedChildEventsData(currentDate);

      fetchAbsenceAllKidsEventsData(currentDate);
    }
  }, [kidsList]); */

  const buttonText = {
    today: t('Calendar.Today'),
  };

  return (
    <div>
      <Button
        onClick={() => {
          const date = calendarRef.current.getApi().getDate();
          const monthYearString = `${String(date.getMonth() + 1).padStart(
            2,
            '0',
          )}.${date.getFullYear()}`;

          downloadKidAbsenceOverviewForGroupPdf(groupInfo.groupId, monthYearString).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Abwesenheiten_${groupInfo.groupName}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          });
        }}
        type='light'
        size='sm'
        className={styles.smallRightMargin}
      >
        {t('PresenceEvaluationPdf.TotalKidsOverview')}
        <i style={{ marginLeft: '5px' }} className='fa fa-download' />
      </Button>
      <Button
        onClick={() => {
          const date = calendarRef.current.getApi().getDate();
          const monthYearString = `${String(date.getMonth() + 1).padStart(
            2,
            '0',
          )}.${date.getFullYear()}`;

          downloadKidAbsenceOverviewForGroup(groupInfo.groupId, monthYearString).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Abwesenheiten_${groupInfo.groupName}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          });
        }}
        type='light'
        size='sm'
        className={styles.smallRightMargin}
      >
        {t('PresenceEvaluationExcel.TotalKidsOverview')}
        <i style={{ marginLeft: '5px' }} className='fa fa-download' />
      </Button>
      {selectedChild !== null && (
        <>
          <Button
            onClick={() => {
              const date = calendarRef.current.getApi().getDate();
              const monthYearString = `${String(date.getMonth() + 1).padStart(
                2,
                '0',
              )}.${date.getFullYear()}`;

              downloadKidAbsenceOverviewForChildPdf(selectedChild.kidId, monthYearString).then(
                res => {
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    `Abwesenheiten_${selectedChild.firstName}_${selectedChild.lastName}.pdf`,
                  );
                  document.body.appendChild(link);
                  link.click();
                },
              );
            }}
            type='light'
            size='sm'
          >
            {t('PresenceEvaluationPdf.SingleChildOverview')}
            <i style={{ marginLeft: '5px' }} className='fa fa-download' />
          </Button>
          <Button
            onClick={() => {
              const date = calendarRef.current.getApi().getDate();
              const monthYearString = `${String(date.getMonth() + 1).padStart(
                2,
                '0',
              )}.${date.getFullYear()}`;

              downloadKidAbsenceOverviewForChild(selectedChild.kidId, monthYearString).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                  'download',
                  `Abwesenheiten_${selectedChild.firstName}_${selectedChild.lastName}.xlsx`,
                );
                document.body.appendChild(link);
                link.click();
              });
            }}
            type='light'
            size='sm'
          >
            {t('PresenceEvaluationExcel.SingleChildOverview')}
            <i style={{ marginLeft: '5px' }} className='fa fa-download' />
          </Button>
        </>
      )}
      {kidsList.length > 0 && (
        <div>
          <Label type='input'>{t('DefaultContractEdit.ChildSelectLabel')}</Label>
          <Select
            options={kidsList.map(c => {
              return {
                value: c.id,
                label: c.title,
              };
            })}
            onSelect={e => {
              getKidWithDetailsAndParentInformationById(e.value).then(kidData => {
                setSelectedChild(kidData.data);
              });
            }}
            selected={
              selectedChild == null
                ? null
                : {
                    value: selectedChild.kidId,
                    label: `${selectedChild.firstName} ${selectedChild.lastName}`,
                  }
            }
            style={{ zIndex: '10000000000' }}
          />
          <div className={styles.calendarContainer}>
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView='dayGridMonth'
              events={events}
              weekends={false}
              ref={calendarRef}
              datesSet={handleMonthChange}
              locale={locale}
              buttonText={buttonText}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PresenceDayOverview;
