import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getAllPostTemplates } from 'library/api/posts';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import Loader from 'library/common/commonComponents/Loader';
import Popup from 'library/common/commonComponents/Popups/Popup';
import store from 'main/store/configureStore';

import TemplateLine from './TemplateLine';

import styles from './postLoadTemplateButton.module.scss';

export default function PostLoadTemplateButton({
  updateText,
  loadCalendarEventFromTemplate,
  type,
}) {
  const { t } = useTranslation();
  const [popupOpened, setPopupOpened] = useState(false);
  const [isTemplatesLoading, setIsTemplatesLoading] = useState(false);
  const [templates, setTemplates] = useState([]);

  const handleTemplateButtonClick = () => {
    setIsTemplatesLoading(true);
    getAllPostTemplates()
      .then(res => {
        const temps =
          type === 'calendar'
            ? res.data.filter(template => template.post.moduleType === 'calendar')
            : res.data.filter(template => template.post.moduleType !== 'calendar');
        setTemplates(temps);
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('PostLoadTemplatePopup.LoadError'), { isFail: true }),
        );
      }).finally(() => {
        setIsTemplatesLoading(false);
    });
    setPopupOpened(true);
  };

  const handleApplyCalendarTemplate = (template, calendarEvent) => {
    loadCalendarEventFromTemplate(template, calendarEvent);
    setPopupOpened(false);
  };

  const handleApplyTemplate = template => {
    updateText(template.post.text);
    setPopupOpened(false);
  };

  return (
    <>
      <Popup
        isOpened={popupOpened}
        closePopup={() => setPopupOpened(false)}
        header={t('PostLoadTemplatePopup.Header')}
        body={isTemplatesLoading ? (
          <Loader />
        ) : (
          <div className={styles.templatesWrapper}>
            {templates.length > 0 ? (
              templates.map(template => (
                <TemplateLine
                  key={template.id}
                  template={template}
                  handleApplyTemplate={() => handleApplyTemplate(template)}
                  handleApplyCalendarTemplate={handleApplyCalendarTemplate}
                  type={type}
                />
              ))
            ) : (
              <p className={styles.noTemplates}>{t('PostLoadTemplatePopup.NoTemplates')}</p>
            )}
          </div>
        )}
        footer={
          <div>
            <Button onClick={() => setPopupOpened(false)}>{t('Popup.Cancel')}</Button>
          </div>
        }
      />

      <Button
        onClick={handleTemplateButtonClick}
        className={styles.templateButton}
      >
        <i
          className='fa fa-object-group'
          aria-hidden='true'
          aria-label='Template laden'
          style={{ fontSize: "15px", verticalAlign: "middle" }}
        />
      </Button>
    </>
  );
}
