import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Card from 'library/common/commonComponents/to-refactor/commonCard';
import Input from 'library/common/commonComponents/to-refactor/commonInput';
import CardHeading from 'library/common/commonComponents/to-refactor/commonCard/CardHeading';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import Storage from 'library/utilities/storage';
import { validateEmail } from 'library/utilities/validation';

import styles from './index.module.scss';

export function RegistrationFrame(props) {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isBounceAnimation, setIsBounceAnimation] = useState(true);
  const [isInputError, setIsInputError] = useState(false);
  const [isSuccessfully, setIsSuccessfully] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const stateHandlers = {
    email: setEmail,
    selectedLanguage: setSelectedLanguage,
    emailError: setEmailError,
    isDisabled: setIsDisabled,
    isBounceAnimation: setIsBounceAnimation,
    isInputError: setIsInputError,
  };

  const [gdprCheckboxState, setgdprCheckboxState] = useState(true);

  // eslint-disable-next-line
  useEffect(() => {
    if (props.selectedLanguage !== selectedLanguage) {
      setSelectedLanguage(props.selectedLanguage);
      resetErrorInput();
    }
  });

  const handleInputChange = event =>
    handleInputChangeHandler({ event, setEmailError, stateHandlers });

  const { t } = useTranslation();

  const setDisabledFlag = () => {
    setIsDisabled(false);
    setIsInputError(false);
  };

  const setErrorOnInput = value =>
    setErrorOnInputHandler({
      value,
      setEmailError,
      setIsInputError,
      setIsDisabled,
      setIsBounceAnimation,
      setDisabledFlag,
      t,
    });

  const resetErrorInput = () =>
    resetErrorInputHandler({
      setEmailError,
    });

  const register = () =>
    registerHandler({
      email,
      setErrorOnInput,
      setIsSuccessfully,
      setEmailError,
      registrationService: props.registrationService,
      setIsInputError,
      setDisabledFlag,
      setIsDisabled,
      t,
    });

  const onSubmit = !isDisabled ? register : undefined;

  return !window.location.hostname.toLowerCase().includes('gfz-app.ch') &&
    !window.location.hostname.toLowerCase().includes('diekita.safe2connect.org') &&
    !window.location.hostname.toLowerCase().includes('ds-shanghai.safe2connect.org') &&
    !window.location.hostname.toLowerCase().includes('indag.safe2connect.org') ? (
    <Card
      className={cn(
        styles.signup_container,
        'animated',
        { bounceIn: isBounceAnimation },
        { shake: isInputError },
      )}
    >
      <CardHeading className={styles.signup_heading}>
        <span className={styles.signup_heading_text_sign_in}>
          <strong>{t('Signup.Sign') + ' '}</strong>
          {t('Signup.up')}
        </span>
      </CardHeading>
      <CardHeading className={styles.signup_heading_2}>
        {isSuccessfully ? (
          <span>{t('Signup.A verification link has been sent to your email address')}</span>
        ) : (
          <div className={styles.signup_heading_info}>
            <span>{t("Signup.Don't have an account?")}</span>
            <div className={styles.signup_heading_info_checkbox}>
              <input
                name='isGoing'
                type='checkbox'
                onChange={() => {
                  setgdprCheckboxState(!gdprCheckboxState);
                }}
                style={{ height: '18px', width: '18px' }}
                defaultChecked={!gdprCheckboxState}
              />
              <div>
                {t('Signup.Accept data protection checkbox')}
                {'  '}
                <a href='auth-privacy'>{t('Signup.Accept data protection')}</a>
              </div>
            </div>
          </div>
        )}
      </CardHeading>
      {!isSuccessfully && (
        <form onSubmit={register} className={styles.formGroup}>
          <div className={styles.formGroup}>
            <Input
              className={styles.email_form}
              placeholder={t('Signup.email')}
              name='email'
              error={emailError !== ''}
              value={email}
              type='text'
              onChange={handleInputChange}
              onEnterDown={onSubmit}
            />
            {emailError !== '' && (
              <div className={styles.error_msg}>
                <InputErrorMsg errorMsg={emailError} />
              </div>
            )}
          </div>
        </form>
      )}
      <hr className={styles.horizontal_line} />
      <div className={cn(styles.signup_btn_container)}>
        {isSuccessfully ? (
          <ButtonWithLoader
            type='primary'
            onClick={!isDisabled ? () => setIsSuccessfully(false) : undefined}
            isLoading={isDisabled}
          >
            {t('Signup.Resend')}
          </ButtonWithLoader>
        ) : (
          <ButtonWithLoader
            type='primary'
            onClick={!isDisabled ? register : undefined}
            isLoading={isDisabled}
            disabled={gdprCheckboxState}
          >
            {t('Signup.Register')}
          </ButtonWithLoader>
        )}
      </div>
    </Card>
  ) : (
    window.location.hostname.toLowerCase().includes('indag.safe2connect.org') ? <><center>{t('Signup.Please contact indag for registration')}</center><br /></> :
      <div />
  );
}

export const resetErrorInputHandler = ({ setEmailError }) => {
  setEmailError('');
};

export const setErrorOnInputHandler = ({
  value,
  setEmailError,
  setIsInputError,
  setIsDisabled,
  setIsBounceAnimation,
  setDisabledFlag,
  t,
}) => {
  if (value === '') {
    setEmailError(t('Signup.Email cannot be blank'));
    setIsInputError(true);
    setIsDisabled(true);
    setIsBounceAnimation(false);
    setDisabledFlag();
    return false;
  } else {
    setIsDisabled(true);
    setIsInputError(false);
    setIsBounceAnimation(false);
    return true;
  }
};

export const handleInputChangeHandler = ({ event, setEmailError, stateHandlers }) => {
  setEmailError('');
  event.preventDefault();
  const { name, value } = event.target;
  stateHandlers[name](value);
};

export const registerHandler = ({
  email,
  setErrorOnInput,
  setIsSuccessfully,
  setEmailError,
  registrationService,
  setIsInputError,
  setDisabledFlag,
  setIsDisabled,
  t,
}) => {
  const trimmed = email.trim();
  if (trimmed.length > 0 && !validateEmail(trimmed)) {
    return setEmailError(t('Signup.You did not use an valid email'));
  }

  if (trimmed.toLowerCase().endsWith('@diako-thueringen.de')) {
    return setEmailError(
      t('Signup.Users with @diako-thueringen.de email can only register via SCIM')
    );
  }

  if (setErrorOnInput(trimmed)) {
    registrationService(trimmed, Storage.getItem('locale')).then(status => {
      if (status === 200) {
        setEmailError('');
        setIsInputError(false);
        setIsDisabled(false);
        setIsSuccessfully(true);
      } else if (status === 208) {
        setEmailError(t('Signup.This email is already registered'));
        setIsInputError(true);
        setDisabledFlag();
      } else {
        setEmailError(t('Signup.Email verification failed'));
        setIsInputError(true);
        setDisabledFlag();
      }
    });
  }
};

const mapStateToProps = state => ({
  login: state.authorizationReducer,
  inputErrorState: state.inputErrorReducer,
  disableButtonState: state.buttonDisableReducer,
  language: state.languageReducer,
});

export default connect(mapStateToProps)(RegistrationFrame);
