import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { sanitize } from 'dompurify';
import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import MovePicturePopup from 'library/common/commonComponents/Popups/MovePicturePopup';
import { timeSince, getUpdatedTime } from 'library/utilities/date';
import Tooltip from 'library/common/commonComponents/Tooltip';
import Button from 'library/common/commonComponents/Buttons/Button';
import Label from 'library/common/commonComponents/Label';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';
import styles from './galleryItemHeader.module.scss';

export default function GalleryItemHeader({
  gallery,
  goBack,
  user,
  isBazaar,
  deleteAllImagesFromGallery,
  dispatch,
  imagesSize,
  selectFiles,
  galleries,
  onMovePictures,
  onDownloadPictures,
  groupInfo,
  allowDownloadImage,
  allowDeleteAndMoveImage,
}) {
  const {
    galleryName,
    firstName,
    lastName,
    editedAt,
    createdAt,
    privateGallery,
    isTranslationAllowed,
    id,
  } = gallery;

  const { t } = useTranslation();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const galleryOptions = galleries.filter(x => x.id !== gallery.id).map(x => {
    return {
      value: x.id,
      label: x.galleryName
    }
  })

  const handleDeleteAll = async () => {
    setIsSubmiting(true);
    await deleteAllImagesFromGallery({ galleryId: id, filesDispatch: dispatch })
      .then(res => {
        if (res.status === 200) {
          store.dispatch(showBottomNotification(t('Gallery.DeleteAllSuccess'), { isFail: false }));
        } else {
          store.dispatch(showBottomNotification(t('Gallery.DeleteAllError'), { isFail: true }));
        }
      })
      .catch(() => {
        store.dispatch(showBottomNotification(t('Gallery.DeleteAllError'), { isFail: true }));
        setIsSubmiting(false);
      });

    setIsSubmiting(false);
    setIsDeletePopupVisible(false);
  };
  const handleDeleteBtnClick = () => {
    setIsDeletePopupVisible(true);
  };

  const handleMoveGallery = () => {
    setShowMoveDialog(true);
  };

  const handleDownloadGallery = () => {
    onDownloadPictures();
  };

  const onMove = async (selectedGallery) => {
    try {
      if (!selectedGallery) {
        return;
      }
      setIsMoving(true);
      await onMovePictures(selectedGallery.value, gallery.id, selectFiles.map(x => x.id));
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsMoving(false);
    }
  }

  return (
    <div className={styles.container}>
      <DeletePopup
        isOpened={isDeletePopupVisible}
        closePopup={() => setIsDeletePopupVisible(false)}
        onDeleteBtnClick={handleDeleteAll}
        headerText={t('Gallery.DeleteAllConfirmTitle')}
        bodyText={t('Gallery.DeleteAllConfirmText')}
        isSubmiting={isSubmiting}
      />
      <MovePicturePopup
        isOpened={showMoveDialog}
        closePopup={() => setShowMoveDialog(false)}
        options={galleryOptions}
        onMove={onMove}
        isSubmiting={isMoving}
      />
      <div className={styles.title}>
        <strong>{isBazaar ? t('Bazaar.Category') : t('GroupBody.LeftNav.Gallery')}</strong>{' '}
        {isTranslationAllowed ? (
          <WithWatsonTranslate
            data={{ text: galleryName, entityId: id, entityType: 'gallery' }}
            Component={({ html }) => <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />}
          />
        ) : (
          galleryName
        )}
      </div>

      <div className={styles.info}>
        <span>
          {firstName} {lastName}
        </span>
        <span className={styles.infoSeparator}>·</span>
        <span>{timeSince({ date: createdAt, langCode: user.langCode })}</span>
        {createdAt !== editedAt && (
          <span className={styles.infoUpdated}>
            <span className={styles.infoSeparator}>·</span>
            <span>
              <strong>
                <Tooltip text={getUpdatedTime(editedAt, user.langCode)}>Updated</Tooltip>
              </strong>
            </span>
          </span>
        )}
      </div>

      <div className={styles.buttonContainer}>
        {!privateGallery && (
          <div>
            <Label type='info'>
              <i className='fa fa-globe' /> {t('Post.Public')}
            </Label>
          </div>
        )}
        {(imagesSize !== 0) ?
          (
            <div className={styles.imagesWrapper}>
              {
                allowDeleteAndMoveImage && (
                  <div className={styles.button} onClick={handleDeleteBtnClick}>
                    <Button size='sm' type='danger'>
                      <i className={cn('fa fa-trash-o', styles.buttonIcon)} /> {t('Gallery.DeleteAll')}
                    </Button>
                  </div>
                )
              }
              {
                !!selectFiles.length && (
                  <>
                    {
                      allowDeleteAndMoveImage && (
                        <div className={styles.button} onClick={handleMoveGallery}>
                          <Button size='sm'>
                            <i className={cn('fa fa-arrows', styles.buttonIcon)} /> {t('Gallery.Move')}
                          </Button>
                        </div>
                      )
                    }
                    {
                      allowDownloadImage && (
                        <div className={styles.button} onClick={handleDownloadGallery}>
                          <Button size='sm'>
                            <i className={cn('fa fa-cloud-download', styles.buttonIcon)} /> {t('Gallery.Download')}
                          </Button>
                        </div>
                      )
                    }
                  </>
                )
              }
            </div>
          )
          :
          <div />
        }
        <div className={styles.button} onClick={goBack}>
          <Button size='sm'>
            <i className={cn('fa fa-arrow-left', styles.buttonIcon)} />{' '}
            {t('Gallery.Back to overview')}
          </Button>
        </div>
      </div>
    </div>
  );
}
