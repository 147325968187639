import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import style from './TablesOverview.module.scss';

import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { deleteTable } from 'library/api/tableEditor';
import ConfirmPopup from 'library/common/commonComponents/Popups/ConfirmPopup';
import Button from 'library/common/commonComponents/Buttons/Button';
import Loader from 'library/common/commonComponents/Loader';

export default function TablesOverview({
  createNewTable,
  tables,
  isLoading,
  startEditTable,
  reloadTables,
}) {
  const { t } = useTranslation();

  const [tableToDelete, setTableToDelete] = useState(null);

  const startDeleteTable = () => {
    deleteTable(tableToDelete.id)
      .then(res => {
        store.dispatch(
          showBottomNotification(t('Administration.TableOverviewDeleteSuccess'), { isFail: false }),
        );
        reloadTables();
      })
      .catch(err => {
        store.dispatch(
          showBottomNotification(t('Administration.TableOverviewDeleteError'), { isFail: true }),
        );
      })
      .finally(() => {
        setTableToDelete(null);
      });
  };

  return (
    <>
      <div className={style.container}>
        <ConfirmPopup
          isOpened={tableToDelete != null}
          closePopup={() => setTableToDelete(null)}
          onConfirm={startDeleteTable}
          text={t('Administration.TableOverviewDeleteConfirmText')}
        />

        <h1>{t('Administration.TableOverviewTitle')}</h1>
        <div className={style.pageContent}>
          <div className={style.addNewButton}>
            <Button onClick={createNewTable} type='success' size='sm'>
              <i className='fa fa-plus' /> {t('Administration.TableOverviewAddFormButton')}
            </Button>
          </div>
          {isLoading && <Loader />}
          {!isLoading && tables && tables.length > 0 ? (
            <div>
              <h2>{t('Administration.TableOverviewListHeader')}</h2>
              <ul>
                {tables.map(table => {
                  return (
                    <p key={table.id}>
                      {table.name}{' '}
                      <a onClick={() => startEditTable(table)}>
                        <i className='fa fa-pencil' />
                      </a>{' '}
                      <a onClick={() => setTableToDelete(table)}>
                        <i className='fa fa-trash' />
                      </a>{' '}
                    </p>
                  );
                })}
              </ul>
            </div>
          ) : (
            <h2>{t('Administration.TableOverviewListNoFormsHeader')}</h2>
          )}
        </div>
      </div>
    </>
  );
}
