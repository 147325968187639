import Button from 'library/common/commonComponents/Buttons/Button';
import Select from 'library/common/commonComponents/Inputs/Select';
import Popup from 'library/common/commonComponents/Popups/Popup';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function TablesPopup({ isOpened, close, selectTableTemplate, availableTemplates }) {
    const { t } = useTranslation();

    const options = availableTemplates.map((table) => {
        return {
            value: table.id,
            label: table.name
        }
    });

    const [ selectedTemplate, setSelectedTemplate ] = useState(options[0] ?? null);

    const currentlySelectedTemplate = () => selectedTemplate ? availableTemplates.find((table) => table.id === selectedTemplate.value) : null;

    return (
        <div>
            <Popup
                size='normal'
                isOpened={isOpened}
                closePopup={close}
                footer={
                    <Button onClick={() => { selectTableTemplate(currentlySelectedTemplate()) }}>
                        {t('Administration.SelectTemplateAction')}
                    </Button>
                }
            >
                <h1>{ t('Administration.SelectTemplate') }</h1>
                <p>{ t('Administration.SelectTemplateText') }</p>
                <Select
                    options={options}
                    onSelect={e => {
                        setSelectedTemplate(e);
                    }}
                    selected={selectedTemplate}
                />
            </Popup>
        </div>
    );
}