import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import Card from 'library/common/commonComponents/Card';
import { getGroupModules, putModule } from 'library/api/groups';
import { isBazaarGroup } from 'library/utilities/groups';
import DisableModulePopup from 'library/common/commonComponents/Popups/DisableModulePopup';
import { GroupContext } from 'modules/Group/groupModule';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Module from 'library/common/commonComponents/Module';
import getModuleText from 'library/utilities/getModuleText';

import styles from './groupBodyModules.module.scss';

export function GroupBodyModules({ match, showBottomNotificationFromProps }) {
  const { t } = useTranslation();
  const [modules, setModules] = useState([]);
  const groupContext = useContext(GroupContext);
  const patchGroupInfo = groupContext.patchGroup;
  const [errors, setErrors] = useState([]);
  const { groupInfo } = groupContext;
  const [confirmProps, setConfirmProps] = useState({
    text: '',
    closePopup: () => { },
    onConfirm: () => { },
    isOpened: false,
  });
  
  useEffect(() => {
    if (groupInfo.groupId) {
      // 1. Pass an *array* with one ID
      getGroupModules([groupInfo.groupId])
        .then(res => {
          // 2. Extract the modules array from the returned map
          //    res.data looks like: { "123": [...], "456": [...], ... }
          //    so grab the modules for our single groupId
          const modulesForThisGroup = res.data[groupInfo.groupId] || [];
  
          // 3. Apply your filtering logic
          const filteredModules = modulesForThisGroup.filter(module =>
            module.moduleKey !== 'translations' &&
            module.moduleKey !== 'employee_portfolio' &&
            module.moduleKey !== 'chat' &&
            module.moduleKey !== 'suggestion_box' &&
            (
              module.moduleKey !== 'bazaar' ||
              (module.moduleKey === 'bazaar' && isBazaarGroup(groupInfo))
            ) &&
            module.moduleKey !== 'tax_consultant'
          );
  
          // 4. Update component state
          setModules(filteredModules);
        })
        .catch(error => {
          console.error('Error fetching modules:', error);
        });
    }
  }, [groupInfo]);
  
  const setModuleHandler = async module => {
    setErrors([]);
    let enableIsValid = true;
    if (module.moduleKey === 'bazaar' && !module.activeStatus) {
      const gallery = modules.filter(mod => mod.moduleKey === 'gallery')[0];
      if (gallery && !gallery.activeStatus) {
        enableIsValid = false;
        errors.push({
          moduleKey: 'bazaar',
          message: t('Bazaar.Please enable the gallery first.'),
        });
        setErrors(errors);
      }
    }

    if (enableIsValid) {
      const modulesTmp = groupInfo.groupModuleList;
      const index = modulesTmp.findIndex(item => item.moduleKey === module.moduleKey);
      if (module.moduleKey === 'absence') {
        await changeModule(modulesTmp, index, module, 'DEACTIVATE');
      } else {
        if (index !== -1 || module.activeStatus) {
          setConfirmProps({
            text: getModuleText(module),
            closePopup: () => {
              setConfirmProps({ ...confirmProps, ...{ isOpened: false } });
            },
            onConfirm: async radioButtonValue => {
              await changeModule(modulesTmp, index, module, radioButtonValue);
              return setConfirmProps({ ...confirmProps, ...{ isOpened: false } });
            },
            isOpened: true,
            isTranslationsModule: module.moduleKey === 'translations',
          });
        } else {
          return changeModule(modulesTmp, index, module);
        }
      }
    }
  };
  const changeModule = (modulesTmp, index, module, radioButtonValue) => {
    const data = {
      moduleAction: module.activeStatus ? radioButtonValue : 'ENABLE',
      groupId: groupInfo.groupId,
      moduleId: module.moduleId,
    };
    return putModule(data)
      .then(res => {
        if (res.status === 200) {
          showBottomNotificationFromProps(t('GroupBody.Modules.Saved'));
          if (index === -1) {
            modulesTmp.push(module);
          } else {
            modulesTmp.splice(index, 1);
          }
          modulesTmp.sort((a, b) =>
            a.moduleName > b.moduleName ? 1 : b.moduleName > a.moduleName ? -1 : 0,
          );
          patchGroupInfo({ groupModuleList: modulesTmp });
        } else {
          showBottomNotificationFromProps(t('BottomNotifications.Something went wrong'), {
            isFail: true,
          });
        }
      })
      .catch(err => {
        console.log(err);
        showBottomNotificationFromProps(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        });
      });
  };

  return (
    <Card title={<Trans i18nKey='GroupBody.Modules.Title' />} className={styles.body}>
      <div className={styles.container}>
        <div className={styles.heading}>
          {t('GroupBody.Modules.Enhance this space with modules')}
        </div>
        <div className={styles.modules}>
          <div className={styles.moduleWrapper}>
            {modules.map(item => (
              <Module
                key={item.moduleId}
                match={match}
                module={item}
                setModuleHandler={setModuleHandler}
                errors={errors}
                groupId={groupInfo.groupId}
              />
            ))}
          </div>
        </div>
      </div>
      <DisableModulePopup {...confirmProps} />
    </Card>
  );
}
export default connect(
  null,
  {
    showBottomNotificationFromProps: showBottomNotification,
  },
)(GroupBodyModules);
