import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { getToken } from 'library/utilities/token';
import { sanitize } from 'dompurify';

import Tooltip from 'library/common/commonComponents/Tooltip';
import MoreBtn from 'library/common/commonComponents/Buttons/MoreBtn';
import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import EditMediaPopup from 'library/common/commonComponents/Popups/EditMediaPopup';
import useGallery from 'library/common/commonHooks/useGallery';
import { getPathWithGroupId } from 'library/utilities/groups';
import defaultImage from 'resources/images/defaultGalleryImage.svg';
import iconFullscreen from 'resources/images/fullscreen.svg';
import * as filesApi from 'library/api/files';
import { isVideo } from 'library/utilities/files';
import VideoPreview from 'library/common/commonComponents/VideoPreview';
import { updatePermanentStatus } from 'library/api/posts';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import store from 'main/store/configureStore';

import styles from '../../gallery.module.scss';
import Checkbox from 'library/common/commonComponents/Checkbox';

export default function GalleryItemImage({
  file,
  galleryFiles,
  index,
  postId,
  deleteImage,
  editImageDescription,
  groupInfo,
  isDefaultGallery,
  permanent,
  isTranslationAllowed,
  isBazaar,
  user,
  isSelectFile,
  onSelectFile,
  showSelectionCheckbox
}) {
  let allowActions;
  if (user) {
    allowActions = file.userId === user.id || user.administrationAccess;
  }
  const { match, history } = useReactRouter();
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);

  const changePermanentOfImage = (currentStatus, post) => {
    let success = true;
    updatePermanentStatus(post)
      .then(() => {
        setDropdownOptions(dropdownOptions(!currentStatus));
      })
      .catch(() => {
        success = false;
      })
      .finally(() => {
        store.dispatch(
          showBottomNotification(
            <Trans i18nKey={`BottomNotifications.${success ? 'Saved' : 'Something went wrong'}`} />,
            { isFail: !success },
          ),
        );
      });
  };

  const dropdownOptions = permanento =>
    getDropdownOptions({
      history,
      match,
      postId,
      permanento,
      file,
      t,
      setIsDeletePopupVisible,
      setIsEditing,
      isEditing,
      addModifyGallery: groupInfo.addModifyGallery,
      isDefaultGallery,
      changePermanentOfImage,
    });
  const [dropdownOptionsState, setDropdownOptions] = useState(dropdownOptions(permanent));

  const [isSubmiting, setIsSubmiting] = useState(false);
  const handleDeleteBtnClick = async () => {
    setIsSubmiting(true);
    await deleteImage({ postId, fileId: file.id });
    setIsSubmiting(false);
    setIsDeletePopupVisible(false);
  };

  const { Gallery, openGallery } = useGallery(isBazaar);

  const watsonTranslated = useCallback(
    () => (
      <WithWatsonTranslate
        data={{ text: file.description, entityId: file.id, entityType: 'gallery' }}
        Component={({ html }) => <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />}
      />
    ),
    [],
  );

  return (
    <>
      <Gallery />
      {isDeletePopupVisible && (
        <DeletePopup
          isOpened={isDeletePopupVisible}
          closePopup={() => setIsDeletePopupVisible(false)}
          onDeleteBtnClick={handleDeleteBtnClick}
          headerText={<Trans i18nKey='Gallery.ConfirmDeleteImageTitle' />}
          bodyText={t('Gallery.ConfirmDeleteImageText')}
          isSubmiting={isSubmiting}
        />
      )}
      {isEditing && (
        <EditMediaPopup
          isOpened={isEditing}
          isBazaar={isBazaar}
          closePopup={() => setIsEditing(false)}
          description={file.description}
          file={file}
          editMedia={({ description, price, bazaarItemInfoDescription }) =>
            editImageDescription({
              description,
              price,
              bazaarItemInfoDescription,
              postId,
              fileId: file.id,
            })
          }
        />
      )}
      <div className={cn(styles.gallery)}>
        <div className={styles.moreBtn}>
          {allowActions && <MoreBtn dropdownOptions={dropdownOptionsState} />}
        </div>
        {
          showSelectionCheckbox && (
            <div className={styles.selectBtn}>
              <Checkbox
                className={styles.checkboxFile}
                isChecked={isSelectFile}
                onChange={onSelectFile}/>
            </div>
          )
        }

        {file.description && (
          <Tooltip text={isTranslationAllowed ? watsonTranslated() : file.description}>
            <div className={styles.galleryTitle}>
              {isTranslationAllowed ? watsonTranslated() : file.description}
            </div>
          </Tooltip>
        )}

        <div
          className={styles.galleryImageContainer}
          style={{ backgroundImage: file.path && !isVideo(file.fileId) && `url('${file.path}')` }}
        >
          {!file.path ? (
            <img
              className={cn(styles.galleryImage, { [styles.galleryImageStub]: !file.path })}
              src={defaultImage}
              alt=''
            />
          ) : (
            isVideo(file.fileId) && <VideoPreview file={file} />
          )}
        </div>

        <div
          className={styles.galleryOverlay}
          onClick={() => {
            openGallery(galleryFiles, { index });
          }}
        />
        <img className={styles.galleryPlusIcon} src={iconFullscreen} alt='' />
      </div>
    </>
  );
}

export function getDropdownOptions({
  history,
  match,
  postId,
  t,
  setIsDeletePopupVisible,
  setIsEditing,
  isEditing,
  addModifyGallery,
  isDefaultGallery,
  file,
  permanento,
  changePermanentOfImage,
}) {
  return [
    {
      faIcon: 'fa-link',
      title: t('Gallery.Dropdown.Show connected post'),
      onClick: () => history.push(`${getPathWithGroupId(match.path)}/stream?${postId}`),
    },
    {
      faIcon: 'fa-trash-o',
      title: t('Post.Delete'),
      onClick: () => {
        setIsDeletePopupVisible(true);
      },
      isHidden: !addModifyGallery,
    },
    {
      faIcon: 'fa-pencil',
      title: t('Post.Edit'),
      onClick: () => {
        setIsEditing(!isEditing);
      },
      isHidden: !addModifyGallery || isDefaultGallery,
    },
    /* {
      faIcon: 'fa-download',
      title: t('Gallery.Dropdown.Download'),
      onClick: () => downloadFile(file),
    }, */
    addModifyGallery
      ? {
          faIcon: permanento ? 'fa-unlock' : 'fa-lock',
          title: permanento ? t('Post.Revoke permanent') : t('Post.Make permanent'),
          onClick: () => changePermanentOfImage(permanento, postId),
          isHidden: isDefaultGallery,
        }
      : null,
  ].filter(option => !!option);
}

export async function downloadFile(file, original = '') {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: 'file_download',
        file: file.fileId,
        id: file.id,
        token: getToken().access_token,
        path: file.path,
      }),
    );
    return null;
  }
  const { data } = await filesApi.downloadFile(file.id, original);
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', file.fileId);
  document.body.appendChild(link);
  link.click();
}

export async function downloadFileChat(file) {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: 'file_download',
        file: file.fileId,
        id: file.id,
        token: getToken().access_token,
        path: file.path,
      }),
    );
    return null;
  }

  const { data } = await filesApi.downloadFileChat(file.id);
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', file.fileId);
  document.body.appendChild(link);
  link.click();
}
