import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';

import { getCalendarEventById } from 'library/api/calendar';
import Button from 'library/common/commonComponents/Buttons/Button';
import styles from './TemplateLine.module.scss';

export default function TemplateLine({ template, handleApplyTemplate, handleApplyCalendarTemplate, type }) {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const [calendarEvent, setCalendarEvent] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleApplyCalendar = () => {
    handleApplyCalendarTemplate(template, calendarEvent);
  };

  const toggleShowDetails = async () => {
    if (!showDetails && type === 'calendar') {
      getCalendarEventById(template.post.id).then(res => {
        setCalendarEvent(res.data);
        setStartDate(new Date(res.data.start));
        setEndDate(new Date(res.data.end));
      });
    }
    setShowDetails(!showDetails);
  };

  const handleApply = () => {
    if (type === 'calendar') {
      handleApplyCalendar();
    } else {
      handleApplyTemplate();
    }
  };

  return (
    <div className={styles.templateLine}>
      <div
        className={styles.headerLabel}
        onClick={toggleShowDetails}
      >
        <span>{template.name}</span>
        <i style={{ marginLeft: '10px' }} className={showDetails ? 'fa fa-caret-up' : 'fa fa-caret-down'} />
      </div>
      {showDetails && (
        <div className={styles.detailsWrapper}>
          {type === "calendar" ? (
            <div className={styles.templateDetails}>
              <p>
                <span className={styles.detailLabel}>
                  {t('PostLoadTemplatePopup.CalendarTitle')}
                </span> <span>{calendarEvent.title}</span>
              </p>
              <p>
                <span className={styles.detailLabel}>{t('PostLoadTemplatePopup.CalendarDescription')}</span> <span
                  dangerouslySetInnerHTML={{ __html: sanitize(calendarEvent.description) }} />
              </p>
              <p>
                <span className={styles.detailLabel}>{t('PostLoadTemplatePopup.CalendarAllDay')}</span> <span>
                  {
                    calendarEvent.allDay
                      ? t('PostLoadTemplatePopup.CalendarAllDayYes')
                      : t('PostLoadTemplatePopup.CalendarAllDayNo')
                  }
                </span>
              </p>
              {startDate && (
                <p>
                  <span className={styles.detailLabel}>{t('PostLoadTemplatePopup.CalendarFrom')}</span> <span>
                    {
                      `${
                        startDate.getDate().toString(10).padStart(2, "0")
                      }.${
                        (startDate.getMonth() + 1).toString(10).padStart(2, "0")
                      }.${
                        startDate.getFullYear()
                      }
                      ${calendarEvent.allDay
                        ? ''
                        : `${startDate.getHours().toString(10).padStart(2, "0")}:${
                        startDate.getMinutes().toString(10).padStart(2, "0")}`
                      }`
                    }
                  </span>
                </p>
              )}
              {endDate && (
                <p>
                  <span className={styles.detailLabel}>{t('PostLoadTemplatePopup.CalendarTo')}</span> <span>
                    {
                      `${
                        endDate.getDate().toString(10).padStart(2, "0")
                      }.${
                        (endDate.getMonth() + 1).toString(10).padStart(2, "0")
                      }.${
                        endDate.getFullYear()
                      }
                      ${calendarEvent.allDay
                        ? ''
                        : `${endDate.getHours().toString(10).padStart(2, "0")}:${
                          endDate.getMinutes().toString(10).padStart(2, "0")}`
                      }`
                    }
                  </span>
                </p>
              )}
            </div>
          ) : (
            <div className={styles.templateDetails}>
              <p>
                <span className={styles.detailLabel}>{t('PostLoadTemplatePopup.PostTextLabel')}</span> <span
                  dangerouslySetInnerHTML={{ __html: sanitize(template.post.text) }} />
              </p>
            </div>
          )}

          <Button
            onClick={handleApply}
          >
            {t('PostLoadTemplatePopup.Apply')}
          </Button>
        </div>
      )}
    </div>
  );
}
