import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getEmployeeIds } from 'library/api/kita';
import { isAwoWW } from 'library/api/tenantConfig';
import { getGroupMembersWithoutFilterSorting } from 'library/common/commonActions/groupActions';
import { openNewChatWithRecipient } from 'library/common/commonActions/chatActions';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import { isPublicUser } from 'library/utilities/user';
import store from 'main/store/configureStore';

export default function GroupInfoPopup(
  {
    isPopupOpen,
    setIsPopupOpen,
    activeKita,
    groupInfo,
    user
  }) {
  const { t } = useTranslation();

  const [groupAdmins, setGroupAdmins] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    if (!groupInfo || !isPopupOpen) {
      return;
    }

    getGroupMembersWithoutFilterSorting(groupInfo.groupId).then(res => {
      const resultAdmins = res.data.content
        .filter(r => r.groupRole && r.groupRole.role && r.groupRole.role.adminRole)
        .map(r => r.user);

      getEmployeeIds(activeKita.kitaId).then(employeeIdRes => {
        const employeeIds = employeeIdRes.data;

        const resultEmployees = res.data.content
          .filter(r => r.user && employeeIds.includes(r.user.id))
          .map(r => r.user);

        setGroupAdmins(resultAdmins);
        setEmployees(resultEmployees);
      });
    });
  }, [groupInfo, isPopupOpen]);

  const handleSendMessage = employee => {
    setIsPopupOpen(false);
    store.dispatch(openNewChatWithRecipient(employee));
  };

  return (
    <Popup
      isOpened={isPopupOpen}
      closePopup={() => setIsPopupOpen(false)}
      header={t('GroupInfoPopup.Header')}
      body={
        <div>
          {(!isAwoWW() || !groupInfo.globalPublicGroup) ? (
            <>
              <h1>{activeKita.kitaName}</h1>
              <div style={{ marginBottom: '2em' }}>
                <h2>{t('GroupInfoPopup.AddressHeader')}</h2>
                <p>
                  {activeKita.streetName} {activeKita.houseNo}
                </p>
                <p>{activeKita.zipCode}</p>
                <p>{activeKita.city}</p>
                <p>{activeKita.kitaChiefName}</p>
                <p>{activeKita.phoneNo}</p>
              </div>
            </>
          ) : (
            <div>
              <p>Arbeiterwohlfahrt Bezirk Westliches Westfalen e.V.</p>
              <p>Kronenstraße 63-69</p>
              <p>44139 Dortmund</p>
              <p>Telefon: 0231 5483-0</p>
              <p>E-Mail: kommunikation@awo-ww.de</p>
            </div>
          )}
          {
            (!isAwoWW() || (!isPublicUser(user) && !groupInfo.globalPublicGroup)) && (
              <div>
                <h2>{t('GroupInfoPopup.EmployeesHeader')}</h2>
                <table>
                  <tr>
                    <th style={{ width: '70%' }}>{t('GroupInfoPopup.AdminsTableNameHeader')}</th>
                    <th style={{ width: '30%' }}>{t('GroupInfoPopup.SendMessage')}</th>
                  </tr>
                  {employees.map(e => {
                    return (
                      <tr>
                        <td>
                          {e.firstName} {e.lastName}
                        </td>
                        <td>
                          <Button onClick={() => handleSendMessage(e)}>
                            <i className='fa fa-envelope' />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            )
          }
        </div>
      }
      footer={
        <div>
          <Button
            onClick={() => {
              setIsPopupOpen(false);
            }}
          >
            {t('Confirm.Close')}
          </Button>
        </div>
      }
    />
  );
}
