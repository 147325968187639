import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  deleteGfzForms,
  getGfzFormsOverview,
  getGfzFormsOverviewForCurrentUser,
} from 'library/api/observationSheet';
import Loader from 'library/common/commonComponents/Loader';
import GfzDocumentationRow from './GfzDocumentationRow';
import Button from 'library/common/commonComponents/Buttons/Button';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import styles from './gfzDocumentationOverview.module.scss';

export default function GfzDocumentationOverview({
  showAll,
  user,
  kitaName,
  setActiveTab,
  setKidId,
  setFormId,
}) {
  const [documentationOverviewData, setDocumentationOverviewData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedItems, setSelectedItems] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (showAll !== undefined) {
      const getGfzFormsOverviewFunction = showAll
        ? getGfzFormsOverview
        : getGfzFormsOverviewForCurrentUser;

      getGfzFormsOverviewFunction()
        .then((data) => {
          const formsOverview = data.data;
          formsOverview.sort((a, b) => {
            return (
              new Date(
                b.date.split('.')[2],
                b.date.split('.')[1] - 1,
                b.date.split('.')[0]
              ) -
              new Date(
                a.date.split('.')[2],
                a.date.split('.')[1] - 1,
                a.date.split('.')[0]
              )
            );
          });
          setDocumentationOverviewData(formsOverview);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [showAll, refresh]);

  const handleCheckboxChange = (rowData, isChecked) => {
    if (isChecked) {
      setSelectedItems((prevSelected) => [...prevSelected, rowData]);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.filter(
          (item) => item.formId !== rowData.formId
        )
      );
    }
  };

  const handleDeleteSelected = () => {

    const payload = selectedItems.map((item) => ({
      formId: item.formId,
      formsType: item.formsType,
    }));

    deleteGfzForms(payload).then((res) => {
      setRefresh(!refresh);
      setSelectedItems([]);

      store.dispatch(
        showBottomNotification(t('Gfz Forms.DeleteSuccess'), { isFail: false }),
      );
    })
  };

  return !isLoading ? (
    <div style={{ overflowY: 'auto' }}>
      <div className={styles.formHeader}>
        <h2>{t('GFZForms.Forms overview')}</h2>

        {selectedItems.length > 0 && (
          <Button onClick={handleDeleteSelected} type="primary">
            {t('Gfz Forms.Delete Selected')}
          </Button>
        )}
      </div>

      <table>
        <thead>
          <tr>
            {user.administrationAccess && <th style={{ minWidth: '40px' }}></th>}

            <th style={{ minWidth: '90px' }}>
              {t('GfzDocumentation.Last change')}
            </th>
            <th style={{ minWidth: '250px' }}>
              {t('GfzDocumentation.Form')}
            </th>
            <th style={{ minWidth: '90px' }}>
              {t('GfzDocumentation.Kid')}
            </th>
            <th style={{ minWidth: '90px' }}>
              {t('GfzDocumentation.KitaSignStatus')}
            </th>
            <th style={{ minWidth: '90px' }}>
              {t('GfzDocumentation.ParentSignStatus')}
            </th>
          </tr>
        </thead>
        <tbody>
          {documentationOverviewData.map((rowData) => {
            const isSelected = selectedItems.some(
              (item) => item.formId === rowData.formId
            );

            return (
              <GfzDocumentationRow
                key={rowData.formId}
                rowData={rowData}
                user={user}
                setActiveTab={setActiveTab}
                setKidId={setKidId}
                setFormId={setFormId}
                isSelected={isSelected}
                onCheckboxChange={handleCheckboxChange}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  ) : (
    <Loader />
  );
}
