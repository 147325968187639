import React, { useState } from 'react';
import cn from 'classnames';
import DOMPurify, { sanitize } from 'dompurify';
import { useTranslation } from 'react-i18next';

import Button from 'library/common/commonComponents/Buttons/Button';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';

import styles from './messageWithFilesText.module.scss';

DOMPurify.setConfig({ ADD_ATTR: ['target'] });

export default function MessageWithFilesText({
  text,
  entityId,
  entityType,
  isTranslationAllowed,
  messageEdited,
}) {
  const [showFullText, setShowFullText] = useState(false);

  const { t } = useTranslation();

  const shorteningLength = 500;

  function truncateHtmlContentPreservingStructure(htmlString, maxLength = 100) {
    // Create a temporary DOM element to parse the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    let totalLength = 0;
    let truncated = false;

    // Recursive function to process nodes while preserving the structure
    function processNode(node) {
      if (totalLength >= maxLength || truncated) return null;

      if (node.nodeType === Node.TEXT_NODE) {
        // Handle text nodes by truncating if necessary
        const text = node.textContent;
        if (totalLength + text.length > maxLength) {
          const truncatedText = text.slice(0, maxLength - totalLength) + ' ...';
          totalLength = maxLength;
          truncated = true;
          return document.createTextNode(truncatedText);
        } else {
          totalLength += text.length;
          return node.cloneNode(true);
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        // Handle element nodes by recursively processing child nodes
        const clone = node.cloneNode(false); // Shallow clone to preserve tag structure
        for (const child of node.childNodes) {
          if (totalLength >= maxLength || truncated) break;
          const processedChild = processNode(child);
          if (processedChild) {
            clone.appendChild(processedChild);
          }
        }
        return clone;
      }

      return null; // Ignore other node types
    }

    // Process the child nodes of the top-level element
    const resultDiv = document.createElement('div');
    for (const child of tempDiv.childNodes) {
      if (totalLength >= maxLength || truncated) break;
      const processedNode = processNode(child);
      if (processedNode) {
        resultDiv.appendChild(processedNode);
      }
    }

    return resultDiv.innerHTML;
  }

  const getTextFromHTML = html => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };
  const countTextInHTML = html => getTextFromHTML(html).length;
  const getShortened = html => {
    return shouldBeShortened(html)
      ? truncateHtmlContentPreservingStructure(html, shorteningLength)
      : html;
  };
  const shouldBeShortened = html => {
    return countTextInHTML(html) > shorteningLength;
  };
  const getText = input => {
    return showFullText ? input : getShortened(input);
  };

  return (
    <div className={styles.textContainer}>
      <div className={cn(styles.textContainerInner)}>
        {isTranslationAllowed ? (
          <WithWatsonTranslate
            data={{ text: getText(text), entityId, entityType, forced: messageEdited }}
            Component={({ html }) => (
              <div className={styles.text}>
                <p dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
              </div>
            )}
          />
        ) : (
          <p dangerouslySetInnerHTML={{ __html: sanitize(getText(text)) }} />
        )}
        {shouldBeShortened(text) && (
          <Button
            size='sm'
            className={styles.textContainerAlignedButton}
            onClick={() => setShowFullText(!showFullText)}
          >
            {showFullText ? t('Post.Hide Complete Post') : t('Post.Show Complete Post')}
          </Button>
        )}
      </div>
    </div>
  );
}
