import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { slideUp, slideDown, slideStop } from 'slide-anim';
import { useTranslation } from 'react-i18next';

import { isAwoWW } from 'library/api/tenantConfig';
import Storage from 'library/utilities/storage';
import TagInput from 'library/common/commonComponents/Inputs/TagInput';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';
import RadioButton from 'library/common/commonComponents/RadioButton';
import { getIsGroupAdmin } from 'library/api/groups';

import styles from './feedFilter.module.scss';
import FeedFilterCheckboxItem from 'library/common/commonComponents/FeedFilterCheckboxItem';
import { isPublicUser } from 'library/utilities/user';
import store from 'main/store/configureStore';

export const defaultContentFilters = [false, false, false, false];
export const defaultFilesFilters = [true, true, true];
export const defaultVisibilityFilters = [false, false, false, false];
export const defaultSortingFilters = [true, false];
export const defaultScheduledFilters = 'YES';
export const defaultGroups = { users: [] };

export default function FeedFilter({
  updateFilters,
  onFiltersLoad,
  showVisibilityFilters,
  showScheduledFilter = true,
  userId,
  group,
  showAuthorContentFilters,
  postType,
  filterFavorite,
  setFilterFavorite,
  kitaFilterActivated,
}) {
  const [isOpened, setIsOpened] = useState(false);
  const filterOptionsRef = useRef(null);

  const toggleOptions = () => toggleOptionsHandler({ isOpened, setIsOpened, filterOptionsRef });

  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const [groups, setGroups] = useState(defaultGroups);
  const [errors, setErrors] = useState({ invite: '' });

  const [contentFilters, setContentFilters] = useState(defaultContentFilters);
  const [filesFilters, setFilesFilters] = useState(defaultFilesFilters);
  const [visibilityFilters, setVisibilityFilters] = useState(defaultVisibilityFilters);
  const [sortingFilters, setSortingFilters] = useState(defaultSortingFilters);
  const [includeScheduled, setIncludeScheduled] = useState(defaultScheduledFilters);
  const [isResetButtonVisible, setIsResetButtonVisible] = useState(false);

  const [isGroupAdmin, setIsGroupAdmin] = useState(false);

  const user = store.getState().userReducer;

  const toggleContentFilters = index => {
    toggleFiltersHandler({ index, filters: contentFilters, setFilters: setContentFilters });
  };

  const toggleFilesFilters = index => {
    toggleFiltersHandler({ index, filters: filesFilters, setFilters: setFilesFilters });
    if (
      filesFilters.filter(filter => filter).length === 1 &&
      filesFilters.findIndex(filter => filter) === index
    ) {
      toggleContentFilters(2);
    }
  };

  const toggleFileAttachmentsFilters = () => {
    toggleFileAttachmentsFiltersHandler({ toggleContentFilters, setFilesFilters });
  };

  const toggleVisibilityFilters = index =>
    toggleFiltersHandler({ index, filters: visibilityFilters, setFilters: setVisibilityFilters });

  const resetFilters = () =>
    resetFiltersHandler({
      setContentFilters,
      setVisibilityFilters,
      setSortingFilters,
      setFilesFilters,
      setGroups,
      setIsResetButtonVisible,
    });

  useEffect(() => {
    getFiltersHandler({
      userId,
      group,
      onFiltersLoad,
      setContentFilters,
      setFilesFilters,
      setVisibilityFilters,
      setSortingFilters,
      setIncludeScheduled,
      setGroups,
      updateFilters,
      getFiltersFromStorageProp: getFiltersFromStorage,
    });
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    let newAppliedFiltersCount = 0;

    if (groups.users.length !== 0) {
      newAppliedFiltersCount++;
    }

    contentFilters.forEach(filter => filter && newAppliedFiltersCount++);
    visibilityFilters.forEach(filter => filter && newAppliedFiltersCount++);

    setAppliedFiltersCount(newAppliedFiltersCount);

    const payload = {
      contentFilters,
      filesFilters,
      visibilityFilters,
      sortingFilters,
      includeScheduled,
      groups,
    };

    saveFiltersToStorage(userId, group, payload);
    updateFilters(payload);

    if (newAppliedFiltersCount || sortingFilters[1]) {
      setIsResetButtonVisible(true);
    } else {
      setIsResetButtonVisible(false);
    }
    // eslint-disable-next-line
  }, [contentFilters, visibilityFilters, filesFilters, sortingFilters, groups, includeScheduled]);

  useEffect(() => {
    if (group?.groupId) {
      getIsGroupAdmin(group.groupId).then(res => {
        setIsGroupAdmin(res.data);
      });
    }
  }, []);

  const { t } = useTranslation();

  return (
    <div className={styles.filter}>
      <div className={styles.filterHead}>
        <div onClick={toggleOptions} className={styles.filterHeadTitleWrapper}>
          <i className='fa fa-filter' />
          <div className={styles.filterHeadTitle}>
            {t('Filters.Filter')}{' '}
            {appliedFiltersCount > 0 && (
              <span className={styles.filterHeadTitleCount}>({appliedFiltersCount})</span>
            )}
          </div>
          <i className='fa fa-caret-down' />
        </div>

        {filterFavorite != null && setFilterFavorite != null && (
          <div className={styles.filterHeadButton}>
            <FeedFilterCheckboxItem
              isChecked={filterFavorite}
              onClick={() => setFilterFavorite(!filterFavorite)}
              text={t('Filters.Favorite')}
            />
          </div>
        )}

        {isResetButtonVisible && (
          <div className={styles.filterHeadButtonWrapper}>
            <Button size='sm' type={isAwoWW() ? 'awoww' : 'info'} onClick={resetFilters}>
              {t('Filters.Reset')}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.filterOptions} ref={filterOptionsRef}>
        <div className={styles.filterOptionsInner}>
          <div className={styles.filterColumn}>
            <div className={styles.filterBlock}>
              <div className={styles.filterBlockTitle}>
                <strong>{t('Filters.Content')}</strong>
              </div>
              <div className={styles.filterList}>
                {showAuthorContentFilters && !(isAwoWW() && isPublicUser(user)) && (
                  <div className={styles.filterListItem}>
                    <Checkbox
                      isChecked={contentFilters[0]}
                      onChange={() => toggleContentFilters(0)}
                      name={t("Filters.Where I'm involved")}
                    />
                  </div>
                )}
                {showAuthorContentFilters && !(isAwoWW() && isPublicUser(user)) && (
                  <div className={styles.filterListItem}>
                    <Checkbox
                      isChecked={contentFilters[1]}
                      onChange={() => toggleContentFilters(1)}
                      name={t('Filters.Created by me')}
                    />
                  </div>
                )}
                <div className={styles.filterListItem}>
                  <Checkbox
                    isChecked={contentFilters[2]}
                    onChange={() => toggleFileAttachmentsFilters()}
                    name={t('Filters.With file attachments')}
                  />

                  {contentFilters[2] && (
                    <div className={styles.filterListItemSubtabs}>
                      <Checkbox
                        isChecked={filesFilters[0]}
                        onChange={() => toggleFilesFilters(0)}
                        name={t('Filters.Images')}
                      />
                      <Checkbox
                        isChecked={filesFilters[1]}
                        onChange={() => toggleFilesFilters(1)}
                        name={t('Filters.Videos')}
                      />
                      <Checkbox
                        isChecked={filesFilters[2]}
                        onChange={() => toggleFilesFilters(2)}
                        name={t('Filters.Others')}
                      />
                    </div>
                  )}
                </div>
                {showAuthorContentFilters && (
                  <div className={styles.filterListItem}>
                    <Checkbox
                      isChecked={contentFilters[3]}
                      onChange={() => toggleContentFilters(3)}
                      name={t('Filters.calendar')}
                    />
                  </div>
                )}
              </div>
            </div>

            {showVisibilityFilters && (
              <div className={styles.filterBlock}>
                <div className={styles.filterBlockTitle}>
                  <strong>{t('Filters.Visibility')}</strong>
                </div>
                <div className={styles.filterList}>
                  <div className={styles.filterListItem}>
                    <Checkbox
                      isChecked={visibilityFilters[0]}
                      onChange={() => toggleVisibilityFilters(0)}
                      name={t('Filters.Only public content')}
                    />
                  </div>
                  <div className={styles.filterListItem}>
                    <Checkbox
                      isChecked={visibilityFilters[1]}
                      onChange={() => toggleVisibilityFilters(1)}
                      name={t('Filters.Only private content')}
                    />
                  </div>
                  <div className={styles.filterListItem}>
                    <Checkbox
                      isChecked={visibilityFilters[2]}
                      onChange={() => toggleVisibilityFilters(2)}
                      name={t('Filters.OnlyHidePosts')}
                    />
                  </div>
                  {isGroupAdmin && (
                    <div className={styles.filterListItem}>
                      <Checkbox
                        isChecked={visibilityFilters[3]}
                        onChange={() => toggleVisibilityFilters(3)}
                        name={t('Filters.PendingComment')}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className={styles.filterColumn}>
            <div className={styles.filterBlock}>
              <div className={styles.filterBlockTitle}>
                <strong>{t('Filters.Sorting')}</strong>
              </div>
              <div className={styles.filterList}>
                <div className={styles.filterListItem}>
                  <RadioButton
                    className={styles.radioFilterItem}
                    checked={sortingFilters[0]}
                    onClick={() => setSortingFilters([true, false])}
                    label={t('Filters.Creation time')}
                  />
                </div>
                <div className={styles.filterListItem}>
                  <RadioButton
                    className={styles.radioFilterItem}
                    checked={sortingFilters[1]}
                    onClick={() => setSortingFilters([false, true])}
                    label={t('Filters.Last update')}
                  />
                </div>
              </div>
            </div>
            {showScheduledFilter && (
              <div className={styles.filterBlock}>
                <div className={styles.filterBlockTitle}>
                  <strong>{t('Filters.Scheduled')}</strong>
                </div>
                <div className={styles.filterList}>
                  <div className={styles.filterListItem}>
                    <RadioButton
                      className={styles.radioFilterItem}
                      checked={includeScheduled === 'YES'}
                      onClick={() => setIncludeScheduled('YES')}
                      label={t('Filters.YesScheduled')}
                    />
                  </div>
                  <div className={styles.filterListItem}>
                    <RadioButton
                      className={styles.radioFilterItem}
                      checked={includeScheduled === 'NO'}
                      onClick={() => setIncludeScheduled('NO')}
                      label={t('Filters.NoScheduled')}
                    />
                  </div>
                  <div className={styles.filterListItem}>
                    <RadioButton
                      className={styles.radioFilterItem}
                      checked={includeScheduled === 'SCHEDULEDONLY'}
                      onClick={() => setIncludeScheduled('SCHEDULEDONLY')}
                      label={t('Filters.OnlyScheduled')}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {window.location.pathname === '/dashboard' && (
            <div className={styles.filterColumn}>
              <div className={styles.filterBlock}>
                <div className={styles.filterBlockTitle}>
                  <strong>{t('Filters.Groups')}</strong>
                </div>
                <TagInput
                  value={groups}
                  onDataChange={setGroups}
                  setErrors={setErrors}
                  errors={errors}
                  showLabel={false}
                  isFullWidth
                  isSmall
                  target='groups'
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {kitaFilterActivated && (
        <>
          <h2 className={styles.kitaFilter}>{t('Filters.KitaFilterActivated')}</h2>
          <div className={styles.filterWrapper}>
            <Link to={`/dashboard-overview?publicUserAWO=${isPublicUser(user)}`}>
              <Button size={'sm'} type={isAwoWW() ? 'awoww' : 'info'}>
                {t('Header.Alle AWO-Gruppen entdecken')}
              </Button>
            </Link>
          </div>
        </>
      )}
    </div>
  );
}

export function getFiltersHandler({
  userId,
  group,
  onFiltersLoad,
  setContentFilters,
  setFilesFilters,
  setVisibilityFilters,
  setSortingFilters,
  setIncludeScheduled,
  setGroups,
  updateFilters,
  getFiltersFromStorageProp,
}) {
  if (!userId) return;
  const filters = getFiltersFromStorageProp(userId, group);
  if (!filters) {
    onFiltersLoad();
    return;
  }

  if (filters.contentFilters.length !== defaultContentFilters.length) {
    setContentFilters(defaultContentFilters);
  } else {
    setContentFilters(filters.contentFilters);
  }
  if (filters.visibilityFilters.length === 2) {
    filters.visibilityFilters.push(false);
    filters.visibilityFilters.push(false);
  }
  if (filters.visibilityFilters.length === 3) {
    filters.visibilityFilters.push(false);
  }

  setFilesFilters(filters.filesFilters);
  setVisibilityFilters(filters.visibilityFilters);
  setSortingFilters(filters.sortingFilters);
  setIncludeScheduled(filters.includeScheduled);
  setGroups(filters.groups);
  updateFilters(filters);
  onFiltersLoad(filters);
}

export function toggleOptionsHandler({ isOpened, setIsOpened, filterOptionsRef }) {
  const duration = 700;

  setIsOpened(!isOpened);
  slideStop(filterOptionsRef.current);
  if (isOpened) {
    slideUp(filterOptionsRef.current, {
      duration,
    });
  } else {
    slideDown(filterOptionsRef.current, {
      duration,
    });
  }
}

export function toggleFiltersHandler({ index, filters, setFilters }) {
  setFilters(filters.map((filter, filterIndex) => (filterIndex === index ? !filter : filter)));
}

export function toggleFileAttachmentsFiltersHandler({ toggleContentFilters, setFilesFilters }) {
  toggleContentFilters(2);
  setFilesFilters(defaultFilesFilters);
}

export function resetFiltersHandler({
  setContentFilters,
  setFilesFilters,
  setVisibilityFilters,
  setSortingFilters,
  setIncludeScheduled,
  setGroups,
  setIsResetButtonVisible,
}) {
  setContentFilters(defaultContentFilters);
  setFilesFilters(defaultFilesFilters);
  setVisibilityFilters(defaultVisibilityFilters);
  setSortingFilters(defaultSortingFilters);
  setIncludeScheduled(defaultScheduledFilters);
  setGroups(defaultGroups);
  setIsResetButtonVisible(false);
}

export function saveFiltersToStorage(userId, group, value) {
  if (!userId) return;
  Storage.setItem(`filters/${userId}/${group ? group.groupId : 'dashboard'}`, value);
}

export function getFiltersFromStorage(userId, group) {
  return Storage.getItem(`filters/${userId}/${group ? group.groupId : 'dashboard'}`);
}
