import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import useEditor from 'library/common/commonHooks/useEditor';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import { checkLink } from 'library/utilities/checkLink';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import useMultipleUpload from 'library/common/commonHooks/useMultipleUpload';
import FilePrewiev from 'library/common/commonComponents/FilePrewiev';
import { getUploadFilesHandler } from 'library/utilities/getUploadFilesHandler';
import { getGroupMembersWithoutFilterSorting } from 'library/common/commonActions/groupActions';
import GroupsTagInput from 'library/common/commonComponents/Inputs/TagInput/GroupsTagInput';
import SelectTagInput from 'library/common/commonComponents/Inputs/TagInput/SelectTagInput';
import RecordButton from 'library/common/commonComponents/Buttons/RecordButton/RecordButtonContainer';
import { handleRNMessageHelper } from "library/utilities/handleRNMessageHelper";
import { VOICE_MESSAGE_AVAILABLE_APPS } from 'main/config';
import styles from './createNewChat.module.scss';

export default function CreateNewChat({
  isOpened,
  onSubmit,
  onClose,
  user,
  showBottomNotification,
  createNewChatRecipientsPreset,
  setCreateNewChatRecipientsPreset,
  shouldCheckBlockUsersFromMessaging = true
}) {
  const { t } = useTranslation();

  const [newChatInfo, setNewChatInfo] = useState({
    groups: [],
    groupNames: [],
    users: [],
    subject: '',
    message: '',
  });
  const [error, setError] = useState({
    users: false,
    subject: false,
    message: false,
  });
  const isSbMasterclass = window.location.hostname.toLowerCase().includes('sbmasterclass.safe2connect.org');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { files, uploadFile, deleteFiles, setFiles } = useMultipleUpload(null);
  const [isVoiceMessageAvailable, setIsVoiceMessageAvailable] = useState(false);

  const [textareaMarginTop, setTextareaMarginTop] = useState(0);
  const uploadFilesHandler = getUploadFilesHandler({
    chatId: 0,
    userId: user.id,
    uploadFile,
    showBottomNotification,
    t,
  });
  const [progress, setProgress] = useState(null);
  const [popupAddGroupWaringIsOpen, setPopupAddGroupWaringIsOpen] = useState(false);

  let timeout;

  const { Editor, getEditorValue } = useEditor({
    message: newChatInfo.message,
    placeholder: t('Chat.Write a message'),
    onOpen: height => {
      if (timeout) clearTimeout(timeout);
      setTextareaMarginTop(height + 10);
    },
    onClose: () => {
      timeout = setTimeout(() => {
        setTextareaMarginTop(0);
      }, 400);
    },
    onChange: () => {
      const value = getEditorValue();
      setError(prevError => ({ ...prevError, message: false }));
      setNewChatInfo(prevChatInfo => ({ ...prevChatInfo, message: checkLink(value) }));
    },
    className: styles.textareaContent,
    isChat: true,
  });

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }
    let isError = false;
    let newErrors = {
      users: false,
      subject: false,
      message: false,
    };

    if (!newChatInfo.users.length && !newChatInfo.groups.length) {
      newErrors = { ...newErrors, users: 'empty' };
      isError = true;
    }
    if (newChatInfo.users.find(item => `${item.id}` === `${user.id}`)) {
      newErrors = { ...newErrors, users: 'isUser' };
      isError = true;
    }
    if (newChatInfo.subject.trim() === '') {
      newErrors = { ...newErrors, subject: true };
      isError = true;
    }

    if (newChatInfo.message.trim() === '' && !files.length) {
      newErrors = { ...newErrors, message: true };
      isError = true;
    }
    if (files.length && newChatInfo.message.trim() === '') {
      newChatInfo.message = '&nbsp;';
    }

    if (isError) {
      return setError(newErrors);
    }
    setIsSubmitting(true);
    const shouldReloadPage = window.location.pathname.includes('/chat/');
    try {
      await onSubmit(newChatInfo, files, setProgress);
      setFiles([]);
      handleClose();
      if (shouldReloadPage) {
        window.location.reload();
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setProgress(null);
      setIsSubmitting(false);
      if (typeof setCreateNewChatRecipientsPreset === 'function') {
        setCreateNewChatRecipientsPreset(null);
      }
    }
  };

  const handleAddUser = data => {
    setError(prevError => ({ ...prevError, users: false }));
    setNewChatInfo(prevChatInfo => ({ ...prevChatInfo, users: data.users }));
  };

  const getSubjectWithGroupNames = (oldChatInfo, groupNames) => {
    const subjectWithoutExtras =
      oldChatInfo.groupNames.length > 0
        ? oldChatInfo.subject
          .split(' - ')
          .slice(1)
          .join(' - ')
        : oldChatInfo.subject;
    const extras =
      `Nachricht an Gruppe${groupNames.length > 1 ? 'n' : ''} ` + groupNames.join(', ') + ' - ';
    return (groupNames.length > 0 ? extras : '') + subjectWithoutExtras;
  };

  const handleGroupsUpdate = data => {
    const deletedGroups = newChatInfo.groups.filter(group => !data.groups.includes(group));
    const indexesesToDelete = deletedGroups.map(group => newChatInfo.groups.indexOf(group));
    const groupNamesList = newChatInfo.groupNames.filter(
      (_, index) => !indexesesToDelete.includes(index),
    );

    setNewChatInfo(prevChatInfo => ({
      ...prevChatInfo,
      groups: data.groups,
      groupNames: groupNamesList,
      subject: getSubjectWithGroupNames(prevChatInfo, groupNamesList),
    }));
  };

  const handleAddGroup = data => {
    setPopupAddGroupWaringIsOpen(true);
    setError(prevError => ({ ...prevError, users: false }));

    data.groups.forEach(groupItem => {
      if (!groupItem.id) return;
      getGroupMembersWithoutFilterSorting(groupItem.id).then(res => {
        const tempUserChatInfo = [...newChatInfo.users];
        if (newChatInfo.users.length > 0) {
          res.data.content.forEach(resUserItem => {
            const newChatInfoUserIds = newChatInfo.users.map(({ id }) => id);
            const newChatInfoGroups = newChatInfo.groups;
            newChatInfoGroups.forEach(group => {
              group.forEach(userItem => {
                newChatInfoUserIds.push(userItem.id);
              });
            });
            if (!newChatInfoUserIds.includes(resUserItem.user.id.toString())) {
              if (resUserItem.user.id !== user.id) {
                resUserItem.user.id = resUserItem.user.id.toString();
                tempUserChatInfo.push(resUserItem.user);
              }
            }
          });
        } else {
          if (!user.administrationAccess && !user.superAdminStatus) {
            res.data.content = res.data.content.filter(
              item => item.user.blockUsersFromMessaging == false,
            );
          }
          res.data.content.forEach(resUserItem => {
            if (resUserItem.user.id !== user.id) {
              resUserItem.user.id = resUserItem.user.id.toString();
              tempUserChatInfo.push(resUserItem.user);
            }
          });
        }
        const updatedGroups = [...newChatInfo.groups, tempUserChatInfo];

        const groupNamesList = [
          ...newChatInfo.groupNames,
          data.groups[data.groups.length - 1].groupName,
        ];

        setNewChatInfo(prevState => ({
          ...prevState,
          subject: getSubjectWithGroupNames(prevState, groupNamesList),
          groupNames: groupNamesList,
          groups: updatedGroups,
        }));
      });
    });
  };

  const handleSubjectChange = e => {
    const { value } = e.target;
    setError(prevError => ({ ...prevError, subject: false }));
    setNewChatInfo(prevChatInfo => ({ ...prevChatInfo, subject: value }));
  };

  const handleClose = () => {
    setError({
      users: false,
      subject: false,
      message: false,
    });
    setNewChatInfo({
      users: [],
      groups: [],
      groupNames: [],
      subject: '',
      message: '',
    });
    if (typeof setCreateNewChatRecipientsPreset === 'function') {
      setCreateNewChatRecipientsPreset(null);
    }

    onClose();
  };

  useEffect(() => {
    setNewChatInfo(state => {
      // ensure each user's id is a string not a number (javascript is a great language)
      const updatedCreateNewChatRecipientsPreset = (createNewChatRecipientsPreset || []).map(user => {
        return {
          ...user,
          id: "" + user.id
        }
      });
      return {
        ...state,
        users: updatedCreateNewChatRecipientsPreset,
      };
    });
  }, [createNewChatRecipientsPreset]);

  const checkVoiceMessageAvailability = (rnEvent) => {
    if (!rnEvent || !rnEvent.data) {
      return;
    }

    setIsVoiceMessageAvailable(user.administrationAccess || user.employee || VOICE_MESSAGE_AVAILABLE_APPS.includes(rnEvent.data) || isSbMasterclass);
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    if (!window.ReactNativeWebView) {
      setIsVoiceMessageAvailable(user.administrationAccess || user.employee || isSbMasterclass);
      return;
    }

    [window, document].forEach(el => {
      el.addEventListener('message', handleRNMessageHelper('native.projectId', checkVoiceMessageAvailability));
    })

    return () => {
      [window, document].forEach(el => {
        el.removeEventListener('message', handleRNMessageHelper('native.projectId', checkVoiceMessageAvailability));
      });
    }
  }, [window.ReactNativeWebView, user]);

  return (
    <Popup
      isOpened={isOpened}
      closePopup={handleClose}
      size='small'
      header={t('Chat.New message')}
      footer={
        <div className={styles.buttonsContainer}>
          <div className={styles.buttonCancel}>
            <Button onClick={handleClose}>{t('Chat.Cancel')}</Button>
          </div>
          <div className={styles.buttonSend}>
            <ButtonWithLoader
              onClick={handleSubmit}
              className={styles.buttonWithLoader}
              isLoading={isSubmitting}
            >
              {t('Chat.Send')}
            </ButtonWithLoader>
          </div>
        </div>
      }
    >
      <div className={styles.container}>
        <Popup
          size='small'
          isOpened={popupAddGroupWaringIsOpen}
          closePopup={() => {
            setPopupAddGroupWaringIsOpen(false);
          }}
          header={<strong>{t('Chat.Warning')}</strong>}
          body={t('Chat.You selected a group')}
          footer={
            <div className={styles.buttonsContainer}>
              <div className={styles.buttonCancel}>
                <Button
                  onClick={() => {
                    setPopupAddGroupWaringIsOpen(false);
                  }}
                >
                  {t('Chat.Ok')}
                </Button>
              </div>
            </div>
          }
        />

        {/*
        <div className={styles.usersInput}>
          <Label type='input'>
            <Trans i18nKey='Chat.Recipient' />
          </Label>
          <TagInput
            value={newChatInfo}
            onDataChange={handleAddUser}
            errors={
              error.users
                ? {
                  invite:
                    error.users === 'empty'
                      ? t('Chat.Recipient cannot be blank')
                      : t('Chat.You cannot send a message to yourself'),
                }
                : { invite: '' }
            }
            showLabel={false}
            userId={user.id}
          />
        </div>
        */}
        <div className={styles.usersInput}>
          <Label type='input'>
            <Trans i18nKey='Chat.Recipient' />
          </Label>
          <SelectTagInput
            value={newChatInfo}
            onDataChange={handleAddUser}
            onGroupChange={handleGroupsUpdate}
            errors={
              error.users
                ? {
                  invite:
                    error.users === 'empty'
                      ? t('Chat.Recipient cannot be blank')
                      : t('Chat.You cannot send a message to yourself'),
                }
                : { invite: '' }
            }
            showLabel={false}
            userId={user.id}
            user={user}
            shouldCheckBlockUsersFromMessaging={shouldCheckBlockUsersFromMessaging}
          />
        </div>
        <div className={styles.usersInput}>
          <Label type='input'>{t('Chat.Recipient Groups')}</Label>
          <GroupsTagInput
            value={newChatInfo}
            onDataChange={handleAddGroup}
            target='groups'
            errors={{ invite: '' }}
            showLabel={false}
            userId={user.id}
          />
        </div>
        <div className={styles.chatNameContainer}>
          <Label type='input'>
            <Trans i18nKey='Chat.Subject' />
          </Label>
          <Input
            type='text'
            error={error.subject}
            value={newChatInfo.subject}
            onChange={handleSubjectChange}
          />
          {error.subject && (
            <div className={styles.error_msg}>
              <InputErrorMsg errorMsg={t('Chat.Subject cannot be blank')} />
            </div>
          )}
        </div>
        <div
          className={cn(styles.messageContainer, error.message && styles.messageContainerError)}
          style={{ marginTop: textareaMarginTop }}
        >
          <Editor />
          {error.message && (
            <div className={styles.error_msg}>
              <InputErrorMsg errorMsg={t('Chat.Message cannot be blank')} />
            </div>
          )}
        </div>

        <div className={styles.fileButtonWrapper}>
          <UploadButton
            className={styles.uploadButton}
            onChange={uploadFilesHandler}
            isMultiple
            files={files}
            maxFileSize={200}
          />

          {
            isVoiceMessageAvailable && (
              <div className={styles.voiceButton}>
                <RecordButton
                  uploadFile={uploadFilesHandler}
                  voiceForPost={true}
                />
              </div>
            )
          }
        </div>

        <div className={styles.filePreviewContainer}>
          {progress && files.length > 0 && (
            <div className={styles.progressBar}>
              <div className={styles.progressBarValue} style={{ width: progress + '%' }} />
            </div>
          )}
          <FilePrewiev newFiles={files} onDelete={deleteFiles} />
        </div>
      </div>
    </Popup>
  );
}
