import axios from 'library/api/axios';
import config, { getPort } from 'main/config';

const URL = `${config.API_BASE_URI}${getPort(8091)}/api/tableeditor`;

export const getTables = () => {
  return axios.get(`${URL}/list`);
};

export const saveTable = table => {
  return axios.post(`${URL}/save`, table);
};

export const deleteTable = id => {
  return axios.delete(`${URL}/delete/${id}`);
};
