import React from 'react';
import axios from 'library/api/axios';
import config, { getPort, isDev } from 'main/config';

export const URL = `${config.API_BASE_URI}${getPort(8091)}/api`;

export const uploadImagesToArticle = (id, images, kitaId, description, location) => {
    const formData = new FormData();
    if (images) {
        images.forEach(image => formData.append('images', image));
    }
    formData.append('description', description);
    formData.append('location', location);
    formData.append('articleId', id);

    return axios
        .post(`${URL}/saveArticle/${kitaId}`, formData);
};

export const getArticle = (kitaId, articleId) => {
    return axios
        .get(`${URL}/getArticle/${kitaId}/${articleId}`);
}

export const deleteArticleImage = (imageId) => {
    return axios.delete(`${URL}/deleteArticle/${imageId}`)
}

export const updateArticle = (articleId, description, images, location) => {
    const formData = new FormData();
    formData.append('descriptionText', description);
    formData.append('location', location);
    if (images?.length > 0) {
        images.forEach(image => formData.append('images', image));
    } else {
        formData.append('images', images)
    }
    return axios.put(`${URL}/updateArticle/${articleId}`, formData);
}

export const deleteArticle = (articleId) => {
    return axios.delete(`${URL}/deleteArticleTotal/${articleId}`)
}

export const saveThirdArticle = (kitaId, articleId, payload) => {
    return axios.post(`${URL}/saveThirdArticle/${kitaId}/${articleId}`, payload)
}

export const getThirdArticle = (kitaId, articleId) => {
    return axios
        .get(`${URL}/getThirdArticle/${kitaId}/${articleId}`);
}

export const updateThirdArticle = (kitaId, articleId, payload) => {
    return axios.put(`${URL}/updateThirdArticle/${kitaId}/${articleId}`, payload)
}