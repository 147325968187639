import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styles from './usersFrameUserGroupsPopup.module.scss';
import Popup from '../../../../../../../../../library/common/commonComponents/Popups/Popup';
import Button from '../../../../../../../../../library/common/commonComponents/Buttons/Button';
import { getGroupsByUserIdAndKitaId, updateGroupUserRole } from '../../../../../../../../../library/api/user';
import Loader from '../../../../../../../../../library/common/commonComponents/Loader';
import ListItemWithLogoAvatar from '../../../../../../../../../library/common/commonComponents/ListItems/ListItemWithLogo/listItemWithLogoFrames/ListItemWithLogoElement/listItemWithLogoElementFrames/ListItemWithLogoAvatar';
import { getLetterInLogo } from '../../../../../../../../../library/utilities/groups';
import { isAwoWW } from 'library/api/tenantConfig';
import Select from 'library/common/commonComponents/Inputs/Select';
import ButtonWithLoader from "library/common/commonComponents/Buttons/ButtonWithLoader";
import Label from 'library/common/commonComponents/Label';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';

export default function UsersFrameUserGroupsPopup({ user, selectedKita }) {
  const { t } = useTranslation();
  const isAWO = isAwoWW();
  const roleOptions = [
    { value: '1', adminRole: true, label: t('GroupPanel.Administrators') },
    { value: '2', adminRole: false, label: t('GroupPanel.Members') },
  ];

  const userRoleOptions = [
    { label: t('Administration.UserSection.Kita user'), value: false },
    { label: t('Administration.UserSection.Kita admin'), value: true },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [groups, setGroups] = useState([]);
  const [awoGroups, setAWOGroups] = useState({});
  const [originalAwoGroups, setOriginalAwoGroups] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const popupTitle = isAWO ? `${user.user.firstName} ${user.user.lastName}` : t('UsersFrame.User.Groups');
  const closePopup = () => {
    setIsOpen(false);
  };

  const groupByKey = (array, key) => {
    return array.reduce((acc, item) => {
      const groupKey = item[key];
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(item);
      return acc;
    }, {});
  }

  const updateGroupRoleItem = (e, groupId) => {
    const updatedAwoGroups = { ...awoGroups };

    Object.keys(updatedAwoGroups).forEach(key => {
      updatedAwoGroups[key] = updatedAwoGroups[key].map(group =>
        group.id === groupId
          ? { ...group, isGroupAdmin: e.adminRole }
          : group
      );
    });
    setAWOGroups(updatedAwoGroups);
  }

  const updateUserRoleItem = (e, groupId) => {
    const updatedAwoGroups = { ...awoGroups };

    Object.keys(updatedAwoGroups).forEach(key => {
      updatedAwoGroups[key] = updatedAwoGroups[key].map(group =>
        group.id === groupId
          ? { ...group, userRole: e.value? 'Administrator': 'Members' }
          : group
      );
    });
    setAWOGroups(updatedAwoGroups);
  }

  const onSaveRole = async () => {
    try {
      setIsSaving(true);
      const payload = [];

      Object.keys(awoGroups).forEach(key => {
        awoGroups[key].forEach(group => {
          const originalGroup = originalAwoGroups[key]?.find(orig => orig.id === group.id);

          if (
            originalGroup &&
            (originalGroup.userRole !== group.userRole ||
             originalGroup.isGroupAdmin !== group.isGroupAdmin)
          ) {
            // Include only modified data
            payload.push({
              id: group.id,
              isGroupAdmin: group.isGroupAdmin,
              userRole: group.userRole,
              kitaId: group.kitaId,
            });
          }
        });
      });

      if (payload.length === 0) {
        store.dispatch(
          showBottomNotification(t('BottomNotification.NoChangesToSave'))
        );
        closePopup();
        return;
      }

      await updateGroupUserRole(user.user.id, payload);
      closePopup();
      store.dispatch(
        showBottomNotification(t('BottomNotification.SuccessfullySaved'))
      );
    } catch (error) {
      store.dispatch(
        showBottomNotification(t('BottomNotification.ErrorSavingTryAgain'), {
          isFail: true,
        })
      );
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      getGroupsByUserIdAndKitaId(user.user.id, selectedKita.value, isAWO).then(res => {
        // filter out groups depending on the current tenant
        const filteredGroups = window.location.hostname.toLowerCase().includes('stramplerbande')
          ? res.data
          : res.data.filter(group => group.groupName !== 'Public group');

        if (isAWO) {
          const newFilterGroups = filteredGroups.filter(x=>x.kitaName);
          const groupByGroup =  groupByKey(newFilterGroups, 'kitaName');
          setAWOGroups(groupByGroup);
          setOriginalAwoGroups(groupByGroup);
        }

        setGroups(filteredGroups);
        setIsLoading(false);
      });
    }
  }, [isOpen]);
  const redirect = groupId => {
    window.location.href = '/groups/' + groupId;
  };
  return (
    <div>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t('UsersFrame.User.Show groups')}
      </Button>
      <Popup
        isOpened={isOpen}
        closePopup={closePopup}
        size='small'
        header={<div> {popupTitle}</div>}
        footer={
          <div className={styles.footerWrapper}>
            {
              isAWO && (
                <ButtonWithLoader onClick={onSaveRole} type='primary' isLoading={isSaving}>
                  {t('Popup.Save')}
                </ButtonWithLoader>
              )
            }
            <Button type='secondary' onClick={() => closePopup()}>
              {t('CreateGroup.Back')}
            </Button>
          </div>
        }
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            {
              !isAWO && (
                <div className={styles.groupHint}>{t('UsersFrame.GroupsText')}:</div>
              )
            }
            {
              !isAWO ? (
                <div className={styles.groupContainer}>
                  {groups.map(group => (
                    <div className={styles.groupElement} onClick={() => redirect(group.id)}>
                      <ListItemWithLogoAvatar
                        groupInfo={{
                          logoUrl: group.groupLogoUrl,
                          groupLogoXCoordinate: group.groupLogoXCoordinate,
                          groupLogoYCoordinate: group.groupLogoYCoordinate,
                          groupLogoWidth: group.groupLogoWidth,
                          groupLogoAspect: group.groupLogoAspect,
                        }}
                        letter={getLetterInLogo(group.groupName)}
                        backgroundColor={group.colourCode}
                        className={styles.groupLogo}
                      />
                      <div className={styles.groupName}>{group.groupName}</div>
                    </div>
                  ))}
                </div>
              ): (
                <div className={styles.awoGroupContainer}>
                  {
                    Object.keys(awoGroups).map(key=> (
                      <div>
                        <div className={styles.kitaNameText}>{key}</div>
                        <div className={styles.groupItems}>
                          {awoGroups[key].map(group => (
                            <>
                            <div className={styles.groupElement} onClick={() => redirect(group.id)}>
                              <ListItemWithLogoAvatar
                                groupInfo={{
                                  logoUrl: group.groupLogoUrl,
                                  groupLogoXCoordinate: group.groupLogoXCoordinate,
                                  groupLogoYCoordinate: group.groupLogoYCoordinate,
                                  groupLogoWidth: group.groupLogoWidth,
                                  groupLogoAspect: group.groupLogoAspect,
                                }}
                                letter={getLetterInLogo(group.groupName)}
                                backgroundColor={group.colourCode}
                                className={styles.groupLogo}
                              />
                              <div className={styles.groupName}>{group.groupName}</div>
                            </div>
                            <div className={styles.groupNameWrapper}>
                              <div>
                                <Label type='input'>{t('GroupPanel.Role')}</Label>
                                <Select
                                  options={roleOptions}
                                  onSelect={e => updateGroupRoleItem(e, group.id)}
                                  selected={
                                    roleOptions.find(item => item.adminRole === group.isGroupAdmin)
                                  }
                                />
                              </div>
                              <div>
                                <Label type='input'>{t('Administration.UserSection.Role')}</Label>
                                <Select
                                  options={userRoleOptions}
                                  onSelect={e => updateUserRoleItem(e, group.id)}
                                  selected={
                                    userRoleOptions.find(item => item.value === (group.userRole === 'Administrator'))
                                  }
                                />
                              </div>
                            </div>
                            </>
                          ))}
                        </div>
                      </div>
                    ))
                  }
                </div>
              )
            }

          </div>
        )}
      </Popup>
    </div>
  );
}
