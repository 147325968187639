import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './gfzDocumentationRow.module.scss';
import Checkbox from 'library/common/commonComponents/Checkbox';

function tabTypeMapping(type) {
  if (type === 'addDays') {
    return 4;
  }
  if (type === 'contractChanges') {
    return 3;
  }
  if (type === 'kidLeavings') {
    return 2;
  }
  if (type === 'medicalAuthorizations') {
    return 1;
  }
  return 0;
}

function getIconByStatus(status) {
  if (status === true) {
    return (
      <div style={{ color: 'green' }}>
        <i className='fa fa-check' />
      </div>
    );
  } else {
    return (
      <div style={{ color: '#f5721b' }}>
        <i className='fa fa-spinner' />
      </div>
    );
  }
}

export default function GfzDocumentationRow({
  rowData,
  user,
  setActiveTab,
  setKidId,
  setFormId,
  isSelected,
  onCheckboxChange,
}) {
  const { t } = useTranslation();
  const [formValue, setFormValue] = useState(' ');
  const [childValue, setChildValue] = useState(0);

  useEffect(() => {
    if (window.location.search.substring != null && window.location.search.length > 0) {
      const queryString = window.location.search.substring(1);
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.get('form') === 'MedicalAuthorization' || urlParams.get('form') === 'KidLeaving') {
        setFormValue(urlParams.get('form') + 's');
      } else {
        setFormValue(urlParams.get('form'));
      }
      setChildValue(urlParams.get('child'));
    }
  }, []);

  const translateGfzFormType = (type) => {
    if (type === 'addDays') {
      return t('GfzDocumentation.AdditionalDays');
    }
    if (type === 'contractChanges') {
      return t('GfzDocumentation.ContractChanges');
    }
    if (type === 'kidLeavings') {
      return t('GfzDocumentation.KidLeaving');
    }
    if (type === 'medicalAuthorizations') {
      return t('GfzDocumentation.MedicalAuthorization');
    }
    return '';
  };

  const isQueryParamMatch =
    formValue.toLowerCase() === rowData.formsType.toLowerCase() &&
    Number(childValue) === rowData.kidId;
  const rowStyle = {
    cursor: 'pointer',
    backgroundColor: isQueryParamMatch ? '#adf294' : 'white',
  };

  const handleRowClick = () => {
    setFormId(rowData.formId);
    setKidId(rowData.kidId);
    setActiveTab(tabTypeMapping(rowData.formsType));
  };

  return (
    <tr style={rowStyle}>
      {user.administrationAccess && <td>
        <Checkbox
          isChecked={isSelected}
          onChange={(event) => {
            event.stopPropagation();
            onCheckboxChange(rowData, !isSelected);
          }}
        />
      </td>}

      <td onClick={handleRowClick}>{rowData.date}</td>
      <td onClick={handleRowClick}>{translateGfzFormType(rowData.formsType)}</td>
      <td onClick={handleRowClick}>{rowData.kidName}</td>
      <td onClick={handleRowClick}>{getIconByStatus(rowData.kitaSignStatus)}</td>
      <td onClick={handleRowClick}>{getIconByStatus(rowData.parentSignStatus)}</td>
    </tr>
  );
}
