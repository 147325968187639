import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './foodOrdersKitaWeeklyView.module.scss';
import Loader from '../../../../Loader';
import {
  generateKitaWeeklyFoodOrdersPDF,
  formatDate,
  getWeekNumber,
  getCurrentFrom,
  getPreviousMonday,
  getNextMonday,
  getSunday,
  getDateFromFormatedDate,
} from '../../../../../../utilities/foodOrder';
import { kitaGetOrdersWeekly } from '../../../../../../api/foodOrder';
import Button from '../../../../Buttons/Button';

export default function FoodOrdersKitaWeeklyView({ groupId, activeKita, groupInfo }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isWeekSelectionLoading, setIsWeekSelectionLoading] = useState(false);
  const [orderedMeals, setOrderedMeals] = useState([]);

  const downloadPDF = () => {
    generateKitaWeeklyFoodOrdersPDF(
      t,
      orderedMeals,
      formatDate(from),
      formatDate(getSunday(from)),
      activeKita.kitaName,
    );
  };

  const getInitialDetailsVisible = orders => {
    return orders.map(order => false);
  };
  const [isDetailsVisible, setIsDetailsVisible] = useState(getInitialDetailsVisible([]));

  useEffect(() => {
    setIsLoading(true);
    reloadData(from);
  }, [from]);

  const onDecreaseCW = () => {
    setFrom(getPreviousMonday(from));
    reloadData(getPreviousMonday(from));
  };

  const onIncreaseCW = () => {
    setFrom(getNextMonday(from));
    reloadData(getNextMonday(from));
  };

  const toggleDetails = (index, foodIndex) => {
    const newDetailsVisible = [...isDetailsVisible];
    newDetailsVisible[index] = !newDetailsVisible[index];
    setIsDetailsVisible(newDetailsVisible);
    orderedMeals[index]['food'][foodIndex]['chevron'] = !orderedMeals[index]['food'][foodIndex][
      'chevron'
    ];
  };

  const reloadData = from => {
    let orderedMealsWeekly = [];
    setIsLoading(true);

    kitaGetOrdersWeekly(groupId, formatDate(from), formatDate(getSunday(from))).then(res => {
      const { data } = res;
      if (data.orders) {
        data.orders.forEach(item => {
          if (item.date) {
            if (orderedMealsWeekly.filter(element => element.date === item.date).length > 0) {
              let dateData = orderedMealsWeekly.filter(element => element.date === item.date);
              let foodData = 0;
              if (item.food) {
                foodData = dateData[0].food.filter(element => element.foodID === item.food.id);
              } else {
                foodData = dateData[0].food.filter(element => element.foodID === 0);
              }
              if (foodData.length > 0) {
                foodData[0]['kids'].push({
                  name: item.childName,
                  group: item.kidGroupName,
                });
              } else {
                dateData[0].food.push({
                  foodID: item.food ? item.food.id : 0,
                  foodName: item.food ? item.food.name : t('FoodOrder.No name'),
                  kids: [
                    {
                      name: item.childName,
                      group: item.kidGroupName,
                    },
                  ],
                  chevron: false,
                });
              }
            } else {
              orderedMealsWeekly.push({
                date: item.date,
                food: [
                  {
                    foodID: item.food ? item.food.id : 0,
                    foodName: item.food ? item.food.name : t('FoodOrder.No name'),
                    kids: [
                      {
                        name: item.childName,
                        group: item.kidGroupName,
                      },
                    ],
                    chevron: false,
                  },
                ],
              });
            }
          }
        });
      }
      orderedMealsWeekly.forEach(item => {
        item.food.forEach(element => {
          element.kids.sort((a, b) => {
            // First, sort by groupname
            if (a.group !== null && b.group !== null) {
              if (a.group !== b.group) {
                return a.group.localeCompare(b.group);
              }

              // If groupname is the same, sort by kidName
              return a.name.localeCompare(b.name);
            }
          });
        });
      });
      orderedMealsWeekly.sort((a, b) => a.date.valueOf().localeCompare(b.date.valueOf()));
      console.log(orderedMealsWeekly);
      setIsLoading(false);
      setOrderedMeals(orderedMealsWeekly);
    });
  };

  const [from, setFrom] = useState(getCurrentFrom());

  return (
    <>
      <Button onClick={() => downloadPDF()}>
        <i className='fa fa-file-pdf-o' /> {t('FoodOrder.export pdf')}
      </Button>
      <div>
        {!isWeekSelectionLoading ? (
          <div className={styles.cwnavigationContainer}>
            <Button onClick={() => onDecreaseCW()}>
              <i className='fa fa-chevron-left' />
            </Button>
            <h3 className={styles.calendarWeek}>
              KW {getWeekNumber(from)[1]} : {formatDate(from)} - {formatDate(getSunday(from))}
            </h3>
            <Button onClick={() => onIncreaseCW()}>
              <i className='fa fa-chevron-right' />
            </Button>
          </div>
        ) : (
          <Loader />
        )}
        {isLoading ? (
          <div className={styles.searchingResultsLoader}>
            <Loader />
          </div>
        ) : (
          <div>
            {orderedMeals && (
              <div className={styles.foodContainer}>
                {orderedMeals.length > 0 ? (
                  orderedMeals.map((meal, index) => (
                    <div className={styles.mealItem}>
                      <div className={styles.mealItemHeader}>
                        <strong>
                          {getDateFromFormatedDate(meal.date, t)} - {meal.date}
                        </strong>
                      </div>
                      <div className={styles.mealItemBody}>
                        <div className={styles.orderedFood}>
                          <div className={styles.orderedFood}>
                            <div className={styles.foodHeader}>
                              {meal.food.map((food, foodIndex) => (
                                <div className={styles.mealItemBody}>
                                  <div className={styles.orderedFood}>
                                    <div className={styles.orderedFood}>
                                      <div className={styles.foodHeaderSub}>
                                        <div className={styles.orders}>
                                          <strong>{food.kids.length}</strong>
                                          <i className={cn('fa fa-cutlery', styles.icon)} />
                                        </div>
                                        <h3 className={styles.foodName}>{food.foodName}</h3>
                                        <i
                                          className={cn(
                                            !food.chevron
                                              ? 'fa fa-chevron-down'
                                              : 'fa fa-chevron-up',
                                            styles.showFurtherInformation,
                                          )}
                                          onClick={() => toggleDetails(index, foodIndex)}
                                        />
                                      </div>
                                      <div>
                                        {food.chevron && (
                                          <div className={styles.showFurtherInformationContainer}>
                                            <br />
                                            <table className={styles.foodOrderWeeklyTable}>
                                              <thead>
                                                <tr>
                                                  <th>{t('FoodOrder.Kid Name')}</th>
                                                  <th>{t('FoodOrder.Kid Group')}</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {food.kids.map(item => (
                                                  <tr>
                                                    <td>{item.name}</td>
                                                    <td>
                                                      {item.group
                                                        ? item.group
                                                        : t('FoodOrder.No group available')}
                                                    </td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                            </table>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <br />
                    <strong>
                      <i className={cn('fa fa-info-circle', styles.mr1)} />
                      {t('FoodOrder.No meals available')}
                    </strong>
                    <br />
                    <br />
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
