import React, { useState, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import deepcopy from 'deepcopy';

import { getEventIcsFile, sendIcsFileViaEmail } from 'library/api/calendar';
import { isAwoWW } from 'library/api/tenantConfig';
import { alreadySigned } from 'library/api/signature';
import useEditing from 'library/common/commonHooks/useEditing';
import Card from 'library/common/commonComponents/Card';
import useLikes from 'library/common/commonHooks/useLikes';
import useDislikes from 'library/common/commonHooks/useDislikes';
import useReturnReceipt from 'library/common/commonHooks/useReturnReceipt';
import { getCanUserVoteStatus } from 'library/utilities/surveys';
import Storage from 'library/utilities/storage';
import { isPublicUser } from 'library/utilities/user';

import FeedItemHeader from './feedItemFrames/FeedItemHeader';
import FeedItemCommon from './feedItemFrames/FeedItemCommon';
import FeedItemFile from './feedItemFrames/FeedItemFile';
import FeedItemGallery from './feedItemFrames/FeedItemGallery';
import FeedItemEvent from './feedItemFrames/FeedItemEvent';
import FeedItemSurvey from './feedItemFrames/FeedItemSurvey';
import FeedItemParentSurvey from './feedItemFrames/FeedItemParentSurvey';
import FeedItemTask from './feedItemFrames/FeedItemTask';
import FeedItemComments from './feedItemFrames/FeedItemComments/FeedItemCommentsContainer';
import ReturnReceiptFooter from './feedItemFrames/FeedItemReturnReceipt';

import styles from './feedItem.module.scss';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

FeedItem.defaultProps = {
  surveyOptions: [],
};

const getAcceptanceCount = (acceptanceType = '', userParticipationList) => {
  return userParticipationList.filter((up) => up.acceptance === acceptanceType).length;
};

export default function FeedItem({
  groups = [],
  kids = [],
  match,
  text,
  comments,
  likes,
  dislikes,
  updateFeedById,
  id,
  user,
  userId,
  files,
  group,
  isLikeFeatureAvailableInGroup,
  likeFeatureEnabled,
  privatePost,
  postType,
  showBottomNotificationProp,
  anonymousVotesStatus,
  anonymousVotesForAllStatus,
  allowUserAddSuggestionStatus,
  endTimeDate,
  endTimeStatus,
  startTimeDate,
  startTimeStatus,
  surveyOpenStatus,
  multipleAnswerStatus,
  hideResultStatus,
  displayRandomOrderStatus,
  question,
  surveyOptions,
  gallery,
  description,
  isPublic,
  activeKita,
  isInProfileStream,
  updateBreadcrumbs,
  isTranslationAllowed,
  closed,
  published,
  createdAt,
  createdBy,
  name,
  parentSurveyQuestions,
  title,
  updated,
  isNotOnlyPublicKitaMemberVar,
  isInGroup,
  commentsForbidden,
  hidePost,
  publicVisible,
  kitaId,
  publicationTime: postPublicationTime,
  reminders,
  reusableParentSurvey,
  setReusableParentSurvey,
  isGroupAdmin,
  showKitaInfo,
  allowMemberComment,
  userParticipations = [],
  ...headerProps
}) {
  const isBazaarUrl = match ? match.url.includes('/search') : false;
  const isBazaar = gallery ? gallery.isBazaar : false;
  let showPost = isBazaarUrl || !isBazaar || isInGroup;
  const [localComments, setLocalComments] = useState(comments.map(deepcopy));
  const groupId = group ? group.groupId : null;
  const [isSigned, setIsSigned] = useState(false);
  const [publicationTime, setPublicationTime] = useState(postPublicationTime);
  const [userParticipationList, setUserParticipationList] = useState(userParticipations);

  useEffect(() => {
    async function updateSignedState(fileId) {
      const response = await alreadySigned(fileId);
      if (response && response.data) setIsSigned(response.data === true);
    }

    if (files != null && files.length === 1 && files[0].mimeType === 'application/pdf') {
      updateSignedState(files[0].id);
    }
  }, []);

  if (
    user.currentWatsonLang === headerProps.watsonLanguage ||
    (user.currentWatsonLang === 'de' &&
      (headerProps.watsonLanguage == null || headerProps.watsonLanguage === 'null'))
  ) {
    isTranslationAllowed = false;
  }

  const { t } = useTranslation();

  const updatePost = async (postId, payload) => {
    if (payload.publicationTime && typeof payload.publicationTime === 'string') {
      payload.publicationTime = new Date(payload.publicationTime).getTime();
    }
    const { files: newFiles, ...restOfPayload } = payload;
    const newPayload = {
      ...restOfPayload,
      privatePost,
      groupId: group ? group.groupId : 0,
      postType,
      files: newFiles.filter(newFile => !newFile.id),
      sortOrder: JSON.stringify(
        newFiles.map(({ id }, index) => ({ fileId: id, sortOrder: index })),
      ),
    };

    const deletedFileIds = files
      .reduce((result, file) => {
        const isNotDeleted = newFiles.find(newFile => file.id === (newFile.id || ''));
        return (result = !isNotDeleted ? result.concat(file.id) : result);
      }, [])
      .join(',');

    if (deletedFileIds.length) {
      newPayload.deletedFileIds = deletedFileIds;
    }
    await updateFeedById(postId, newPayload);
    Storage.setItem(`editor/message/${t('Editor.Edit your post')}`, newPayload.text);
  };

  const { isEditing, setIsEditing, ...editorProps } = useEditing({
    text: postType === 2 ? description : text,
    updateItemById: updatePost,
    id,
    placeholder: t('Editor.Edit your post'),
    groupId,
    publicationTime,
  });
  const { LikeButton, ...likeButtonProps } = useLikes({ likes, user, id, activeKita });
  const { DislikeButton, ...dislikeButtonProps } = useDislikes({ dislikes, user, id, activeKita });

  const { ...rrmProps } = useReturnReceipt({ user, id });

  const [commentsIsOpened, setCommentsIsOpened] = useState(comments.length > 0);
  const isAuthoredByCurrentUser = (group && group.groupAdminStatus) || user.id === userId;

  const canCreateCommentStatus = !group || (group && group.createCommentStatus);

  const [canUserVote, setCanUserVote] = useState(
    getCanUserVoteStatus({ surveyOptions, user, surveyOpenStatus }),
  );
  const groupMemberStatus = group
    ? (group.groupAdminStatus && group.visibility) || group.showCancelMembership
    : false;

  const handleOpenComment = () => {
    setCommentsIsOpened(!commentsIsOpened);
  };

  const participationCount = useMemo(() => {
    let acceptanceCount = 0;
    userParticipationList.forEach(up => {
      if (up.acceptance === 'ACCEPT') {
        acceptanceCount++;
        if (up.manualAttendCount > 1) {
          acceptanceCount = acceptanceCount + up.manualAttendCount - 1;
        }
      } else if (up.acceptance === 'DECLINE' && up.manualAttendCount > 0) {
        acceptanceCount = acceptanceCount + up.manualAttendCount;
      }
    });
    const declineCount = getAcceptanceCount('DECLINE', userParticipationList);
    const maybeCount = getAcceptanceCount('MAYBE', userParticipationList);
    return {
      acceptanceCount,
      declineCount,
      maybeCount,
    };
  }, [userParticipationList]);

  const survey = {
    allowUserAddSuggestionStatus,
    anonymousVotesStatus,
    anonymousVotesForAllStatus,
    deletedSurveyOptionIds: [],
    displayRandomOrderStatus,
    hideResultStatus,
    question,
    surveyOpenStatus,
    surveyOptions,
    multipleAnswerStatus,
  };

  const parentSurvey = {
    id,
    title,
    isClosed: closed,
    isPublished: published,
    createdAt,
    createdBy,
    allowUserAddSuggestionStatus,
    name,
    questions: parentSurveyQuestions,
    total_votes_completed: 0,
    userHasVoted: true,
    userIsKitaAdmin: user.administrationAccess,
    updated: updated || false,
    startTimeStatus,
    startTimeDate,
    endTimeStatus,
    endTimeDate,
    reminders,
  };

  /**
   *
   * @returns {boolean} true if the user is using an IOS device
   */
  const isIOS = () => {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        navigator.platform,
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  };

  const [downloadedICSFile, setDownloadedICSFile] = useState(false);
  const downloadICSFile = async () => {
    if (downloadedICSFile) return;

    const manuallySetDate = headerProps.eventClickInfo.event.extendedProps.repeatType !== 'none';
    const manualStartDate = new Date(headerProps.eventClickInfo.event.start).getTime();
    const manualEndDate = new Date(headerProps.eventClickInfo.event.end).getTime();

    try {
      if (isIOS()) {
        await sendIcsFileViaEmail(
          id,
          user.email,
          manuallySetDate ? headerProps.start : null,
          manuallySetDate ? headerProps.end : null,
        );
      } else {
        await getEventIcsFile(
          id,
          text,
          manuallySetDate ? headerProps.start : null,
          manuallySetDate ? headerProps.end : null,
        );
      }

      setDownloadedICSFile(true);
      store.dispatch(
        showBottomNotification(t('Calendar.ICS file download successful'), { isFail: false }),
      );
    } catch (error) {
      store.dispatch(
        showBottomNotification(t('Calendar.ICS file download failed'), { isFail: true }),
      );
    }
  };

  const canShowCommentButton =
    (canCreateCommentStatus &&
      isNotOnlyPublicKitaMemberVar &&
      !commentsForbidden &&
      user?.lastName !== 'Caterer' &&
      (!isAwoWW() || !isPublicUser(user))) ||
    (!!allowMemberComment && !isPublicUser(user));

  return (
    showPost && (
      <>
        <Card>
          {((startTimeDate != null &&
            new Date(startTimeDate) <= new Date(Date.now()) &&
            startTimeStatus) ||
            !startTimeStatus ||
            isAuthoredByCurrentUser) && (
            <div className={styles.feed}>
              <FeedItemHeader
                {...headerProps}
                groups={groups}
                kids={kids}
                isTranslationAllowed={isTranslationAllowed}
                privatePost={postType === 5 ? !isPublic : privatePost}
                isAuthoredByCurrentUser={isAuthoredByCurrentUser}
                id={id}
                updateFeedById={updateFeedById}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                group={group}
                postType={postType}
                canUserVote={canUserVote}
                endTimeDate={endTimeDate}
                endTimeStatus={endTimeStatus}
                startTimeDate={startTimeDate}
                startTimeStatus={startTimeStatus}
                survey={survey}
                parentSurvey={parentSurvey}
                description={description}
                files={files}
                user={user}
                userId={userId}
                showBottomNotification={showBottomNotificationProp}
                activeKita={activeKita}
                isInProfileStream={isInProfileStream}
                isSigned={isSigned}
                createdAt={createdAt}
                publicationTime={publicationTime}
                text={text}
                commentsForbidden={commentsForbidden}
                allowMemberComment={allowMemberComment}
                hidePost={hidePost}
                publicVisible={publicVisible}
                kitaId={kitaId}
                isLikeFeatureAvailableInGroup={isLikeFeatureAvailableInGroup}
                likeFeatureEnabled={likeFeatureEnabled}
                reusableParentSurvey={reusableParentSurvey}
                setReusableParentSurvey={setReusableParentSurvey}
                showKitaInfo={showKitaInfo}
              />

              {postType === 0 ? (
                <FeedItemCommon
                  isTranslationAllowed={isTranslationAllowed}
                  postType='0'
                  id={id}
                  groupId={group && group.groupId}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  editorProps={editorProps}
                  files={files}
                  publicationTime={publicationTime}
                  setPublicationTime={setPublicationTime}
                  text={text}
                  isSigned={isSigned}
                  user={user}
                />
              ) : postType === 1 && files[0] ? (
                <FeedItemFile
                  userLanguage={user.langCode}
                  isTranslationAllowed={isTranslationAllowed}
                  postType='1'
                  id={id}
                  isPublic={!privatePost}
                  file={files[0]}
                  groupId={group && group.groupId}
                  userId={userId}
                  isEditing={isEditing}
                  closePopup={() => setIsEditing(false)}
                  updateFeedById={updateFeedById}
                  text={text}
                  group={group}
                  updateBreadcrumbs={updateBreadcrumbs}
                />
              ) : postType === 2 ? (
                <FeedItemEvent
                  isTranslationAllowed={isTranslationAllowed}
                  postType='2'
                  id={id}
                  isPublic={!privatePost}
                  {...headerProps}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  eventId={id}
                  title={text}
                  files={files}
                  group={group}
                  updateFeedById={updateFeedById}
                  description={description}
                  isAuthoredByCurrentUser={isAuthoredByCurrentUser}
                  user={user}
                  userParticipations={userParticipationList}
                  setUserParticipations={setUserParticipationList}
                  acceptanceCount={participationCount.acceptanceCount}
                  declineCount={participationCount.declineCount}
                  maybeCount={participationCount.maybeCount}
                />
              ) : postType === 3 ? (
                <FeedItemGallery
                  isTranslationAllowed={isTranslationAllowed}
                  postType='3'
                  id={id}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  group={group}
                  gallery={gallery}
                  files={files}
                  text={text}
                  updateFeedById={updateFeedById}
                  userId={userId}
                />
              ) : postType === 4 ? (
                <FeedItemSurvey
                  isTranslationAllowed={isTranslationAllowed}
                  postType='4'
                  id={id}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  user={user}
                  showBottomNotification={showBottomNotificationProp}
                  updateFeedById={updateFeedById}
                  surveyOpenStatus={surveyOpenStatus}
                  multipleAnswerStatus={multipleAnswerStatus}
                  anonymousVotesStatus={anonymousVotesStatus}
                  anonymousVotesForAllStatus={anonymousVotesForAllStatus}
                  hideResultStatus={hideResultStatus}
                  displayRandomOrderStatus={displayRandomOrderStatus}
                  allowUserAddSuggestionStatus={allowUserAddSuggestionStatus}
                  endTimeDate={endTimeDate}
                  endTimeStatus={endTimeStatus}
                  startTimeDate={startTimeDate}
                  startTimeStatus={startTimeStatus}
                  reminders={reminders}
                  question={question}
                  surveyOptions={surveyOptions}
                  survey={survey}
                  isPublic={!privatePost}
                  files={files}
                  canUserVote={canUserVote}
                  setCanUserVote={setCanUserVote}
                  isAuthoredByCurrentUser={isAuthoredByCurrentUser}
                  groupMemberStatus={groupMemberStatus}
                  userId={userId}
                  activeKita={activeKita}
                />
              ) : postType === 5 ? (
                <FeedItemTask
                  isTranslationAllowed={isTranslationAllowed}
                  postType='5'
                  match={match}
                  id={id}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  {...headerProps}
                  group={group}
                  files={files}
                  user={user}
                  title={title}
                  updateFeedById={updateFeedById}
                  description={description}
                  privatePost={postType === 5 ? !isPublic : privatePost}
                />
              ) : postType === 9 ? (
                <FeedItemParentSurvey
                  kidsCount={kids.length}
                  isTranslationAllowed={isTranslationAllowed}
                  postType='9'
                  id={id}
                  parentSurvey={parentSurvey}
                  user={user}
                  showBottomNotification={showBottomNotificationProp}
                  updateFeedById={updateFeedById}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  isPublic={!privatePost}
                  files={files}
                  isAuthoredByCurrentUser={isAuthoredByCurrentUser}
                  groupMemberStatus={groupMemberStatus}
                  groupInfo={group}
                  activeKita={activeKita}
                  endTimeDate={endTimeDate}
                  endTimeStatus={endTimeStatus}
                  startTimeDate={startTimeDate}
                  startTimeStatus={startTimeStatus}
                />
              ) : postType === 11 ? (
                <FeedItemCommon
                  isTranslationAllowed={isTranslationAllowed}
                  postType='11'
                  id={id}
                  groupId={group.groupId}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  editorProps={editorProps}
                  files={files}
                  text={text}
                  isSigned={isSigned}
                  user={user}
                />
              ) : null}
              {(!publicationTime || (isAwoWW() && Number(publicationTime) <= Date.now())) && (
                <>
                  <div
                    className={cn(styles.bottomSection, {
                      [styles.commentsIsOpened]: commentsIsOpened,
                    })}
                  >
                    {user?.lastName !== 'Caterer' &&
                      isLikeFeatureAvailableInGroup &&
                      likeFeatureEnabled && (
                        <div className={styles.bottomSectionButton}>
                          <LikeButton {...likeButtonProps} />
                        </div>
                      )}
                    {canShowCommentButton && (
                      <>
                        <div className={styles.bottomSectionSeparator}>·</div>
                        <div className={styles.bottomSectionButton} onClick={handleOpenComment}>
                          <div className={styles.comments}>
                            {t('Post.Comment')}
                            {localComments.length > 0 && (
                              <div className={styles.commentsCount}>({localComments.length})</div>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {postType === 2 && (
                      <>
                        <div className={styles.bottomSectionSeparator}>·</div>
                        <div
                          className={styles.calendarEventDownload}
                          onClick={() => downloadICSFile()}
                        >
                          {downloadedICSFile && isIOS()
                            ? t('Calendar.ICS file sent via E-Mail')
                            : t('Calendar.Download as ICS file')}
                        </div>
                      </>
                    )}
                    {headerProps.returnReceipt && (
                      <div className={styles.bottomSectionSeparator}>·</div>
                    )}
                    {headerProps.returnReceipt && (
                      <ReturnReceiptFooter
                        {...rrmProps}
                        userId={user.id}
                        rrm={headerProps.rrm}
                        postId={id}
                      />
                    )}
                  </div>

                  {user?.lastName !== 'Caterer' && (!isAwoWW() || !isPublicUser(user)) && (
                    <FeedItemComments
                      group={group}
                      postAuthorId={userId}
                      postId={id}
                      postType='comment'
                      user={user}
                      isOpened={commentsIsOpened}
                      createCommentStatus={
                        (canCreateCommentStatus && !commentsForbidden) || !!allowMemberComment
                      }
                      comments={localComments}
                      isLikeFeatureAvailableInGroup={isLikeFeatureAvailableInGroup}
                      likeFeatureEnabled={likeFeatureEnabled}
                      isTranslationAllowed={isTranslationAllowed}
                      setComments={setLocalComments}
                      groupId={groupId}
                      usersTitle={headerProps.usersTitle}
                      activeKita={activeKita}
                      isInProfileStream={isInProfileStream}
                      isGroupAdmin={isGroupAdmin}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </Card>
      </>
    )
  );
}
