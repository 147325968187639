import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import { getTables } from 'library/api/tableEditor';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';
import TablesOverview from './TablesOverview';
import TablesEditor from './TablesEditor';
import TablesPopup from './TablesPopup';

function Tables({ user, kitaName, kitaId }) {
  const { t } = useTranslation();

  const [tables, setTables] = useState([]);

  const defaultTableData = [['Spalte 1'], ['Wert 1']];
  const emptyTable = {
    id: -1,
    name: '',
    creator: -1,
    templateData: defaultTableData,
  };

  const useTemplate = (table) => ({
    id: -1,
    name: table.name,
    creator: -1,
    templateData: table.templateData,
    columnsDetails: table.columnsDetails,
  })

  const [editTable, setEditTable] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectTemplatePopupOpen, setSelectTemplatePopupOpen] = useState(false);

  const reloadTables = () => {
    setIsLoading(true);
    getTables()
      .then(res => {
        const data = (res.data ?? []).map(table => {
          let parsedData = defaultTableData;
          let parsedColumns = [];
          try {
            parsedData = JSON.parse(table.templateData) ?? [];
            if (typeof parsedData === 'string') {
              parsedData = JSON.parse(parsedData);
            }
            parsedColumns = JSON.parse(table.columnsDetails) ?? [];
            if (typeof parsedColumns === 'string') {
              parsedColumns = JSON.parse(parsedColumns);
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('Error parsing table data', e);
          }
          const res = {
            ...table,
            columnsDetails: parsedColumns,
            templateData: parsedData,
          };
          return res;
        });
        setTables(data);
      })
      .catch(err => {
        store.dispatch(showBottomNotification(t('FormsEditor.LoadFormsError'), { isFail: true }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getTemplates = () => {
    return tables.filter(table => table.template);
  };

  const createNewTable = () => {
    setSelectTemplatePopupOpen(true);
  };

  const selectTableTemplate = (table) => {
    setSelectTemplatePopupOpen(false);
    if(table === null) {
      startEditTable(emptyTable);
    } else {
      startEditTable(useTemplate(table));
    }
  };

  const startEditTable = f => {
    setEditTable(f);
  };

  useEffect(() => {
    reloadTables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <Col>
          <TablesPopup 
            isOpened={selectTemplatePopupOpen}
            close={() => { setSelectTemplatePopupOpen(false) }}
            selectTableTemplate={selectTableTemplate}
            availableTemplates={getTemplates()}
          ></TablesPopup>
          {editTable ? (
            <TablesEditor
              editTable={editTable}
              cancelEdit={() => setEditTable(null)}
              reloadTables={reloadTables}
            />
          ) : (
            <TablesOverview
              createNewTable={createNewTable}
              tables={tables.filter(table => !table.template)}
              isLoading={isLoading}
              startEditTable={startEditTable}
              reloadTables={reloadTables}
            />
          )}
        </Col>
      </Wrapper>
    </KitaPanelWrapper>
  );
}

export default connect(state => ({
  user: state.userReducer,
  kitaName: state.kitaReducer.activeKita.kitaName,
  kitaId: state.kitaReducer.activeKita.kitaId,
}))(Tables);
