import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Popup from 'library/common/commonComponents/Popups/Popup';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Label from 'library/common/commonComponents/Label';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import LanguageDropdown from 'library/common/commonComponents/Dropdowns/LanguageDropdown';
import Storage from 'library/utilities/storage';
import { validateEmailWithTLD } from 'library/utilities/validation';

import styles from './inviteUsersToKitaPopup.module.scss';

export function InviteUsersToKitaPopup({
  isOpened,
  closePopup,
  invitationService,
  superAdminStatus,
  showBottomNotificationFromProps,
}) {
  const { t } = useTranslation();
  const [emails, setEmails] = useState('');
  const [emailsError, setEmailsError] = useState('');
  const [emailsSuccess, setEmailsSuccess] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invitationResults, setInvitationResults] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(
    (window.location.hostname.toLowerCase().includes('gfz-app.ch') ||
      window.location.hostname.toLowerCase().includes('staging.stramplerbande.org')) &&
      superAdminStatus,
  );

  const [selectedLanguage, setSelectedLanguage] = useState(Storage.getItem('locale'));
  const changeLanguage = e => {
    setSelectedLanguage(e.target.value);
  };

  const handleChange = () => {
    const replacedEmails = emails.replaceAll(/\s/g, '');
    let emailsArr = replacedEmails
      .split(' ')
      .join('')
      .split(/[;,]+/);

    emailsArr = emailsArr.filter(function(el) {
      return el !== '';
    });

    let invalidEmail = false;
    let invalidEmailString = '';
    for (let i = 0; i < emailsArr.length; i++) {
      if (validateEmailWithTLD(emailsArr[i]) === false) {
        invalidEmail = true;
        invalidEmailString = invalidEmailString + emailsArr[i] + ', ';
      }
    }

    if (!emails) {
      setEmailsError(t('Administration.UserSection.Email is required'));
      // setIsDisabled(true);
    } else if (invalidEmail) {
      invalidEmailString = invalidEmailString.substring(0, invalidEmailString.length - 2);
      setEmailsError(t('Administration.UserSection.Email is invalid') + ' ' + invalidEmailString);
      // setIsDisabled(true);
    } else if (emails.toLowerCase().endsWith('@diako-thueringen.de')) {
      return setEmailsError(
        t('Signup.Users with @diako-thueringen.de email can only register via SCIM')
      );
    } else {
      setEmailsError('');
      setIsDisabled(false);
      sendInvites(emailsArr);
    }
  };

  const sendInvites = async emailsArr => {
    if (isLoading) {
      return;
    }
    if (!isDisabled) {
      setIsDisabled(true);
      const errors = [];
      const success = [];
      const fetchs = emailsArr.map(email =>
        invitationService({ email, langCode: selectedLanguage }),
      );

      setIsLoading(true);

      const responses = await Promise.all(fetchs);

      for (let i = 0; i < responses.length; i++) {
        const { result, reason } = responses[i];

        if (!result) {
          errors.push({
            email: emailsArr[i],
            reason,
          });
        } else {
          success.push({ email: emailsArr[i] });
        }
      }
      setIsLoading(false);
      setIsDisabled(false);

      if (!errors.length) {
        showBottomNotificationFromProps(t('Administration.All invitations sent successfully'));
        closePopup();
      } else {
        setEmails('');
        setEmailsError(t('Administration.UserSection.Invitation errors'));
        setInvitationResults(errors);
        setEmailsSuccess(success);
      }
    }
  };
  const onSubmit = !isDisabled ? sendInvites : undefined;
  return (
    <Popup
      data-test='invitation-modal-container'
      isOpened={isOpened}
      closePopup={closePopup}
      size='small'
      header={<Trans i18nKey='Administration.UserSection.Add new users' />}
      footer={
        <>
          {buttonDisabled && (
            <div>
              <CheckBox
                name={t(
                  'Administration.UserSection.Invitation is going to create the user as administrator',
                )}
                isChecked={!buttonDisabled}
                onChange={() => {
                  setButtonDisabled(!buttonDisabled);
                }}
              />
              <br />
            </div>
          )}
          <ButtonWithLoader
            onClick={handleChange}
            type='primary'
            isLoading={isLoading}
            disabled={buttonDisabled}
          >
            {t('Administration.UserSection.Send')}
          </ButtonWithLoader>
        </>
      }
    >
      <div className={styles.container}>
        <div className={styles.inputGroup}>
          <Label type='input' className={styles.labelInput}>
            {t('Administration.UserSection.New user by e-mail (comma separated)')}
          </Label>
          <TextArea
            placeholder={t('Administration.UserSection.Email addresses')}
            value={emails}
            error={emailsError !== ''}
            onChange={e => {
              setEmails(e.target.value);
              setEmailsError('');
            }}
            onEnterDown={onSubmit}
          />
        </div>
        <div className={styles.success_msg}>
          {invitationResults.length > 0 && emailsSuccess.map(item => item.email).join(', ')}
          {invitationResults.length > 0 && (
            <span> - {t('Administration.UserSection.Invitations sent to remaining emails')}</span>
          )}
        </div>
        {emailsError !== '' && (
          <div className={styles.error_msg}>
            <InputErrorMsg errorMsg={emailsError} />
          </div>
        )}
        {invitationResults.map(item => (
          <div className={styles.error_msg} key={item.email}>
            <InputErrorMsg errorMsg={`${item.email} - ${t(item.reason)}`} />
          </div>
        ))}
        <LanguageDropdown
          chooseLanguageText={t('Login.Choose language')}
          submitLanguage={changeLanguage}
          selectedLanguage={selectedLanguage}
          isLefted
        />
      </div>
    </Popup>
  );
}

const mapDispatchToProps = {
  showBottomNotificationFromProps: showBottomNotification,
};
export default connect(
  null,
  mapDispatchToProps,
)(InviteUsersToKitaPopup);
