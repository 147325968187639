import React, {forwardRef, useState} from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Card from 'library/common/commonComponents/Card';
import Button from 'library/common/commonComponents/Buttons/Button';
import DatePicker from 'library/common/commonComponents/DatePicker';

import styles from '../publicationTimeButton.module.scss';
import getNextHalfHour from "../getNextHalfHourHelper";
import { isAwoWW } from 'library/api/tenantConfig';

const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
        type="text"
        className="datepicker-custom-input"
        value={value}
        ref={ref}
        onClick={onClick}
        onKeyDown={e => e.preventDefault()}
        placeholder='DD.MM.YYYY HH:MM'
        onChange={() => {}}
    />
));
export default function TimeSelectionDropdown({ isOpened, value, onApply }) {
  const [selectedTime, setSelectedTime] = useState(value);
  const { t } = useTranslation();

  return (
    isOpened && (
      <div className={styles.timeSelectionDropdown}>
        <Card className={styles.timeSelectionDropdownContent}>
          <div className={styles.inputContainer}>
            <DatePicker
              selected={selectedTime ? new Date(selectedTime) : undefined}
              onChange={datetime => {
                const formattedTime = format(datetime, "yyy-MM-dd'T'HH:mm");
                setSelectedTime(formattedTime);
              }}
              customInput={<CustomInput />}
              minDate={isAwoWW() ? new Date('1970-01-01') : getNextHalfHour()}
              timeIntervals={30}
              showTimeSelect
              timeFormat='HH:mm'
              dateFormat='dd.MM.yyyy HH:mm'
              placeholderText=''
              allowCustomHeader={true}
            />
            {selectedTime && (
              <div
                className={styles.clearIcon}
                role='button'
                onClick={() => {
                  setSelectedTime();
                  onApply();
                }}
              >
                <i className='fa fa-times' />
              </div>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <Button type='primary' onClick={() => onApply(selectedTime)} disabled={!selectedTime}>
              {t('Post.Apply')}
            </Button>
          </div>
        </Card>
      </div>
    )
  );
}
